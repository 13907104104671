import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createAProcurement,
  editAProcurement,
  getAProcurement,
  getBusinessTypes,
  getIndustries,
} from '../../services/procurementService';

import FormikInput from '../../components/CustomTextInput/CustomTextInput';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormikTextarea from '../../components/CustomTextArea/CustomTextArea';
import FormikSelect from '../../components/CustomDropdownSelect/CustomDropdownSelect';
import FormikDateInput from '../../components/CustomDatePicker/CustomDatePicker';
import UploadInput from '../../components/CustomUploadInput/CustomUploadInput';
import { ProcurementDataProps } from '../../types/procurement-types';
import { toast } from 'react-toastify';
import { ProcurementStatus } from '../../types/enums';

interface FormValues {
  procurementProjectId?: string;
  status?: number;
  title?: string;
  industryId?: string;
  businessType?: string;
  //   supplierTypeToBid?: string;
  bidStartDate?: string | Date;
  bidEndDate?: string | Date;
  description?: string;
  bidRequirement?: string;
  prerequisite?: string;
  workReference?: string;
  additionalInformation?: string;
  //   isBiddingProject: boolean;
}

interface Industry {
  industryId: string;
  industryName: string;
}

interface LookupType {
  lookupId: string;
  lookupName: string;
  lookupValue: string;
}

interface ServiceInformationProps {
  goToNextTab: () => void;
  serviceInformationId: string | null;
  setServiceInformationId: React.Dispatch<React.SetStateAction<string | null>>;
}

const ServiceInformation: React.FC<ServiceInformationProps> = ({
  goToNextTab,
  serviceInformationId,
  setServiceInformationId,
}) => {
  const [industries, setIndustries] = useState<Industry[]>([]);
  const [businessTypes, setBusinessTypes] = useState<LookupType[]>([]);

  //   const [tags, setTags] = useState<string[]>([]);
  const [procurementProjectData, setProcurementProjectData] = useState<ProcurementDataProps>();

  const [isLoading, setIsLoading] = useState(false);
  const [saveAndContinue, setSaveAndContinue] = useState(false);

  const navigate = useNavigate();

  //   const [availableTags, setAvailableTags] = useState<string[]>([]);

  useEffect(() => {
    fetchIndustries();
    fetchBusinessTypes();
    // fetchTags();
  }, []);

  useEffect(() => {
    if (serviceInformationId) {
      loadProcurementProject(serviceInformationId);
    }
  }, [serviceInformationId]);

  const loadProcurementProject = async (projectId: string) => {
    try {
      const response = await getAProcurement(projectId);
      if (response.isSuccess) {
        setProcurementProjectData(response.data);
      }
    } catch (error) {
      console.error('Something went wrong: ', error);
    }
  };

  const fetchIndustries = async () => {
    try {
      const response = await getIndustries();
      if (response.isSuccess) {
        setIndustries(response.data);
      }
    } catch {
      // console.error('Error fetching industries:', error);
    }
  };

  const fetchBusinessTypes = async () => {
    try {
      const response = await getBusinessTypes();
      if (response.isSuccess) {
        console.log('Business Types:', response.data);
        setBusinessTypes(response.data);
      }
    } catch {
      // console.error('Error fetching supplier types:', error);
    }
  };

  //   const fetchTags = async () => {
  //     try {
  //       const response = await getTags();
  //       const tagArr = response.data.map((item: tagsData) => item.tagName);
  //       setAvailableTags(tagArr);
  //     } catch (error) {
  //       console.error('Error fetching suppliers:', error);
  //     }
  //   };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Procurement Title is required'),
    industryId: Yup.string().required('Industry is required'),
    businessType: Yup.string().required('Business type is required'),
    // supplierTypeToBid: Yup.string(),
    bidStartDate: Yup.string().required('Bidding start date is required'),
    bidEndDate: Yup.string().required('Bidding end date is required'),
    description: Yup.string().required('Description is required'),
    bidRequirement: Yup.string().required('Bid Requirement is required'),
    prerequisite: Yup.string().required('Prerequisites are required'),
    workReference: Yup.string().required('Reference is required'),
    additionalInformation: Yup.string().required('Additional Information is required'),
    // isBiddingProject: Yup.boolean(),
  });

  const handleFileUpload = (files: File[]) => {
    // console.log("Uploaded Files:", files);
  };

  const handleGoBack = () => {
    navigate(-1); // Goes back to the previous page
  };

  const handleSubmit = async (values: FormValues) => {
    const payload = {
      ...values,
      ...(serviceInformationId && { serviceInformationId }),
      status: ProcurementStatus.DRAFT,
      //   isBiddingProject: true,
    };

    try {
      setIsLoading(true);
      let response;

      if (serviceInformationId) {
        response = await editAProcurement(payload);
      } else {
        response = await createAProcurement(payload);
      }

      if (response.isSuccess) {
        toast.success(response.data.message);
        setServiceInformationId(response.data.id);

        // Check which button was clicked and navigate accordingly
        if (saveAndContinue) {
          goToNextTab();
        } else {
          handleGoBack();
        }
      } else {
        toast.error(response.errorMessage);
      }
    } catch (error) {
      console.error('An error occurred while trying to create a procurement', error);
      toast.error('An error occurred while trying to create procurement');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="mb-8 border-b border-b-[#E5E5E5] pb-4">
        <h2 className="text-2xl font-semibold">Provide service details</h2>
      </div>

      <Formik<FormValues>
        initialValues={{
          title: procurementProjectData?.title || '',
          industryId: procurementProjectData?.industryId || '',
          businessType: procurementProjectData?.businessType || '',
          //   supplierTypeToBid: procurementProjectData?.supplierTypeToBid || '',
          bidStartDate: procurementProjectData?.bidStartDate
            ? new Date(procurementProjectData?.bidStartDate) // Parse ISO string to Date object
            : '',
          bidEndDate: procurementProjectData?.bidEndDate
            ? new Date(procurementProjectData?.bidEndDate)
            : '',
          description: procurementProjectData?.description || '',
          bidRequirement: procurementProjectData?.bidRequirement || '',
          prerequisite: procurementProjectData?.prerequisite || '',
          workReference: procurementProjectData?.workReference || '',
          additionalInformation: procurementProjectData?.additionalInformation || '',
          //   isBiddingProject: procurementProjectData?.isBiddingProject || false,
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <FormikInput
              label="Title of Procurement"
              name="title"
              type="text"
              placeholder="Title of procurement plan. Easy to understand"
              isRequired
            />

            <FormikSelect
              label="Industry"
              name="industryId"
              options={industries.map((industry) => ({
                value: industry.industryId,
                label: industry.industryName,
              }))}
              isRequired
            />

            <FormikSelect
              label="Which type of Suppplier should bid"
              name="businessType"
              options={businessTypes.map((businessType) => ({
                value: businessType.lookupValue,
                label: businessType.lookupName,
              }))}
              isRequired
            />

            {/* <FormikSelect
              label="Which type of Supplier should bid"
              name="supplierTypeToBid"
              options={supplierTypes.map((type) => ({
                value: type.lookupValue,
                label: type.lookupName,
              }))}
              isRequired
            /> */}

            <div className="flex w-full gap-x-4">
              <FormikDateInput label="Bidding start date" name="bidStartDate" isRequired />
              <FormikDateInput label="Bidding end date" name="bidEndDate" isRequired />
            </div>

            {/* <TagInput
              label="Tags"
              placeholder="Enter tags..."
              tags={tags}
              suggestions={availableTags}
              onChange={setTags}
              isRequired={true}
            /> */}

            <FormikTextarea
              label="Description"
              name="description"
              placeholder="Describe the project"
              isRequired
            />

            <FormikTextarea
              label="Bid Requirement"
              name="bidRequirement"
              placeholder="Enter bidding criteria"
              isRequired
            />

            <FormikTextarea
              label="Prerequisites or Submission requirements"
              name="prerequisite"
              placeholder="What are the supplier requirements you are looking for?"
              isRequired
            />

            <FormikTextarea
              label="Reference to any work that has been done"
              name="workReference"
              placeholder="Paste links"
              isRequired
            />

            <UploadInput
              label="Sample of Outcome or Supporting documents"
              acceptedFileTypes={['image/png', 'image/jpeg', 'application/pdf']}
              maxFileSizeMB={10}
              multiple={true}
              onUpload={handleFileUpload}
            />

            <FormikTextarea
              label="Additional Information"
              name="additionalInformation"
              placeholder="Note / Comment"
              isRequired
            />

            {/* <label className="custom-checkbox flex items-center">
              <input
                type="checkbox"
                checked={values.isBiddingProject} // Bind to Formik value
                onChange={(e) => {
                  setFieldValue('isBiddingProject', e.target.checked); // Update Formik value
                }}
              />
              <span className="checkbox"></span>
              <span className="label !text-[#525252] !text-sm ">
                I want suppliers to go through the bidding process on this project?
              </span>
            </label> */}

            <div className="ml-auto mt-10 flex w-full items-center justify-end gap-x-[18px]">
              <button
                type="submit"
                className="min-w-[195px] rounded-[10px] bg-[#F5F5F5] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#E0E0E0] disabled:cursor-not-allowed disabled:bg-[#D6D6D6] disabled:text-[#B0B0B0]"
                disabled={isLoading}
                onClick={() => setSaveAndContinue(false)} // Save and exit
              >
                Save and exit
              </button>
              <button
                type="submit"
                className="min-w-[195px] rounded-[10px] bg-[#FFC726] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#FFB600] disabled:cursor-not-allowed disabled:bg-[#E0A900] disabled:text-[#B0B0B0]"
                disabled={isLoading}
                onClick={() => setSaveAndContinue(true)} // Save & Continue
              >
                Save & Continue
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ServiceInformation;
