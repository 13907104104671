import React from 'react';
import { FaFilter } from 'react-icons/fa';
import { FilterButtonProps } from '../../types/interfaces';
const FilterButton: React.FC<FilterButtonProps> = ({
  label = 'Filters',
  onClick,
  className = '',
}) => {
  return (
    <div
      className={`flex items-center justify-center gap-x-1 border rounded-lg border-gray-400 px-3 py-1.5  font-bold cursor-pointer ${className}`}
      onClick={onClick}
    >
      <img src={`${process.env.PUBLIC_URL}/assets/icons/outline/filter.svg`} alt="funnel" />
      <span className="text-[#667085] font-normal">{label}</span>
    </div>
  );
};

export default FilterButton;
