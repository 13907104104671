import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

interface ContactFormValues {
  email: string;
  phone: string;
  address: string;
  country: string;
  city: string;
  social: string;
}

const EditContactForm: React.FC<{ onSave: () => void }> = ({ onSave }) => {
  // Initial values for the form
  const initialValues: ContactFormValues = {
    email: 'Sharonmoses@gmail.com',
    phone: '+1 (281) 555-1234',
    address: '123 Main Street Houston, TX 77002',
    country: 'USA',
    city: 'Texas',
    social: 'Nil',
  };

  // Form validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string()
      .matches(/^\+?[0-9\s()-]+$/, 'Invalid phone number')
      .required('Phone number is required'),
    address: Yup.string().required('Contact address is required'),
    country: Yup.string().required('Country is required'),
    city: Yup.string().required('City is required'),
    socials: Yup.string().nullable(),
  });

  // Formik hook
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log('Updated Contact Details:', values);
      alert('Changes Saved Successfully!');
      onSave();
    },
  });

  return (
    <div className="bg-white rounded-lg shadow-sm py-3 px-4 w-full">
      <h2 className="text-lg font-semibold mb-4 text-gray-500">Contact Information</h2>
      <form onSubmit={formik.handleSubmit} className="space-y-4">
        {/* Email Address */}
        <div>
          <label htmlFor="email" className="block text-sm font-medium ">
            Email address
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="mt-1 p-2 block w-full border rounded-md text-gray-500 focus:outline-none border-gray-400"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="text-red-500 text-sm">{formik.errors.email}</div>
          )}
        </div>

        {/* Phone Number */}
        <div>
          <label htmlFor="phone" className="block text-sm font-medium ">
            Phone number
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="mt-1 p-2 block w-full border rounded-md text-gray-500 focus:outline-none border-gray-400"
          />
          {formik.touched.phone && formik.errors.phone && (
            <div className="text-red-500 text-sm">{formik.errors.phone}</div>
          )}
        </div>

        {/* Contact Address */}
        <div>
          <label htmlFor="address" className="block text-sm font-medium ">
            Contact address
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="mt-1 p-2 block w-full border rounded-md text-gray-500 focus:outline-none border-gray-400"
          />
          {formik.touched.address && formik.errors.address && (
            <div className="text-red-500 text-sm">{formik.errors.address}</div>
          )}
        </div>

        {/* Country */}
        <div>
          <label htmlFor="country" className="block text-sm font-medium ">
            Country
          </label>
          <input
            type="text"
            id="country"
            name="country"
            value={formik.values.country}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="mt-1 p-2 block w-full border rounded-md text-gray-500 focus:outline-none border-gray-400"
          />
          {formik.touched.country && formik.errors.country && (
            <div className="text-red-500 text-sm">{formik.errors.country}</div>
          )}
        </div>

        {/* City */}
        <div>
          <label htmlFor="city" className="block text-sm font-medium ">
            City
          </label>
          <input
            type="text"
            id="city"
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="mt-1 p-2 block w-full border rounded-md text-gray-500 focus:outline-none border-gray-400"
          />
          {formik.touched.city && formik.errors.city && (
            <div className="text-red-500 text-sm">{formik.errors.city}</div>
          )}
        </div>

        {/* Socials */}
        <div>
          <label htmlFor="social" className="block text-sm font-medium ">
            Social
          </label>
          <input
            type="text"
            id="social"
            name="social"
            value={formik.values.social}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="mt-1 p-2 block w-full border rounded-md text-gray-500 focus:outline-none border-gray-400"
          />
          {formik.touched.social && formik.errors.social && (
            <div className="text-red-500 text-sm">{formik.errors.social}</div>
          )}
        </div>

        {/* Save Changes Button */}
        <div className="text-right">
          <button
            type="submit"
            className="px-4 py-2 bg-primary rounded-md hover:bg-yellow-500 text-sm font-medium"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditContactForm;
