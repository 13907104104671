import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

// Sample options for select dropdowns
const countryOptions = [
  { value: 'us', label: 'United States' },
  { value: 'ca', label: 'Canada' },
  { value: 'uk', label: 'United Kingdom' },
];

const businessTypeOptions = [
  { value: 'retail', label: 'Retail' },
  { value: 'service', label: 'Service' },
  { value: 'manufacturing', label: 'Manufacturing' },
];

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  operatorName: Yup.string().required('Operator Name is required'),
  dateOfEstablishment: Yup.string().required('Date of Establishment is required'),
  typeOfBusiness: Yup.string().required('Type of Business is required'),
  country: Yup.string().required('Country is required'),
  commercialRegNo: Yup.string().required('Commercial Registration No. is required'),
  officeAddressLine1: Yup.string().required('Office Address Line 1 is required'),
  municipalityRegion: Yup.string().required('Municipality/Region is required'),
  postAdministrativeCity: Yup.string().required('Post Administrative/City is required'),
  suburbTown: Yup.string().required('Suburb or Town is required'),
  contactPhoneNumber: Yup.string().required('Contact Phone Number is required'),
  website: Yup.string().url('Invalid website URL').required('Website is required'),
  contactEmail: Yup.string().email('Invalid email address').required('Contact Email is required'),
});

interface OperatorFormModalProps {
  onSubmit: (values: any) => void;
  onClose: () => void;
}

const OperatorFormModal: React.FC<OperatorFormModalProps> = ({ onSubmit, onClose }) => {
  const initialValues = {
    operatorName: '',
    dateOfEstablishment: '',
    typeOfBusiness: '',
    country: '',
    commercialRegNo: '',
    officeAddressLine1: '',
    officeAddressLine2: '',
    municipalityRegion: '',
    postAdministrativeCity: '',
    suburbTown: '',
    contactPhoneNumber: '',
    website: '',
    contactEmail: '',
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-[900px] p-6 rounded-3xl relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Add New Operator</h2>
          <button className="text-red-500 text-xl" onClick={onClose}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/close.svg`} alt="close" />
          </button>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            // console.log('Form values:', values);
            onSubmit(values);
          }}
        >
          {({ setFieldValue, values }) => (
            <Form className="space-y-4">
              {/* First row */}
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label htmlFor="operatorName" className="block mb-1">
                    Operator Name<span className="text-red-500"> *</span>
                  </label>
                  <Field
                    name="operatorName"
                    placeholder="Operator Name"
                    className="w-full text-sm h-14 border rounded-lg p-2"
                  />
                  <ErrorMessage
                    name="operatorName"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="dateOfEstablishment" className="block mb-1">
                    Date of Establishment
                    <span className="text-red-500"> *</span>
                  </label>
                  <Field
                    name="dateOfEstablishment"
                    type="date"
                    placeholder="MM/DD/YYYY"
                    className="w-full text-sm h-14 border rounded-lg p-2"
                  />
                  <ErrorMessage
                    name="dateOfEstablishment"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="typeOfBusiness" className="block mb-1">
                    Type of Business<span className="text-red-500"> *</span>
                  </label>
                  <select
                    name="typeOfBusiness"
                    onChange={(e) => setFieldValue('typeOfBusiness', e.target.value)}
                    className="w-full text-sm h-14 border rounded-lg p-2"
                  >
                    <option className="text-gray-100" value=" ">
                      Select
                    </option>
                    {businessTypeOptions.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage
                    name="typeOfBusiness"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>

              {/* Second row */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="country" className="block mb-1">
                    Country<span className="text-red-500"> *</span>
                  </label>
                  <select
                    name="country"
                    onChange={(e) => setFieldValue('country', e.target.value)}
                    className="w-full text-sm h-14 border rounded-lg p-2"
                  >
                    <option className="text-gray-100" value=" ">
                      Select
                    </option>
                    {countryOptions.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage name="country" component="div" className="text-red-500 text-sm" />
                </div>

                <div>
                  <label htmlFor="commercialRegNo" className="block mb-1">
                    Commercial Reg. No<span className="text-red-500"> *</span>
                  </label>
                  <select
                    name="commercialRegNo"
                    onChange={(e) => setFieldValue('commercialRegNo', e.target.value)}
                    className="w-full text-sm h-14 border rounded-lg p-2"
                  >
                    <option className="text-gray-100" value=" ">
                      Select
                    </option>
                    <option value="Retail">Retail</option>
                    <option value="Service">Service</option>
                    <option value="Manufacturing">Manufacturing</option>
                  </select>
                  <ErrorMessage
                    name="commercialRegNo"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>

              {/* Third row */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="officeAddressLine1" className="block mb-1">
                    Office Address Line 1<span className="text-red-500"> *</span>
                  </label>
                  <Field
                    name="officeAddressLine1"
                    placeholder="Enter office address"
                    className="w-full text-sm h-14 border rounded-lg p-2"
                  />
                  <ErrorMessage
                    name="officeAddressLine1"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="officeAddressLine2" className="block mb-1">
                    Office Address Line 2
                  </label>
                  <Field
                    name="officeAddressLine2"
                    placeholder="Enter office address"
                    className="w-full text-sm h-14 border rounded-lg p-2"
                  />
                </div>
              </div>

              {/* Fourth row */}
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label htmlFor="municipalityRegion" className="block mb-1">
                    Municipality/Region<span className="text-red-500"> *</span>
                  </label>
                  <select
                    name="municipalityRegion"
                    className="w-full text-sm h-14 border rounded-lg p-2"
                    onChange={(e) => setFieldValue('municipalityRegion', e.target.value)}
                  >
                    <option className="text-gray-100" value=" ">
                      Select
                    </option>
                    <option value="">christianity</option>
                    <option value="">Herbalist</option>
                    <option value="">Islam</option>
                  </select>
                  <ErrorMessage
                    name="municipalityRegion"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="postAdministrativeCity" className="block mb-1">
                    Post Administrative/City
                    <span className="text-red-500"> *</span>
                  </label>
                  <Field
                    name="postAdministrativeCity"
                    placeholder="Enter city"
                    className="w-full text-sm h-14 border rounded-lg p-2"
                  />
                  <ErrorMessage
                    name="postAdministrativeCity"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="suburbTown" className="block mb-1">
                    Suburb or Town<span className="text-red-500"> *</span>
                  </label>
                  <select
                    name="suburbTown"
                    className="w-full text-sm h-14 border rounded-lg p-2"
                    onChange={(e) => setFieldValue('suburbTown', e.target.value)}
                  >
                    <option className="text-gray-100" value=" ">
                      Select
                    </option>
                    <option value="Auckland">Auckland</option>
                    <option value="Wellington">Wellington</option>
                    <option value="Christchurch">Christchurch</option>
                    {/* Add options here */}
                  </select>
                  <ErrorMessage
                    name="suburbTown"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>

              {/* Fifth row */}
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label htmlFor="contactPhoneNumber" className="block mb-1">
                    Contact Phone Number<span className="text-red-500"> *</span>
                  </label>
                  <PhoneInput
                    country={'us'}
                    value={values.contactPhoneNumber}
                    onChange={(phone) => setFieldValue('contactPhoneNumber', phone)}
                    inputClass="!w-full text-sm h-14 border rounded-lg h-11"
                  />
                  <ErrorMessage
                    name="contactPhoneNumber"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div>
                  <label htmlFor="website" className="block mb-1">
                    Website<span className="text-red-500"> *</span>
                  </label>
                  <Field
                    name="website"
                    placeholder="https://www.example.com"
                    className="w-full text-sm h-14 border rounded-lg p-2"
                  />
                  <ErrorMessage name="website" component="div" className="text-red-500 text-sm" />
                </div>

                <div>
                  <label htmlFor="contactEmail" className="block mb-1">
                    Contact Email<span className="text-red-500"> *</span>
                  </label>
                  <Field
                    name="contactEmail"
                    type="email"
                    placeholder="Enter official email"
                    className="w-full text-sm h-14 border rounded-lg p-2"
                  />
                  <ErrorMessage
                    name="contactEmail"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>

              {/* Submit button */}
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-yellow-500 text-black my-[40px] font-bold py-2 px-4 rounded-lg"
                >
                  Add Operator
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default OperatorFormModal;
