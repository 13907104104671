import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import RouterConfig from './router/router';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
// import Navbar from './shared/utils/NavBar/Navbar';
import { GlobalProvider, useGlobalContext } from './contexts/GlobalContext';
import { PuffLoader } from 'react-spinners';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Loader Component that listens to global isLoading state
const GlobalLoader = () => {
  const { isLoading } = useGlobalContext(); // Access the isLoading state from GlobalContext

  return (
    <>
      {isLoading && (
        <div className="spinner-container">
          <div>
            <PuffLoader size={70} color={'#FFC726'} loading={isLoading} />
          </div>
        </div>
      )}
    </>
  );
};

function App() {
  const queryClient = new QueryClient();
  return (
    <div>
      {/* ToastContainer must be present in the TSX */}
      <ToastContainer autoClose={3000} pauseOnFocusLoss={false} />
      <GlobalProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            {/* <Navbar/> */}

            <GlobalLoader />
            <RouterConfig />
          </Router>
        </QueryClientProvider>
      </GlobalProvider>
    </div>
  );
}

export default App;
