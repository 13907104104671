import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createContact, updateContact } from '../../../../services/supplierService';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { PrimaryContact } from './../PrimaryContact/PrimaryContact';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  parsePhoneNumberFromString,
  isPossiblePhoneNumber,
  validatePhoneNumberLength,
  CountryCode,
} from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';

interface ModalContactFormProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: PrimaryContact | null;
  isEditable: boolean;
}

interface ContactFormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  designation: string;
  nationality: string;
  email: string;
  phoneNumber: string;
  terms: boolean;
}

// Utility to handle splitting the fullName into parts
const splitFullName = (fullName: string) => {
  const [firstName = '', middleName = '', lastName = ''] = fullName.split(' ');
  return { firstName, middleName, lastName };
};

const ModalContactForm: React.FC<ModalContactFormProps> = ({
  isOpen,
  onClose,
  isEditable,
  initialValues,
}) => {
  const { supplierId } = useGlobalContext();
  const { t } = useTranslation();
  const [selectedCountryCode, setSelectedCountryCode] = useState('US');

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    middleName: Yup.string(),
    lastName: Yup.string().required('Last Name is required'),
    designation: Yup.string().required('Designation is required'),
    nationality: Yup.string().required('Nationality is required'),
    email: Yup.string()
      .required('Email is required')
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email format'),
    phoneNumber: Yup.string()
      .required(t('required', 'Required'))
      .test('isValidPhoneNumber', t('invalidPhoneNumber', 'Invalid phone number'), (value) => {
        if (!value) return false;
        const phoneNumber = parsePhoneNumberFromString(value, selectedCountryCode as CountryCode); // Replace 'US' with your default country code
        if (!phoneNumber) {
          return false;
        }

        const isLengthValid = validatePhoneNumberLength(phoneNumber.number);

        return isLengthValid === undefined && isPossiblePhoneNumber(phoneNumber.number);
      }),
    terms: Yup.boolean().oneOf([true], 'Please accept the terms and conditions to continue'),
  });

  const formInitialValues = useMemo<ContactFormValues>(() => {
    if (initialValues) {
      const { firstName, middleName, lastName } = splitFullName(initialValues.fullName);
      return {
        firstName,
        middleName,
        lastName,
        designation: initialValues.designation || '',
        nationality: initialValues.nationality || '',
        email: initialValues.email || '',
        phoneNumber: initialValues.phoneNumber || '',
        terms: true,
      };
    }
    return {
      firstName: '',
      middleName: '',
      lastName: '',
      designation: '',
      nationality: '',
      email: '',
      phoneNumber: '',
      terms: false,
    };
  }, [initialValues]);

  const handleSubmit = async (values: ContactFormValues) => {
    try {
      const payload: any = {
        fullName: values.firstName + ' ' + values.middleName + ' ' + values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        companyId: supplierId,
        nationality: values.nationality,
        designation: values.designation,
      };

      if (initialValues) {
        payload.contactId = initialValues.contactId;
        const response = await updateContact(payload);
        if (response.isSuccess) {
          toast.success(t('contactUpdatedSuccessfully', 'Contact updated successfully'));
          onClose();
        }
      } else {
        const response = await createContact(payload);
        if (response.isSuccess) {
          toast.success(t('contactCreatedSuccessfully', 'Contact created successfully'));
          onClose();
        }
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (initialValues) {
      const parsedNumber = parsePhoneNumberFromString(`+${initialValues.phoneNumber}`);
      setSelectedCountryCode(parsedNumber?.country || 'US');
    }
  }, []);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-2xl shadow-lg max-w-5xl">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">
                {t('addPrimaryContact', 'Add Primary Contact')}
              </h2>
              <button className="text-red-500 text-xl" onClick={onClose}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
                  alt={t('close', 'close')}
                />
              </button>
            </div>

            {/* Divider */}
            <div className="divider my-6 !bg-[#E5E5E5]"></div>

            <Formik
              initialValues={formInitialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form className="flex flex-col gap-x-4 gap-y-6">
                  <div className="flex gap-4">
                    <div className="w-[33%]">
                      <label className="block mb-1">
                        {t('firstName', 'First Name')}
                        <span className="text-red-500">*</span>
                      </label>
                      <Field
                        name="firstName"
                        className="border rounded-lg text-sm p-2 h-[33px]"
                        placeholder={t('enterFirstName', 'Enter first name')}
                        disabled={!isEditable} // Set disabled based on isEditable
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="w-[33%]">
                      <label className="block mb-1">{t('middleName', 'Middle Name')}</label>
                      <Field
                        name="middleName"
                        className="border rounded-lg text-sm p-2 h-[33px]"
                        placeholder={t('enterMiddleName', 'Enter middle name')}
                        disabled={!isEditable} // Set disabled based on isEditable
                      />
                    </div>

                    <div className="w-[33%]">
                      <label className="block mb-1">
                        {t('lastName', 'Last Name')}
                        <span className="text-red-500">*</span>
                      </label>
                      <Field
                        name="lastName"
                        className="border rounded-lg text-sm p-2 h-[33px]"
                        placeholder={t('enterLastName', 'Enter last name')}
                        disabled={!isEditable} // Set disabled based on isEditable
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex w-full mt-4">
                    <div className="w-full">
                      <label className="block mb-1">
                        {t('designation', 'Designation')}
                        <span className="text-red-500">*</span>
                      </label>
                      <Field
                        name="designation"
                        className="border rounded-lg text-sm p-2 w-full h-[33px]"
                        placeholder={t('enterDesignation', 'Enter designation')}
                        disabled={!isEditable} // Set disabled based on isEditable
                      />
                      <ErrorMessage
                        name="designation"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex gap-4 mt-4">
                    <div className="w-[33%]">
                      <label className="block mb-1">
                        {t('nationality', 'Nationality')}
                        <span className="text-red-500">*</span>
                      </label>
                      <Field
                        as="select"
                        name="nationality"
                        className="border rounded-lg text-sm p-2 w-full h-[33px]"
                        disabled={!isEditable} // Set disabled based on isEditable
                      >
                        <option value="">{t('selectNationality', 'Select nationality')}</option>
                        <option value="United States">{t('unitedStates', 'United States')}</option>
                        <option value="Nigeria">{t('nigeria', 'Nigeria')}</option>
                        <option value="United Kingdom">
                          {t('unitedKingdom', 'United Kingdom')}
                        </option>
                        {/* Add more nationalities */}
                      </Field>
                      <ErrorMessage
                        name="nationality"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="w-[33%]">
                      <label className="block mb-1">
                        {t('email', 'Email')}
                        <span className="text-red-500">*</span>
                      </label>
                      <Field
                        name="email"
                        type="email"
                        className="border rounded-lg text-sm p-2 h-[33px]"
                        placeholder={t('enterEmail', 'Enter email')}
                        disabled={!isEditable} // Set disabled based on isEditable
                      />
                      <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
                    </div>

                    <div className="w-[33%]">
                      <label className="block mb-1">
                        {t('phoneNumber', 'Phone Number')}
                        <span className="text-red-500">*</span>
                      </label>
                      {/* <Field
                        name="phoneNumber"
                        className="border rounded-lg text-sm p-2 w-[177px] h-[33px]"
                        placeholder={t(
                          'enterPhoneNumber',
                          'Enter phone number'
                        )}
                        disabled={!isEditable} // Set disabled based on isEditable
                      /> */}
                      <PhoneInput
                        country={'us'}
                        disabled={!isEditable}
                        value={values.phoneNumber}
                        onChange={(phone, country: any) => {
                          setFieldValue('phoneNumber', phone);
                          setSelectedCountryCode(country.countryCode.toUpperCase());
                        }}
                        inputClass="!w-full border rounded-lg !h-[33px]"
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </div>

                  {/* Divider */}
                  {isEditable && ( // Only show if editable
                    <div className="divider my-6 !bg-[#E5E5E5]"></div>
                  )}

                  {/* Terms and Conditions */}
                  {isEditable && ( // Only show if editable
                    <div className="">
                      <div className="flex items-center">
                        <Field
                          type="checkbox"
                          name="terms"
                          className="mr-2 w-[15px] h-[15px] bg-primary-500 rounded-lg text-white"
                          disabled={!isEditable} // Set disabled based on isEditable
                        />
                        <label htmlFor="terms" className="text-gray-700 text-sm">
                          {t(
                            'termsAndConditions',
                            'I have fully read and understood the Terms and Conditions. I am Authorized by my company to agree and abide by them.'
                          )}
                        </label>
                      </div>
                      <ErrorMessage name="terms" component="div" className="text-red-500 text-sm" />
                    </div>
                  )}

                  {/* Save Button */}
                  <div className="flex justify-end mt-4">
                    {isEditable && ( // Only show if editable
                      <button
                        type="submit"
                        className="bg-primary text-black px-12 h-12 py-2 rounded-lg font-semibold"
                        disabled={!isEditable || isSubmitting} // Disable when not editable or submitting
                      >
                        {initialValues ? t('update', 'Update') : t('save', 'Save')}
                      </button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalContactForm;
