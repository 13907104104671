'use client';

import { ChangeEvent, useRef, useState } from 'react';
import { FileUploadProps } from '../../types/interfaces';
import AppButton from '../AppButton/AppButton';
import { toast } from 'react-toastify';

export default function FileUpload({
  onFileSelect,
  onUpload = () => {},
  accept = '.jpeg,.jpg,.png,.pdf',
  maxSize = 100 * 1024, // 100KB
  className,
  error,
  fileName,
  isViewOnly = false,
  showUpload = true,
}: FileUploadProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      if (file.size > maxSize) {
        toast.error('File size exceeds the limit');
        return;
      }
      setSelectedFile(file);
      onFileSelect(file);
    }
  };

  const handleChooseFile = () => {
    fileInputRef.current?.click();
  };

  return (
    <div>
      {!isViewOnly && (
        <div className={`space-y-2 ${className ? className : ''}`}>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept={accept}
            className="hidden"
            aria-label="File upload input"
          />
          <div className="flex flex-wrap gap-2 items-center bg-[#F5F5F5] rounded-[5px]">
            <AppButton
              title="Choose File"
              onClick={handleChooseFile}
              className="bg-white"
              outline
            />
            <span className="text-sm text-muted-foreground">
              {selectedFile ? selectedFile.name : 'No File Chosen'}
            </span>
            {showUpload && (
              <AppButton
                title="Upload"
                onClick={onUpload}
                disabled={!selectedFile}
                className="ml-auto px-10 text-base py-3 bg-green-500 text-white rounded-[8px]"
              />
            )}
          </div>
          {error && <p className="text-sm text-destructive">{error}</p>}
          <p className="text-sm text-muted-foreground">
            Please upload .jpeg, .jpg, .png or .pdf, size less than {maxSize / 1024}
            KB
          </p>
        </div>
      )}

      {/* put ur view only code here */}
      <div>
        {isViewOnly && (
          <div className="space-y-4">
            <div className="">
              <div className="flex items-center justify-between bg-gray-100 px-4 py-3 rounded-lg">
                <span className="text-lg text-gray-700 truncate">{fileName}</span>
                <p className="text-lg mr-2 text-green-600 underline">View</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
