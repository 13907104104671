import { toast } from 'react-toastify';
import axiosInstance from '../axiosInstance';
import { Endpoint } from './endpoint/endpoint';

interface PageRequest {
  pageNumber: number;
  pageSize: number;
}

const handleError = (error: any) => {
  if (error?.response) {
    const errorMessage = error?.response?.data?.errors?.[0]?.reason || 'An error occurred';
    toast.error(errorMessage);
  }
};

export const getSuppliers = async (pageRequest: PageRequest) => {
  const url = `${Endpoint.suppliers.getAll}?pageNumber=${pageRequest.pageNumber}&pageSize=${pageRequest.pageSize}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getSupplierById = async (id: string) => {
  const url = Endpoint.suppliers.getOne(id);
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const createSupplier = async (data: any) => {
  const url = Endpoint.suppliers.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const updateSupplier = async (data: any) => {
  const url = Endpoint.suppliers.update;
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteSupplier = async (companyId: string) => {
  const url = Endpoint.suppliers.delete;
  const body = { companyId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const setSupplierStatus = async (supplierId: string, onboardingStatus: number) => {
  const url = Endpoint.suppliers.setStatus;
  try {
    const response = await axiosInstance.patch(url, {
      supplierId,
      onboardingStatus,
    });
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getSupplierProgress = async (supplierId: string) => {
  const url = Endpoint.suppliers.progress(supplierId);
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const checkSupplierSetup = async () => {
  const url = Endpoint.suppliers.checkSetup;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getSupplierHistory = async (supplierId: string) => {
  const url = Endpoint.suppliers.history(supplierId);
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getSubsidiaries = async (supplierId: string | null, pageRequest: PageRequest) => {
  const url = `${Endpoint.suppliers.getSubsidiaries}?companyId=${supplierId}&pageNumber=${pageRequest.pageNumber}&pageSize=${pageRequest.pageSize}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const createPersonnel = async (data: any) => {
  const url = Endpoint.suppliers.personnel.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getPersonnels = async (supplierId: string, pageRequest: PageRequest) => {
  const url = `${Endpoint.suppliers.personnel.get(supplierId)}?pageNumber=${pageRequest.pageNumber}&pageSize=${pageRequest.pageSize}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const updatePersonnel = async (data: any) => {
  const url = Endpoint.suppliers.personnel.update;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const deletePersonnel = async (personnelId: string) => {
  const url = Endpoint.suppliers.personnel.delete;
  const body = { personnelId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const createContact = async (data: any) => {
  const url = Endpoint.suppliers.contact.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getContacts = async (supplierId: string, pageRequest: PageRequest) => {
  const url = `${Endpoint.suppliers.contact.get(supplierId)}?pageNumber=${pageRequest.pageNumber}&pageSize=${pageRequest.pageSize}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const updateContact = async (data: any) => {
  const url = Endpoint.suppliers.contact.update;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteContact = async (contactId: string) => {
  const url = Endpoint.suppliers.contact.delete;
  const body = { contactId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const createShareholder = async (data: any) => {
  const url = Endpoint.suppliers.shareholder.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getShareholders = async (supplierId: string, pageRequest: PageRequest) => {
  const url = `${Endpoint.suppliers.shareholder.get(supplierId)}?pageNumber=${pageRequest.pageNumber}&pageSize=${pageRequest.pageSize}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const updateShareholder = async (data: any) => {
  const url = Endpoint.suppliers.shareholder.update;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteShareholder = async (shareholderId: string) => {
  const url = Endpoint.suppliers.shareholder.delete;
  const body = { shareholderId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const createProject = async (data: any) => {
  const url = Endpoint.suppliers.project.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getProjects = async (supplierId: string, pageRequest: PageRequest) => {
  const url = `${Endpoint.suppliers.project.get}?companyId=${supplierId}&pageNumber=${pageRequest.pageNumber}&pageSize=${pageRequest.pageSize}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const updateProject = async (data: any) => {
  const url = Endpoint.suppliers.project.update;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteProject = async (projectId: string) => {
  const url = Endpoint.suppliers.project.delete;
  const body = { projectId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const createAward = async (data: any) => {
  const url = Endpoint.suppliers.award.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getAwards = async (supplierId: string, pageRequest: PageRequest) => {
  const url = `${Endpoint.suppliers.award.get(supplierId)}?pageNumber=${pageRequest.pageNumber}&pageSize=${pageRequest.pageSize}`;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const updateAward = async (data: any) => {
  const url = Endpoint.suppliers.award.update;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteAward = async (awardId: string) => {
  const url = Endpoint.suppliers.award.delete;
  const body = { awardId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const createIndustry = async (data: any) => {
  const url = Endpoint.suppliers.industry.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getIndustries = async () => {
  const url = Endpoint.suppliers.industry.get;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const updateIndustry = async (supplierId: string, id: string, data: any) => {
  const url = Endpoint.suppliers.industry.update;
  try {
    const response = await axiosInstance.post(url, { supplierId, id, ...data });
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteIndustry = async (supplierId: string, id: string) => {
  const url = Endpoint.suppliers.industry.delete;
  const body = { supplierId, id };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const createAdditional = async (data: any) => {
  const url = Endpoint.suppliers.additionalInfo.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const updateAdditional = async (data: any) => {
  const url = Endpoint.suppliers.additionalInfo.update;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getAdditional = async (supplierId: string) => {
  const url = Endpoint.suppliers.additionalInfo.get(supplierId);
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const deleteAdditional = async (supplierId: string, id: string) => {
  const url = Endpoint.suppliers.additionalInfo.delete;
  const body = { supplierId, id };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};

export const getTags = async (supplierId: string) => {
  const url = Endpoint.suppliers.tags.get(supplierId);
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    handleError(error);
  }
};
