import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import TenderOpportunitiesComponent from './TenderOpportunitiesComponent';
import ProposalsComponent from './ProposalsComponent';

const RegulatorProcurementComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Retrieve the tab from query params or default to 'Tender Opportunities'
  const initialTab = searchParams.get('tab') || 'Tender Opportunities';
  const [activeTab, setActiveTab] = useState(initialTab);

  // Update the query parameter when the active tab changes
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setSearchParams({ tab });
  };

  const handleCreateButtonClick = () => {
    navigate('/procurement/create');
  };

  // Sync the active tab with query params when the component mounts
  useEffect(() => {
    if (searchParams.get('tab') !== activeTab) {
      setSearchParams({ tab: activeTab });
    }
  }, [activeTab, searchParams, setSearchParams]);

  return (
    <div className="mx-auto max-w-screen-xl">
      <div className="p-8">
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="mb-1 text-2xl font-semibold text-gray-900">Electronic Procurement</h1>
              <p className="text-base text-[#525252]">
                Create tender opportunities and get qualified suppliers
              </p>
            </div>

            <button
              className="rounded-md bg-primary px-9 py-3 text-base font-semibold text-black"
              onClick={handleCreateButtonClick}
            >
              + Create
            </button>
          </div>
        </div>
        <div className="mb-6 flex space-x-8 border-b border-gray-200">
          {['Tender Opportunities', 'Proposals'].map((tab) => (
            <button
              key={tab}
              className={`px-4 py-3 text-base font-semibold text-gray-900 ${
                activeTab === tab ? 'border-b-2 border-primary' : ''
              }`}
              onClick={() => handleTabChange(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        {activeTab === 'Tender Opportunities' && <TenderOpportunitiesComponent />}
        {activeTab === 'Proposals' && <ProposalsComponent />}
      </div>
    </div>
  );
};

export default RegulatorProcurementComponent;
