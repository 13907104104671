import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getSingleProcurementProposal } from '../../services/procurementService';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { ProcurementProposalWithTag } from '../../types/procurement-types';
import moment from 'moment';

const ProcurementProposalPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialTab = searchParams.get('tab') || 'Request to View';
  const [activeTab, setActiveTab] = useState(initialTab);

  const { id: serviceInformationId } = useParams<{ id: string }>();

  const { data: proposals = [] } = useQuery({
    queryKey: ['procurements-proposals', activeTab, serviceInformationId],
    queryFn: async () => {
      try {
        const response = await getSingleProcurementProposal({
          isProposal: activeTab === 'Requests for proposal (RFP)',
          isView: activeTab === 'Request to View',
          serviceInformationId: serviceInformationId || '',
        });
        return response?.data || [];
      } catch (error) {
        toast.error('Failed to fetch proposals. Please try again later.');
        throw error;
      }
    },
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    retry: 2,
  });

  const {
    title = 'N/A',
    businessType = 'N/A',
    bidEndDate = null,
    interest = 'N/A',
  } = Array.isArray(proposals) && proposals.length > 0 ? proposals[0] : {};

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setSearchParams({ tab });
  };

  return (
    <div className="mx-auto max-w-screen-xl p-8">
      <h1 className="mb-1 text-2xl font-semibold text-gray-900">Electronic Procurement</h1>

      <div className="2xl:w-11/12">
        <div className="mb-4 mt-8 flex flex-row flex-wrap items-center gap-x-10 gap-y-4 rounded-xl border border-[#E5E5E5] bg-white p-5 pb-12">
          <div className="flex flex-col gap-y-2">
            <span className="text-sm text-[#8D8D8D]">Contract Name</span>
            <span className="text-sm font-medium text-[#525252]">{title}</span>
          </div>

          <div className="flex flex-col gap-y-2">
            <span className="text-sm text-[#8D8D8D]">Type</span>
            <span className="text-sm font-medium text-[#525252]">{businessType}</span>
          </div>

          <div className="flex flex-col gap-y-2">
            <span className="text-sm text-[#8D8D8D]">Date</span>
            <span className="text-sm font-medium text-[#525252]">
              {bidEndDate ? moment(bidEndDate).format('DD/MM/YYYY') : 'N/A'}
            </span>
          </div>

          <div className="flex flex-col gap-y-2">
            <span className="text-sm text-[#8D8D8D]">No of Interests</span>
            <span className="text-sm font-medium text-[#24A148]">{interest}</span>
          </div>
        </div>

        <div className="mb-4 flex border-b border-[#F5F5F5]">
          {['All Bids', 'Requests for proposal (RFP)', 'Request to View'].map((tab) => (
            <button
              key={tab}
              className={`border-b-2 px-4 pb-4 text-sm text-[#101828] ${
                activeTab === tab ? 'border-primary font-semibold' : 'border-transparent'
              }`}
              onClick={() => handleTabChange(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="flex flex-col gap-y-4">
          {Array.from({ length: 4 }).map((_, index) => (
            <SuppliersCard key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

const SuppliersCard: React.FC = () => {
  return (
    <div className="rounded-2xl border border-[#E5E5E5] bg-white px-3.5 pb-4 pt-5">
      <div className="mb-4 flex flex-col justify-between gap-y-6 border-b border-[#E9EFF2] pb-4 md:flex-row md:items-center">
        <div className="flex items-center gap-x-6">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/ShellPix.svg`}
            alt="vendor images"
            className="h-[58px] w-[58px] rounded-full"
          />

          <div className="flex flex-col items-start gap-y-2">
            <span className="font-semibold text-[#101828] underline">
              Green Earth Consultants Ltd
            </span>

            <span className="rounded-[30px] bg-[#42BE65] px-2.5 py-1 text-[10px] font-medium text-white">
              Verified Operator
            </span>
          </div>
        </div>

        <div className="flex flex-col gap-4 sm:flex-row">
          <button className="rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054]">
            View Profile
          </button>

          <button className="rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054]">
            Not Interested
          </button>

          <button className="rounded-lg border border-[#FFC726] bg-[#FFC726] px-3.5 py-2 text-sm font-semibold text-[#344054]">
            Allow Access
          </button>
        </div>
      </div>

      <div className="grid grid-cols-[1.5fr_2fr]">
        <div>
          <p>Ratings & Reviews</p>
          <div className="mb-4 flex gap-x-2.5">
            <div className="flex">
              {Array.from({ length: 4 }).map((_, index) => (
                <img
                  key={index}
                  src={`${process.env.PUBLIC_URL}/assets/icons/outline/ri_star-s-fill.svg`}
                  alt="rating"
                  className="h-[24px] w-[24px] rounded-full"
                />
              ))}
            </div>
            <p>(6 Reviews)</p>
          </div>

          <div>
            <p className="mb-2 text-base font-semibold text-[#292D32]">Services</p>
            <ul className="list-disc pl-4 text-[#667085]">
              <li>Gas Exploration</li>
              <li>Solar Installation</li>
              <li>Quality Assurance</li>
              <li>Maintenance</li>
            </ul>
          </div>
        </div>

        <div className="grid grid-cols-[2fr_1.8fr_2fr] gap-4">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/compressor.png`}
            alt="vendor icon"
            className="h-full w-full rounded-lg"
          />

          <div className="grid grid-cols-2 gap-4">
            {Array.from({ length: 4 }).map((_, index) => (
              <img
                key={index}
                src={`${process.env.PUBLIC_URL}/assets/images/compressor.png`}
                alt="vendor icon"
                className="h-full w-full rounded-lg object-cover"
              />
            ))}
          </div>

          <img
            src={`${process.env.PUBLIC_URL}/assets/images/compressor.png`}
            alt="vendor icon"
            className="h-full w-full rounded-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default ProcurementProposalPage;
