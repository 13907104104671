import React, { useState } from 'react';

interface Notification {
  id: string;
  label: string;
  description: string;
  enabled: boolean;
}

const PopUpNotification: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([
    {
      id: 'pause_all',
      label: 'Pause all',
      description: 'Pause all notifications',
      enabled: false,
    },
    {
      id: 'bid_management',
      label: 'Bid management notification',
      description: 'Your bid for Project X is due in 24 hours.',
      enabled: true,
    },
    {
      id: 'communication_feedback',
      label: 'Communication and feedback notification',
      description: 'The project owner needs more details about your submitted documents.',
      enabled: true,
    },
    {
      id: 'contract_agreement',
      label: 'Contract and agreement notification',
      description: 'Your contract for Project X has been finalized.',
      enabled: false,
    },
    {
      id: 'document_compliance',
      label: 'Document and compliance notification',
      description: 'Your uploaded documents for Project X have been verified.',
      enabled: false,
    },
    {
      id: 'maintenance_inspection',
      label: 'Maintenance and inspection notification',
      description: 'Please upload the inspection report for Project X to proceed.',
      enabled: false,
    },
    {
      id: 'project_interest',
      label: 'Project and expression of interest notification',
      description: 'Your expression of interest has been received for project X.',
      enabled: false,
    },
  ]);

  const toggleNotification = (id: string) => {
    setNotifications((prev) =>
      prev.map((notification) =>
        notification.id === id ? { ...notification, enabled: !notification.enabled } : notification
      )
    );
  };

  return (
    <div className=" mx-auto p-6 bg-white shadow-sm rounded-lg">
      <h2 className=" font-medium text-gray-500 mb-4">Push Notifications</h2>
      <div>
        {notifications.map((notification) => (
          <div key={notification.id} className=" mb-4">
            <div>
              <label htmlFor={notification.id} className="text-sm font-medium text-gray-700 mb-2">
                {notification.label}
              </label>
            </div>
            <div className="flex items-center justify-between py-2 px-2 border border-gray-300 rounded-lg">
              {notification.description && (
                <p className="text-xs text-gray-500">{notification.description}</p>
              )}
              {/* Toggle Button */}
              <button
                onClick={() => toggleNotification(notification.id)}
                className={`relative w-11 h-5 rounded-full focus:outline-none transition ${
                  notification.enabled
                    ? 'bg-blue-500'
                    : 'bg-white border rounded-full border-gray-600'
                }`}
              >
                <span
                  className={`absolute left-1 top-1 h-3 w-3  rounded-full shadow transform transition ${
                    notification.enabled ? 'translate-x-6 bg-white' : 'bg-gray-500 '
                  }`}
                ></span>
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end">
        <button className="mt-4 bg-primary text-sm font-medium py-2 px-4 rounded-lg hover:bg-yellow-600">
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default PopUpNotification;
