import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  ChangePassword,
  ConfirmEmail,
  ForgotPassword,
  OtpData,
  ResendOtp,
} from '../../../services/AccountServices';
import { toast } from 'react-toastify';
import { maskEmail } from '../../../utils/helper';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

interface PasswordFormValues {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

const validationSchema = yup.object().shape({
  currentPassword: yup.string().required('Current Password is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[\W_]/, 'Password must contain at least one special character'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Passwords must match')
    .required('Confirm Password is required'),
});

const PasswordSecurity: React.FC = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [timer, setTimer] = useState<number | null>(null);
  const [isCodeResent, setIsCodeResent] = useState(false);
  const [ispasswordSubmit, setIspasswordSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const email = localStorage.getItem('email') || '';
  const maskedEmail = maskEmail(email);
  const userId = localStorage.getItem('userId') || '';

  const formik = useFormik<PasswordFormValues>({
    initialValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const payload = {
        newPassword: values.password,
        oldPassword: values.currentPassword,
        userId: userId,
      };

      const response = await ChangePassword(payload);
      if (response?.isSuccess) {
        toast.success(response?.data?.message);
        setIspasswordSubmit(false);
        setIsCodeResent(false);
        setVerificationCode('');
        formik.resetForm();
      } else {
        toast.error(response?.errorMessage);
      }
    },
  });

  // Timer Countdown Logic
  React.useEffect(() => {
    if (timer !== null && timer > 0) {
      const countdown = setInterval(
        () => setTimer((prev) => (prev !== null ? prev - 1 : null)),
        1000
      );
      return () => clearInterval(countdown);
    }
  }, [timer]);

  const handleResendCode = async () => {
    const otpData: { email: string } = {
      email,
    };
    const response = await ResendOtp(otpData);
    if (response.isSuccess) {
      // setIsCodeResent(true);
      setTimer(180); // Start a 3-minute 5 secs countdown
    }
  };

  const handleConfirmOtp = async () => {
    const otpData: OtpData = {
      code: verificationCode,
      email,
    };
    const response = await ConfirmEmail(otpData);
    if (response?.isSuccess) {
      setIspasswordSubmit(true);
      // toast.success(response?.data?.message);
    } else {
      toast.error(response?.errorMessage);
    }
  };

  const forgotPassword = async () => {
    const payload = { email: email };
    const response = await ForgotPassword(payload);
    if (response?.isSuccess) {
      setIsCodeResent(true);
      handleResendCode();
      // toast.success(response?.data?.message);
    } else {
      toast.error(response?.errorMessage);
    }
  };

  return (
    <div className="">
      <div className="bg-white shadow-sm rounded-lg px-6 py-4 w-full">
        <h2 className=" font-medium text-gray-500 mb-4">Password and Security</h2>
        <div className="flex gap-1 items-center mb-6">
          <img src={`${process.env.PUBLIC_URL}/assets/icons/outline/info-circle.svg`} alt="info" />
          <p className="text-sm">To protect your account, please complete this verification.</p>
        </div>
        {!ispasswordSubmit ? (
          <div className="">
            <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
              <div className="flex gap-6 items-center mb-4">
                <label className="text-sm font-medium text-gray-500">Email:</label>
                <div className=" flex items-center space-x-6">
                  <span className="text-sm">{maskedEmail}</span>
                  {!isCodeResent && (
                    <p
                      className="text-green-600 underline cursor-pointer decoration-green-600 text-sm"
                      onClick={forgotPassword}
                    >
                      Get Validation Code
                    </p>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-6 mb-2">
                <label className="w-[30%] text-sm font-medium text-gray-500">
                  Verification code:
                </label>
                <input
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  className="w-[30%] px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none text-gray-800 text-sm"
                  placeholder="6 digits"
                  maxLength={6}
                  minLength={6}
                  required
                />
              </div>
              {timer !== null && timer > 0 && (
                <div className="flex gap-2">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/icons/outline/tick-circle.svg`}
                    alt="info"
                  />

                  <p className="text-xs text-gray-600">
                    A verification code has been sent to your email and will be valid for{' '}
                    <span className="text-green-600">
                      ({Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}
                      {timer % 60})
                    </span>
                    . Please keep it confidential.
                  </p>
                </div>
              )}
              <div className="flex justify-center">
                <button
                  onClick={handleConfirmOtp}
                  type="button"
                  className="w-[60%] bg-green-700 hover:bg-green-600 text-white font-medium py-2 px-4 rounded-lg"
                >
                  Submit
                </button>
              </div>
            </form>
            {timer === null || timer === 0 ? (
              <div className="mt-4 text-center">
                <p className="text-sm text-gray-600">
                  Code not received?{' '}
                  <button
                    onClick={handleResendCode}
                    className="text-green-600 underline decoration-green-600"
                  >
                    Click here.
                  </button>
                </p>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            <form onSubmit={formik.handleSubmit} className="space-y-6">
              {/* Current Password Field */}
              <div className="flex gap-6 items-center">
                <label className="w-[30%] text-sm font-medium text-gray-500">
                  Current Password:
                </label>
                <div className="w-full">
                  <div className="flex gap-6 items-center relative">
                    <input
                      type={showCurrentPassword ? 'text' : 'password'}
                      name="currentPassword"
                      value={formik.values.currentPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`mt-1 w-full px-3 py-2 border ${
                        formik.touched.currentPassword && formik.errors.currentPassword
                          ? 'border-red-500'
                          : 'border-gray-400'
                      } rounded-lg shadow-sm focus:outline-none`}
                      placeholder="Enter current password"
                    />
                    <button
                      type="button"
                      className="absolute top-4 right-3 flex items-center text-sm leading-5"
                      onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    >
                      {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>

                  {formik.touched.currentPassword && formik.errors.currentPassword && (
                    <p className=" text-xs text-red-500">{formik.errors.currentPassword}</p>
                  )}
                </div>
              </div>
              {/* Password Field */}
              <div className="flex gap-6 items-center">
                <label className="w-[30%] text-sm font-medium text-gray-500">New Password:</label>
                <div className="w-full">
                  <div className="flex gap-6 items-center relative">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`mt-1 w-full px-3 py-2 border ${
                        formik.touched.password && formik.errors.password
                          ? 'border-red-500'
                          : 'border-gray-400'
                      } rounded-lg shadow-sm focus:outline-none`}
                      placeholder="Enter new password"
                    />
                    <button
                      type="button"
                      className="absolute top-4 right-3 flex items-center text-sm leading-5"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>

                  {formik.touched.password && formik.errors.password && (
                    <p className=" text-xs text-red-500">{formik.errors.password}</p>
                  )}
                </div>
              </div>

              {/* Confirm Password Field */}
              <div className="flex gap-6 items-center">
                <label className="w-[30%] text-sm font-medium text-gray-500">
                  Confirm New Password:
                </label>
                <div className="w-full">
                  <div className="flex gap-6 items-center relative">
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`w-full mt-1 px-3 py-2 border ${
                        formik.touched.confirmPassword && formik.errors.confirmPassword
                          ? 'border-red-500'
                          : 'border-gray-400'
                      } rounded-lg shadow-sm focus:outline-none`}
                      placeholder="Confirm new password"
                    />
                    <button
                      type="button"
                      className="absolute top-4 right-3 flex items-center text-sm leading-5"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <p className=" text-xs text-red-500">{formik.errors.confirmPassword}</p>
                  )}
                </div>
              </div>

              {/* Buttons */}
              <div className="flex justify-end gap-8 mt-4 mb-4">
                <button
                  type="button"
                  onClick={() => setIspasswordSubmit(false)}
                  className="w-1/3 bg-gray-300 hover:bg-gray-400 text-sm font-medium py-3 px-4 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-1/3 bg-primary hover:bg-yellow-600 text-sm font-medium py-3 px-4 rounded-lg"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordSecurity;
