import React, { ChangeEvent, RefObject, useRef } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface Education {
  educationInformationId: string;
  schoolName: string;
  startYear: string;
  endYear: string;
  certificateAwarded: string;
  personalId: string;
  educationLevel: string;
  major: string;
  country: string;
}
interface EducationFormProps {
  parentFormik: any; // or use a more specific type for formik if you have one
  isEditable: boolean;
}

const EducationForm: React.FC<EducationFormProps> = ({ parentFormik, isEditable }) => {
  const { t } = useTranslation();
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const handleFileUploadClick = (inputRef: RefObject<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true }));
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const files = event.currentTarget.files;
    if (files === null) return;
    const file = files[0];

    if (!file) {
      toast.error(t('noFileSelected', 'No file selected'));
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result as string;
      const base64StringWithoutData = base64String.split(',')[1];
      parentFormik.setFieldValue(
        `educationInformations[${index}].certificateAwarded`,
        base64StringWithoutData
      );
    };

    reader.onerror = () => {
      toast.error(t('errorReadingFile', 'Error reading file'));
    };

    reader.readAsDataURL(file);
  };

  const handleAddEducation = () => {
    parentFormik.setFieldValue('educationInformations', [
      ...parentFormik.values.educationInformations,
      {
        educationInformationId: '',
        schoolName: '',
        startYear: '',
        endYear: '',
        certificateAwarded: '',
        personalId: '',
        educationLevel: '',
        major: '',
        country: '',
      },
    ]);
  };

  const handleRemoveEducation = (index: number) => {
    const updatedEducations = parentFormik.values.educationInformations.filter(
      (_: any, i: number) => i !== index
    );
    parentFormik.setFieldValue('educationInformations', updatedEducations);
  };

  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-lg font-semibold">
          {t('educationBackground', 'Education Background')}
        </h2>
        {isEditable && (
          <button
            type="button"
            onClick={handleAddEducation}
            className=" px-4 py-2 w-24 border border-[#101828] text-[#101828] font-semibold rounded-lg"
          >
            {t('add', '+ Add')}
          </button>
        )}
      </div>

      <div className="divider my-6 !bg-[#E5E5E5]"></div>

      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        {parentFormik.values.educationInformations?.map((education: Education, index: number) => (
          <div key={index} className="mb-8 border p-4 rounded-lg">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold">
                {t('education', 'Education')} {index + 1}
              </h2>
              <div className="flex justify-end">
                {index !== 0 && (
                  <img
                    onClick={() => handleRemoveEducation(index)}
                    className="h-7 w-7 cursor-pointer"
                    src={`${process.env.PUBLIC_URL}/assets/images/trash.svg`}
                    alt={t('delete', 'delete')}
                  />
                )}
              </div>
            </div>

            <div className="divider my-6 !bg-[#E5E5E5]"></div>

            <div className="grid grid-cols-3 gap-4 mb-8">
              <div>
                <label htmlFor={`educationInformations[${index}].educationLevel`}>
                  {t('educationLevel', 'Education Level')}
                  <span className="text-red-500">*</span>
                </label>
                <select
                  disabled={!isEditable}
                  name={`educationInformations[${index}].educationLevel`}
                  onChange={parentFormik.handleChange}
                  value={parentFormik.values.educationInformations[index]?.educationLevel || ''}
                  className="w-full text-sm border h-10 rounded-lg"
                >
                  <option value="">{t('select', 'Select')}</option>
                  <option value="bachelors">{t('bachelors', "Bachelor's")}</option>
                  <option value="masters">{t('masters', "Master's")}</option>
                  <option value="phd">{t('phd', 'PhD')}</option>
                </select>
                {parentFormik.errors.educationInformations &&
                  parentFormik.errors.educationInformations[index]?.educationLevel &&
                  parentFormik.touched.educationInformations &&
                  parentFormik.touched.educationInformations[index]?.educationLevel && (
                    <div className="text-red-500 text-sm">
                      {parentFormik.errors.educationInformations[index].educationLevel}
                    </div>
                  )}
              </div>

              <div>
                <label htmlFor={`educationInformations[${index}].schoolName`}>
                  {t('university', 'University')}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  disabled={!isEditable}
                  name={`educationInformations[${index}].schoolName`}
                  onChange={parentFormik.handleChange}
                  value={parentFormik.values.educationInformations[index]?.schoolName || ''}
                  placeholder={t('enterUniversity', 'Enter University')}
                  className="w-full text-sm border h-10 rounded-lg"
                />
                {parentFormik.errors.educationInformations &&
                  parentFormik.errors.educationInformations[index]?.schoolName &&
                  parentFormik.touched.educationInformations &&
                  parentFormik.touched.educationInformations[index]?.schoolName && (
                    <div className="text-red-500 text-sm">
                      {parentFormik.errors.educationInformations[index].schoolName}
                    </div>
                  )}
              </div>

              <div>
                <label htmlFor={`educationInformations[${index}].major`}>
                  {t('major', 'Major')}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  disabled={!isEditable}
                  name={`educationInformations[${index}].major`}
                  onChange={parentFormik.handleChange}
                  value={parentFormik.values.educationInformations[index]?.major || ''}
                  placeholder={t('enterMajor', 'Enter Major')}
                  className="w-full text-sm border h-10 rounded-lg"
                />
                {parentFormik.errors.educationInformations &&
                  parentFormik.errors.educationInformations[index]?.major &&
                  parentFormik.touched.educationInformations &&
                  parentFormik.touched.educationInformations[index]?.major && (
                    <div className="text-red-500 text-sm">
                      {parentFormik.errors.educationInformations[index].major}
                    </div>
                  )}
              </div>
            </div>

            <div className="grid grid-cols-3 gap-4 mb-8">
              <div>
                <label htmlFor={`educationInformations[${index}].startYear`}>
                  {t('yearOfEntry', 'Year of Entry')}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  disabled={!isEditable}
                  name={`educationInformations[${index}].startYear`}
                  onChange={parentFormik.handleChange}
                  value={parentFormik.values.educationInformations[index]?.startYear || ''}
                  className="w-full text-sm border h-10 rounded-lg"
                />
                {parentFormik.errors.educationInformations &&
                  parentFormik.errors.educationInformations[index]?.startYear &&
                  parentFormik.touched.educationInformations &&
                  parentFormik.touched.educationInformations[index]?.startYear && (
                    <div className="text-red-500 text-sm">
                      {parentFormik.errors.educationInformations[index].startYear}
                    </div>
                  )}
              </div>

              <div>
                <label htmlFor={`educationInformations[${index}].endYear`}>
                  {t('yearOfGraduation', 'Year of Graduation')}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="date"
                  disabled={!isEditable}
                  name={`educationInformations[${index}].endYear`}
                  onChange={parentFormik.handleChange}
                  max={new Date().toISOString().split('T')[0]}
                  value={parentFormik.values.educationInformations[index]?.endYear || ''}
                  className="w-full text-sm border h-10 rounded-lg"
                />
                {parentFormik.errors.educationInformations &&
                  parentFormik.errors.educationInformations[index]?.endYear &&
                  parentFormik.touched.educationInformations &&
                  parentFormik.touched.educationInformations[index]?.endYear && (
                    <div className="text-red-500 text-sm">
                      {parentFormik.errors.educationInformations[index].endYear}
                    </div>
                  )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EducationForm;
