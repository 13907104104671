import React, { InputHTMLAttributes } from 'react';
import { useField } from 'formik';

type FormikInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  name: string;
  type?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'url';
  isRequired?: boolean;
  inputClassName?: string;
};

const FormikInput: React.FC<FormikInputProps> = ({
  label,
  isRequired,
  inputClassName,
  ...props
}) => {
  const [field, meta] = useField({
    name: props.name,
    type: props.type,
    value: props.value,
    multiple: props.multiple,
  });

  return (
    <div className={`w-full mb-5 ${inputClassName}`}>
      {label ? (
        <label
          htmlFor={props.id || props.name}
          className="block text-sm font-medium text-[#344054] mb-1.5"
        >
          {label}
          {isRequired && <span className="text-red-500 ml-1">*</span>}
        </label>
      ) : null}

      <input
        {...field}
        {...props}
        className={`
          w-full px-3.5 py-2.5 border rounded-md shadow-sm
          
          ${meta.touched && meta.error ? 'border-red-500' : 'border-[#D0D5DD]'}
          ${props.className || ''}
        `}
      />

      {meta.touched && meta.error ? (
        <div className="mt-1 text-sm text-red-600">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default FormikInput;
