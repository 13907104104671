import React, { useState } from 'react';

type UploadInputProps = {
  label: string;
  acceptedFileTypes?: string[]; // Example: ['image/png', 'application/pdf']
  maxFileSizeMB?: number; // Maximum file size in MB
  multiple?: boolean;
  onUpload: (files: File[]) => void;
};

const UploadInput: React.FC<UploadInputProps> = ({
  label,
  acceptedFileTypes = [],
  maxFileSizeMB = 5,
  multiple = false,
  onUpload,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [error, setError] = useState<string | null>(null);

  const handleFiles = (files: FileList | null) => {
    if (!files) return;

    const fileArray = Array.from(files);
    const validFiles: File[] = [];
    let errorMessage = '';

    fileArray.forEach((file) => {
      if (acceptedFileTypes.length && !acceptedFileTypes.includes(file.type)) {
        errorMessage = `Invalid file type: ${file.name}`;
      } else if (file.size > maxFileSizeMB * 1024 * 1024) {
        errorMessage = `File is too large: ${file.name}`;
      } else {
        validFiles.push(file);
      }
    });

    if (errorMessage) {
      setError(errorMessage);
    } else {
      setError(null);
      setUploadedFiles([...uploadedFiles, ...validFiles]);
      onUpload(validFiles);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFiles(e.target.files);
    e.target.value = ''; // Clear input value for re-upload
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files);
  };

  return (
    <div className="mb-5">
      <label className="block text-sm font-medium text-[#344054] mb-2">{label}</label>
      <div
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
        className="relative border-2 border-dashed border-[#D0D5DD] rounded-lg p-4 text-center bg-gray-50 hover:bg-gray-100"
      >
        {/* Upload Button */}
        <input
          type="file"
          multiple={multiple}
          accept={acceptedFileTypes.join(',')}
          onChange={handleInputChange}
          className="absolute inset-0 opacity-0 cursor-pointer"
        />
        <div>
          <p className="text-sm text-gray-500">
            Drag and drop files here or <span className="text-[#42BE65] font-medium">browse</span>
          </p>
          <p className="text-xs text-gray-400">
            Supported formats: {acceptedFileTypes.join(', ') || 'Any'} | Max size: {maxFileSizeMB}MB
          </p>
        </div>
      </div>

      {/* Error Message */}
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
      {/* Uploaded Files */}
      {uploadedFiles.length > 0 ? (
        <ul className="mt-2 text-sm text-gray-600">
          {uploadedFiles.map((file, index) => (
            <li key={index} className="flex items-center justify-between">
              {file.name}{' '}
              <span className="text-gray-400 text-xs">
                ({(file.size / 1024 / 1024).toFixed(2)} MB)
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-sm text-[#667085]">
          Please upload .jepg, .jpg, .png or .pdf, .doc or docx, .html, .csv, .xml. Size less than
          100KB
        </p>
      )}
    </div>
  );
};

export default UploadInput;
