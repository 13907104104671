import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTable, { Column } from '../CustomTable';
import {
  deleteDocument,
  downloadDocument,
  getDocuments,
  viewDocuments,
} from '../../services/edmsService';
import { DocumentInterface } from '../../types/interfaces';
import Delete from '../Delete/Delete';
import { toast } from 'react-toastify';
import AddDocument from './AddDocument';

interface DocumentListProps {
  isEditable: boolean;
  entityId: string | null;
  module: string;
  subModule?: string | null;
  documentTypeList: { key: string; value: string }[];
  documents: DocumentInterface[];
  fetchDocuments: () => void;
}

const DocumentList: React.FC<DocumentListProps> = ({
  isEditable = true,
  entityId,
  module,
  subModule = null,
  documentTypeList,
  documents,
  fetchDocuments,
}) => {
  const { t } = useTranslation();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<DocumentInterface | null>(null);

  const handleDeleteDocument = async () => {
    if (!selectedDocument) return;
    setIsDeleteModalOpen(false);
    const payload = {
      documentId: selectedDocument.documentId,
      module,
      ...(subModule && { subModule }),
    };
    const response = await deleteDocument(payload);
    if (response?.isSuccess) {
      toast.success(response?.data.message);
      fetchDocuments();
    }
  };

  const handleViewDocument = (doc: DocumentInterface) => async () => {
    const request = {
      documentId: doc.documentId,
      module,
      ...(subModule && { subModule }),
    };

    try {
      const response = await downloadDocument(request);
      if (response) {
        const fileExtension = doc.documentName.split('.').pop()?.toLowerCase();
        if (fileExtension === 'pdf') {
          // Open the PDF in a new tab
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const newTab = window.open(url, '_blank');
          if (!newTab) {
            toast.error('Popup blocked. Please allow popups to view the document.');
          }
        } else {
          // Download the file
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', doc.documentName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        toast.error('Failed to view document');
      }
    } catch (error) {
      toast.error('An error occurred while viewing the document');
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <div className="space-y-4 mx-4">
      <div className="border border-[#E5E5E5] rounded-md ">
        <div className="grid grid-cols-[7fr_1fr] items-center p-3 pr-6 border-b border-[#E5E5E5]">
          <span className="text-[#8D8D8D] text-sm">Documents</span>
          <button
            onClick={() => {
              setIsAddModalOpen(true);
              setSelectedDocument(null);
            }}
            className="px-3 py-1.5 bg-white border border-black text-[#101828] font-medium rounded-lg"
          >
            + Add
          </button>
        </div>

        {documents?.map((item, index) => (
          <div
            key={item.documentId}
            className={`grid grid-cols-[7fr_1fr] items-center p-3 pr-6 ${documents?.length - 1 !== index ? 'border-b border-[#E5E5E5]' : ''}`}
          >
            <span className="text-[#252C32] text-sm">{item.documentName}</span>

            <div className="flex gap-x-3 justify-end">
              <button
                onClick={handleViewDocument(item)}
                className="text-[#42BE65] border-b border-[#42BE65] text-sm font-semibold"
              >
                View
              </button>
              <button
                className="text-error border-b border-error text-sm font-semibold"
                onClick={() => {
                  setSelectedDocument(item);
                  setIsDeleteModalOpen(true);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {isDeleteModalOpen && (
        <Delete
          title={t('areYouSure', 'Are you sure?')}
          desc={t('deleteConfirmation', 'This action is permanent, all data would be lost.')}
          onDelete={handleDeleteDocument}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}

      {/* Add Document Modal */}
      {isAddModalOpen && (
        <AddDocument
          onClose={() => {
            setIsAddModalOpen(false);
            setSelectedDocument(null);
          }}
          onAdd={() => {
            fetchDocuments();
            setIsAddModalOpen(false);
            setSelectedDocument(null);
          }}
          isEditable={isEditable}
          entityId={entityId}
          module={module}
          subModule={subModule}
          documentTypeList={documentTypeList}
        />
      )}
    </div>
  );
};

export default DocumentList;
