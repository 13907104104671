// Module.tsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import ModuleCard from '../../../components/moduleCard/ModuleCard';
import './Module.css';
import Navbar from '../../../layouts/NavBar/Navbar';

// Define the props for the reusable module component
interface ModuleProps {
  title: string;
  desc: string;
  modulesData: Array<{
    id: number;
    imageSrc: string;
    moduleTitle: string;
    moduleType: string;
    link: string;
    external: boolean;
  }>;
}

const Module: React.FC<ModuleProps> = ({ title, desc, modulesData }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-[#f9fafb] min-h-screen">
      <div className="max-w-4xl pt-[50px] mx-5 lg:mx-auto ">
        <div className="w-full flex flex-col gap-3 mb-6">
          <p className="textStyle">{t(title)}</p>
          <p className="textStyle2">{t(desc)}</p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {modulesData.map((module) => (
            <ModuleCard
              key={module.id}
              imageSrc={module.imageSrc}
              moduleTitle={t(module.moduleTitle)}
              moduleType={t(module.moduleType)}
              link={module.link}
              external={module.external}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Module;
