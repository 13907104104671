import React, { useEffect, useState } from 'react';
import Cards from '../../../components/CatalogueCards/Cards';
import { IProduct, MyCards, ProductsProps } from '../../../types/interfaces';
import AppButton from '../../../components/AppButton/AppButton';
import { toast } from 'react-toastify';
import { IPageRequest } from '../../../components/CustomTable';
import { ProductType } from '../../../types/enums';
import { getGoodsList } from '../../../services/catalogService';
import { useNavigate } from 'react-router-dom';

const Services: React.FC<ProductsProps> = ({ companyId }) => {
  const [pageSize, setPageSize] = useState(12);
  const [products, setProducts] = useState<IProduct[]>([]);
  const navigate = useNavigate();

  const pageRequest: IPageRequest = {
    pageNumber: 1,
    pageSize: pageSize,
  };

  const viewMoreProducts = () => {
    setPageSize((prevPageSize) => prevPageSize + 12);
    fetchProducts();
  };

  const handleViewProduct = (productId: any) => {
    navigate(`/services/detail?Id=${productId}`, {
      state: {
        productType: ProductType.SERVICES,
      },
    });
  };

  const fetchProducts = async () => {
    if (!companyId) return setProducts([]);

    const fetchRequest = {
      supplierId: companyId,
      productType: ProductType.SERVICES,
      pageNumber: pageRequest.pageNumber,
      pageSize: pageRequest.pageSize,
    };
    const response = await getGoodsList(fetchRequest);
    if (response) {
      setProducts(response.data);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      <div className="border border-gray-300 bg-white shadow-sm rounded-lg px-8 py-6">
        <div>
          <h2 className="text-2xl font-medium mb-2">Services</h2>
        </div>
        <hr className="mb-6" />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-10">
          {products.map((product, index) => (
            <Cards
              key={index}
              imagePath={
                product?.productImages.length > 0
                  ? product?.productImages[0].sharepointPath
                  : 'images/compressor.svg'
              }
              showDefaultImage={product?.productImages.length <= 0}
              title={product.productName}
              ratings={product.ratings ?? '3'}
              ratingsCount={product.ratingsCount ?? '1.5k'}
              location={product.location ?? 'USA'}
              price={product.price.toString()}
              showPrice={true}
              showRatings={true}
              showLocation={true}
              onClick={() => handleViewProduct(product.productId)}
            />
          ))}
        </div>
        {products.length > 12 && (
          <div className="flex justify-center">
            <AppButton
              title={'View More'}
              onClick={viewMoreProducts}
              className="border border-gray-400 py-3 px-10 rounded-xl font-semibold"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Services;
