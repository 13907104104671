import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSupportedLanguages } from '../../../services/utilityServices';
import { toast } from 'react-toastify';

const Language = () => {
  const { t, i18n } = useTranslation();

  // Available languages
  const [languages, setLanguages] = useState<{ code: string; label: string }[]>([
    { code: 'tet', label: 'Tetum' },
    { code: 'en-US', label: 'English' },
    { code: 'pt-PT', label: 'Portugues' },
  ]);

  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const storedLangCode = localStorage.getItem('i18nextLng') || 'tet';
    const storedLang = languages.find((lang) => lang.code === storedLangCode);
    return storedLang ? storedLang.label : languages[0].label;
  });

  const [languageCode, setLanguageCode] = useState(() => {
    return localStorage.getItem('i18nextLng') || 'tet';
  });

  const handleLanguageChange = (code: string) => {
    setLanguageCode(code);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    i18n.changeLanguage(languageCode);
    const selectedLang = languages.find((lang) => lang.code === languageCode)?.label;
    setSelectedLanguage(selectedLang || selectedLanguage);
  };

  useEffect(() => {
    const fetchSupportedLanguages = async () => {
      const response = await getSupportedLanguages();
      if (response) {
        const supportedLanguages = response.map((lang: any) => ({
          code: lang.culture,
          label: lang.displayName.split(' (')[0].trim(),
        }));
        setLanguages(supportedLanguages);
      } else {
        toast.error('Failed to fetch supported languages');
      }
    };

    fetchSupportedLanguages();
  }, []);

  return (
    <div className="">
      <div className="bg-white shadow-sm rounded-lg px-6 py-4 w-full">
        <h2 className="font-medium text-gray-500 mb-4">Language and Time zone</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="language" className="block text-sm font-semibold text-gray-700">
              Language settings
            </label>
            <select
              id="language"
              name="language"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-2 border-gray-400 focus:outline-none sm:text-sm rounded-lg"
              onChange={(e) => handleLanguageChange(e.target.value)}
              value={languageCode}
            >
              {languages.map((language) => (
                <option key={language.code} value={language.code}>
                  {language.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="mt-4 bg-primary text-sm font-medium py-2 px-4 rounded-lg hover:bg-yellow-600"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Language;
