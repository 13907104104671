import React, { useEffect, useState } from 'react';
import {
  FaChevronRight,
  FaStar,
  FaShoppingCart,
  FaChevronDown,
  FaMinus,
  FaPlus,
} from 'react-icons/fa';
import AppButton from '../../../components/AppButton/AppButton';
import Chips from '../../../components/Chips/Chips';
import { ChipBtn, IProduct, IReview } from '../../../types/interfaces';
import Carousel from '../../../components/Carousel/Carousel';
import Breadcrumb from '../../../components/BreadCrumb/BreadCrumb';
import {
  addToCart,
  createReview,
  getAllCategories,
  getProductById,
  getReviews,
} from '../../../services/catalogService';
import { getTags } from '../../../services/supplierService';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProductType } from '../../../types/enums';

const ProductDetails = () => {
  const [product, setProduct] = useState<IProduct | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [quantity, setQuantity] = useState<number>(0);
  const [categories, setCategories] = useState<{ categorId: string; categoryName: string }[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<{
    categorId: string;
    categoryName: string;
  } | null>(null);
  const [comment, setComment] = useState<string>('');
  const [reviews, setReviews] = useState<IReview[]>([]);
  const [productImages, setProductImages] = useState<string[]>([]);

  const { supplierId, setSupplierId } = useGlobalContext();
  const urlParams = new URLSearchParams(window.location.search);
  const productId = urlParams.get('Id');
  const location = useLocation();
  const productType: ProductType = location.state?.productType;
  const navigate = useNavigate();

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleQuantity = (type: 'increment' | 'decrement') => {
    if (type === 'increment') {
      if (quantity === product?.quantity) return;
      setQuantity(quantity + 1);
    } else {
      if (quantity === 0) return;
      setQuantity(quantity - 1);
    }
  };

  const paths = [
    { label: 'Dashboard', path: '/dashboard' },
    { label: 'Catalogue', path: '/catalogue' },
    {
      label: productType === ProductType.SERVICES ? 'Service' : 'Product',
      path: productType === ProductType.SERVICES ? '/services' : '/product',
    },
  ];
  const images = [
    `${process.env.PUBLIC_URL}/assets/images/compressor.svg`,
    `${process.env.PUBLIC_URL}/assets/images/compressor.svg`,
    `${process.env.PUBLIC_URL}/assets/images/compressor.svg`,
    `${process.env.PUBLIC_URL}/assets/images/compressor.svg`,
    `${process.env.PUBLIC_URL}/assets/images/compressor.svg`,
    `${process.env.PUBLIC_URL}/assets/images/compressor.svg`,
  ];

  const fetchProduct = async () => {
    if (!productId) return;
    const response = await getProductById(productId);
    if (response?.isSuccess) {
      setProduct(response.data);
      // setQuantity(response.data.quantity || 0);
      setProductImages(
        response.data.productImages
          ? response.data.productImages.map(
              (image: { sharepointPath: string }) => image.sharepointPath
            )
          : []
      );
      setSelectedCategory(
        categories.find((category) => category.categorId === response.data.categoryId) || null
      );
    }
  };

  const handleReviewPublish = async (comment: string) => {
    if (!productId) return;
    const payload = {
      reviewerName: 'Rebecca Taylor',
      comment,
      rating: 0,
      productId: productId,
    };
    const response = await createReview(payload);
    if (response?.isSuccess) {
      setComment('');
      toast.success(response?.data?.message);
      getProductReviews();
    } else {
      toast.error(response?.errorMessage);
    }
  };

  const getProductReviews = async () => {
    if (!productId) return;
    const response = await getReviews(productId);
    if (response?.isSuccess) {
      setReviews(response.data);
    }
  };

  const viewProfile = () => {
    navigate('/settings/supplier-profile', { state: { companyId: product?.companyId } });
  };

  const handleAddToCart = async () => {
    if (!productId) return;
    const payload = {
      customerId: supplierId,
      itemsList: [
        {
          quantity,
          cost: product?.price || 0,
          productId,
        },
      ],
    };
    const response = await addToCart(payload);
    if (response?.isSuccess) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.errorMessage);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const params = { isParentCategory: false };
      const response = await getAllCategories(params);
      if (response?.isSuccess) {
        setCategories(response.data);
      }
    };

    const fetchSupplierTags = async () => {
      if (!supplierId) return;
      const response = await getTags(supplierId);
      if (response?.isSuccess) {
        setTags(response.data);
      }
    };

    fetchCategories();
    fetchSupplierTags();
    getProductReviews();
    fetchProduct();
  }, [productId]);

  return (
    <>
      <div className="py-5">
        <Breadcrumb paths={paths} />
        <div className="w-full flex mt-6 mb-8 gap-8 justify-between">
          <div className=" w-[56%]">
            <div>
              <img
                src={productImages[0] || ``}
                alt={'compressor'}
                className={`w-full h-[50vh] object-cover rounded-t-lg`}
              />
            </div>
            <div className="w-full mt-6 ">
              <Carousel images={productImages} />
            </div>
          </div>
          <div className="w-[44%]">
            <div className="mt-4">
              <h2 className="text-3xl mb-3">{product?.productName}</h2>
              <h2 className="text-2xl mb-5">
                {product?.price.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
                {productType === ProductType.SERVICES && '/mth'}
              </h2>
            </div>
            <hr />
            <div className="mt-7 mb-8">
              <p className="w-[80%] text-xl text-gray-600">{product?.description}</p>
              <div className="flex justify-between mt-5 items-center">
                <div className="flex gap-4 items-center">
                  <div className="flex space-x-1 text-yellow-300">
                    {[...Array(5)].map((_, index) => (
                      <FaStar key={index} size={'16px'} />
                    ))}
                  </div>
                  <p className="underline text-gray-600 text-lg">{reviews.length} Reviews</p>
                </div>
                <div className="flex gap-4 items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/heart.svg`}
                    alt={'like'}
                    className={`h-[15px] w-[15px] object-cover rounded-t-lg`}
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/share.svg`}
                    alt={'share'}
                    className={`h-[15px] w-[15px] object-cover rounded-t-lg`}
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="mt-7 mb-11 items-center">
              {productType === ProductType.GOODS && (
                <div className="flex justify-between items-center mb-9">
                  <div className="text-2xl text-gray-600">
                    <h2>Quantity</h2>
                  </div>
                  <div className="flex w-[20%] items-center justify-between">
                    <button
                      onClick={() => handleQuantity('decrement')}
                      className="text-xl text-gray-600"
                    >
                      <FaMinus size={10} />
                    </button>
                    <p>{quantity}</p>
                    <button
                      onClick={() => handleQuantity('increment')}
                      className="text-xl text-gray-600"
                    >
                      <FaPlus size={10} />
                    </button>
                  </div>
                </div>
              )}
              <div className="flex gap-4">
                <div className="flex bg-primary px-6 py-4 border rounded-lg text-xl gap-2 items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/messages-2.svg`}
                    alt={'chat'}
                    className={`h-[15px] w-[15px] object-cover rounded-t-lg`}
                  />
                  <AppButton title={'Chat Supplier'} onClick={() => toast.info('Chat Supplier')} />
                </div>
                <div className="flex bg-gray-400 px-4 py-4 border rounded-lg text-xl gap-2 items-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/eye.svg`}
                    alt={'view profile'}
                    className={`h-[15px] w-[15px] object-cover rounded-t-lg`}
                  />
                  <AppButton title={'View Profile'} onClick={viewProfile} />
                </div>
                {productType === ProductType.GOODS && (
                  <div className="flex bg-gray-400 px-6 py-4 border rounded-lg text-xl gap-2 items-center">
                    <FaShoppingCart size={'16px'} />
                    <AppButton title={'Add to Cart'} onClick={handleAddToCart} />
                  </div>
                )}

                {productType === ProductType.SERVICES && (
                  <div className="flex bg-gray-400 px-4 py-4 border rounded-lg text-xl gap-2 items-center">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/icons/outline/receipt-edit.svg`}
                      alt={'Request Quote'}
                      className={`h-[15px] w-[15px] object-cover rounded-t-lg`}
                    />
                    <AppButton
                      title={'Request Quote'}
                      onClick={() => toast.info('Your request for quote is pending')}
                    />
                  </div>
                )}
              </div>
            </div>
            <hr />
            <div className="flex gap-8 mt-7">
              <div className="space-y-3">
                {/* <p>
                  <span className="font-semibold text-lg">Product ID:</span>{' '}
                  <span className="text-lg"></span>
                </p> */}
                <p>
                  <span className="font-semibold text-lg">Category:</span>{' '}
                  <span className="text-lg">{selectedCategory?.categoryName}</span>
                </p>
                {productType === ProductType.GOODS && (
                  <p>
                    <span className="font-semibold text-lg">Delivery:</span>{' '}
                    <span className="text-lg">{product?.distributionMechanism}</span>
                  </p>
                )}
              </div>
              <div className="space-y-3">
                {productType === ProductType.GOODS && (
                  <p>
                    <span className="font-semibold text-lg">Availability:</span>{' '}
                    <span
                      className={`text-lg ${product?.quantity && product.quantity > 0 ? 'text-green-500' : 'text-red-500'}`}
                    >
                      {product?.quantity && product.quantity > 0 ? 'In stock' : 'Out of stock'}
                    </span>
                  </p>
                )}
                {productType === ProductType.GOODS && (
                  <p>
                    <span className="font-semibold text-lg">Colour:</span>{' '}
                    <span className="text-lg"></span>
                  </p>
                )}
                {productType === ProductType.SERVICES && (
                  <p>
                    <span className="font-semibold text-lg">Location:</span>{' '}
                    <span className="text-lg">{product?.location}</span>
                  </p>
                )}
                {/* <p>
                  <span className="font-semibold text-lg">Delivery:</span>{' '}
                  <span className="text-lg">Houston, Texas</span>
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-4 mb-8">
          {tags.map((tag, index) => (
            <Chips key={index} title={tag.tagName} bgColor={'bg-green-50'} />
          ))}
        </div>
        <div className="w-[56%] border rounded-2xl py-3 px-6 mb-8">
          <div className="flex items-center gap-2">
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/Notebook.svg`}
                alt={'additional Instructions'}
                className={`w-full object-cover rounded-t-lg`}
              />
            </div>
            <div>
              <p className="text-xl text-gray-600">Additional Instructions</p>
            </div>
          </div>
          <div>
            <p className="text-gray-600 text-lg mb-8 ">
              Contractors should demonstrate strong project management capabilities and a proven
              track record in diverse geographical conditions. All work must adhere to the highest
              industry standard for safety and quality
            </p>
          </div>
        </div>
        <div className="w-[70%] mb-3">
          <div className="border rounded-2xl shadow-sm my-4">
            {/* Accordion Header */}
            <button
              onClick={toggleAccordion}
              className="w-full flex justify-between items-center p-4 text-gray-800 font-semibold"
            >
              <span>
                Reviews <span>({reviews.length})</span>
              </span>
              <span
                className={`transform transition-transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}
              >
                <FaChevronDown />
              </span>
            </button>
            <hr />

            {/* Accordion Content */}
            {isOpen && (
              <div className="p-4 bg-white text-gray-700">
                {reviews.map((review, index) => (
                  <div className="mb-3">
                    <div className="text-gray-600 text-lg mb-2">
                      <p>{review.comment}</p>
                    </div>
                    <div className="italic font-semibold text-lg">
                      <span>{review.reviewerName}</span>
                      <span> - </span>
                      <span>
                        {new Date(review.createdDate).toLocaleString('en-US', {
                          month: 'long',
                          day: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        {/* <div className="w-[70%] mb-3">
          <div className="border rounded-2xl shadow-sm my-4 p-4">
            <h3 className="text-xl font-semibold mb-4">Add a Review</h3>
            <textarea
              className="w-full p-2 border rounded-lg mb-4"
              rows={4}
              placeholder="Write your review here..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
            <div className="flex justify-end">
              <button
                className={`bg-green-500 text-white px-4 py-2 rounded-lg ${comment.trim() === '' ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={() => handleReviewPublish(comment)}
                disabled={comment.trim() === ''}
              >
                Publish Review
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ProductDetails;
