import React, { useMemo } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AwardInterface } from './../ProjectAndAward/ProjectAndAward';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { createAward, updateAward } from '../../../../services/supplierService';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface ProjectModalProps {
  onClose: () => void;
  initialValues?: AwardInterface | null;
  isEditable: boolean;
}

const validationSchema = Yup.object().shape({
  awardName: Yup.string().required('Title of Award is required'),
  issuedBy: Yup.string().required('Issuing body is required'),
  issuedDate: Yup.string().required('Year of Award is required'),
});

const AwardModal: React.FC<ProjectModalProps> = ({ onClose, initialValues, isEditable }) => {
  const { supplierId } = useGlobalContext();
  const { t } = useTranslation();

  const formInitialValues = useMemo<AwardInterface>(() => {
    if (initialValues) {
      return {
        ...initialValues,
        issuedDate: initialValues?.issuedDate
          ? new Date(initialValues?.issuedDate).toISOString().split('T')[0]
          : '',
      };
    }
    return {
      awardId: '',
      awardName: '',
      issuedBy: '',
      issuedDate: '',
      companyId: '',
    };
  }, [initialValues]);

  const handleSubmit = async (values: AwardInterface) => {
    try {
      const payload = {
        ...values,
        companyId: supplierId,
      };

      if (initialValues) {
        payload.awardId = initialValues.awardId;
        const response = await updateAward(payload);
        if (response.isSuccess) {
          toast.success(t('awardUpdatedSuccessfully', 'Award updated successfully'));
          onClose();
        }
      } else {
        const { awardId, ...payloadWithoutProjectId } = payload;
        const response = await createAward({
          ...payloadWithoutProjectId,
        });
        if (response.isSuccess) {
          toast.success(t('awardCreatedSuccessfully', 'Award created successfully'));
          onClose();
        }
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-10">
      <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-4xl">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{t('addAward', 'Add Award')}</h2>
          <button className="text-red-500 text-xl" onClick={onClose}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
              alt={t('close', 'close')}
            />
          </button>
        </div>

        {/* Divider */}
        <div className="divider my-6 !bg-[#E5E5E5]"></div>

        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className="flex flex-col gap-x-4 gap-y-6">
            {/* Row for the first two inputs */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label htmlFor="awardName" className="block mb-1">
                  {t('titleOfAward', 'Title of Award')}
                  <span className="text-red-500"> *</span>
                </label>
                <Field
                  disabled={!isEditable}
                  name="awardName"
                  placeholder={t('enterTitleOfAward', 'Enter Title of Award')}
                  className="w-full border rounded-lg text-sm p-2 h-11"
                />
                <ErrorMessage name="awardName" component="span" className="text-red-500 text-sm" />
              </div>

              <div>
                <label htmlFor="issuedBy" className="block mb-1">
                  {t('issuingBody', 'Issuing Body')}
                  <span className="text-red-500"> *</span>
                </label>
                <Field
                  disabled={!isEditable}
                  name="issuedBy"
                  placeholder={t('enterIssuingBody', 'Enter Issuing Body')}
                  className="w-full border rounded-lg text-sm p-2 h-11"
                />
                <ErrorMessage name="issuedBy" component="span" className="text-red-500 text-sm" />
              </div>
            </div>

            {/* Column for the last input */}
            <div className="w-1/2 mb-4">
              <label htmlFor="issuedDate" className="block mb-1">
                {t('yearOfAward', 'Year of Award')}
                <span className="text-red-500"> *</span>
              </label>
              <Field
                disabled={!isEditable}
                name="issuedDate"
                className="w-full border rounded-lg p-2 h-11"
                type="date"
                max={new Date().toISOString().split('T')[0]}
              ></Field>
              <ErrorMessage name="issuedDate" component="span" className="text-red-500 text-sm" />
            </div>

            {/* Divider */}
            {isEditable && <div className="divider my-2 !bg-[#E5E5E5]"></div>}

            <div className="flex justify-end mt-4">
              {isEditable && (
                <button
                  type="submit"
                  className="bg-primary text-black px-12 h-12 py-2 rounded-lg font-semibold"
                >
                  {initialValues ? t('update', 'Update') : t('save', 'Save')}
                </button>
              )}
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default AwardModal;
