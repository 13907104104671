import React, { useEffect, useState } from 'react';
import EditContactForm from './EditContactForm';
import { getUserProfile } from '../../../services/AccountServices';
import { toast } from 'react-toastify';
import { Profile } from '../../../types/interfaces';

const UserProfile: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [userProfile, setUserProfile] = useState<Profile | null>(null);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      const response = await getUserProfile();
      if (response?.isSuccess) {
        setUserProfile(response.data);
      } else {
        toast.error(response?.errorMessage);
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <div className="flex justify-center items-center">
      {!isEditing ? (
        <div className="flex flex-col gap-4 w-full max-w-2xl">
          <div className="bg-white rounded-lg shadow-sm py-3 px-4">
            <div className="flex gap-3">
              <div className="relative">
                <div className="rounded-full px-4 py-2 bg-gray-300 font-semibold text-3xl">
                  {userProfile?.organizationName.charAt(0).toUpperCase()}
                </div>
                <div className="absolute top-0 right-0 w-4 h-4 bg-green-500 rounded-full "></div>
              </div>
              <div>
                <div>
                  <p className=" text-xl font-medium">{userProfile?.organizationName}</p>
                </div>
                <div className="flex gap-2 items-center">
                  {/* <img src={`${process.env.PUBLIC_URL}/assets/images/usaFlag.svg`} alt="edit" /> */}
                  <p className="">{userProfile?.contactInfo.country}</p>
                  {userProfile?.supplierVerify != 'False' ? (
                    <p className="bg-green-500 px-3 py-1 text-white text-sm rounded-full">
                      Verified Supplier
                    </p>
                  ) : (
                    <p className="bg-gray-500 px-3 py-1 text-white text-sm rounded-full">
                      Unverified Supplier
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-[90px_1fr] gap-y-1 text-sm text-gray-500 mt-3 mb-6">
              <p className="">Username: </p>
              <p className="text-black">{userProfile?.userName} </p>
              <p className="">Email: </p>
              <p className="text-black">{userProfile?.email} </p>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-sm py-3 px-4 mb-6">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold">Contact Information</h2>

              {/* <button onClick={handleEditClick} className="flex items-center gap-1">
                <img src={`${process.env.PUBLIC_URL}/assets/icons/outline/edit.svg`} alt="edit" />
                <p className="text-green-500 text-sm font-medium">Edit</p>
              </button> */}
            </div>
            <div className="grid grid-cols-[130px_1fr] gap-y-2 text-sm text-gray-500 mt-3">
              <p>Name :</p>
              <p className="text-black">{userProfile?.contactInfo.fullName}</p>

              <p>Email :</p>
              <p className="text-black">{userProfile?.contactInfo.email}</p>

              <p>Phone number :</p>
              <p className="text-black">{userProfile?.phoneNumber}</p>

              <p>Contact address :</p>
              <p className="text-black">{userProfile?.contactInfo.address}</p>

              <p>Country :</p>
              <p className="text-black">{userProfile?.contactInfo.country}</p>

              <p>City :</p>
              <p className="text-black">{userProfile?.contactInfo.city}</p>

              <p>Socials :</p>
              <p className="text-black">{userProfile?.contactInfo.social}</p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-sm py-3 px-4 mb-6">
            <h2 className="text-lg font-semibold">Company Information</h2>
            <div className="grid grid-cols-[130px_1fr] gap-y-2 text-sm text-gray-500 mt-3">
              <p>Company Name :</p>
              <p className="text-black">{userProfile?.companyInfo.fullName}</p>

              <p>Phone number :</p>
              <p className="text-black">{userProfile?.phoneNumber}</p>

              <p>Contact address :</p>
              <p className="text-black">{userProfile?.companyInfo.address}</p>

              <p>Business Type :</p>
              <p className="text-black">{userProfile?.companyInfo.businessType}</p>

              <p>City :</p>
              <p className="text-black">{userProfile?.companyInfo.city}</p>

              <p>Socials :</p>
              <p className="text-black">{userProfile?.companyInfo.social}</p>
            </div>
          </div>
          <div>
            <p className="flex w-auto justify-end text-red-600 underline decoration-red-600 text-sm font-medium cursor-pointer">
              Deactivate Account
            </p>
          </div>
        </div>
      ) : (
        <EditContactForm onSave={handleSave} />
      )}
    </div>
  );
};

export default UserProfile;
