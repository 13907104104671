import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa';
import Chips from '../../../../components/Chips/Chips';
import { ChipBtn, IProduct, MyCards } from '../../../../types/interfaces';
import Cards from '../../../../components/CatalogueCards/Cards';
import FilterButton from '../../../../components/FilterButton/FilterButton';
import FilterForm from '../../../../components/FilterForm/FilterForm';
import DropdownMenu from '../../../../components/DropdownMenu';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { IPageRequest } from '../../../../components/CustomTable';
import { useNavigate } from 'react-router-dom';
import { getGoodsList } from '../../../../services/catalogService';
import { getTags } from '../../../../services/supplierService';
import { ProductType, SortEnum } from '../../../../types/enums';
import AppButton from '../../../../components/AppButton/AppButton';

interface AllServicesProps {
  selectedCategory: string | null;
}

const AllServices = forwardRef<{ fetchProducts: () => void }, AllServicesProps>(
  ({ selectedCategory }, ref) => {
    const [isFilterFormVisible, setIsFilterFormVisible] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({
      top: 0,
      left: 0,
    });
    const [tags, setTags] = useState<any[]>([]);
    const [pageSize, setPageSize] = useState(12);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [sortOrder, setSortOrder] = useState<SortEnum>(SortEnum.NEWEST);

    const { supplierId, setSupplierId } = useGlobalContext();
    const navigate = useNavigate();

    const pageRequest: IPageRequest = {
      pageNumber: 1,
      pageSize: pageSize,
    };

    const handleFilterClick = (): void => {
      setIsFilterFormVisible((prev) => !prev);
    };

    const handleSortClick = () => {
      setDropdownPosition({ top: 20, left: 20 });
      setIsDropdownOpen((prev) => !prev);
    };
    const [showFeature, setShowFeature] = useState<boolean>(true);
    const [myChips, setMyChips] = useState<ChipBtn[]>([
      { title: 'Maintenance', bgColor: 'bg-primary' },
      { title: 'Oil and Gas ', bgColor: 'bg-blue-400' },
      { title: 'Management', bgColor: 'bg-green-400' },
      { title: 'Equipments', bgColor: 'bg-red-400' },
    ]);

    const handleFilterClose = (values: any) => {
      setIsFilterFormVisible(false);
      fetchProducts(values);
    };

    const viewMoreProducts = () => {
      setPageSize((prevPageSize) => prevPageSize + 12);
      fetchProducts();
    };

    const fetchProducts = async (filteredValues?: any) => {
      // if (!supplierId) return setProducts([]);

      const fetchRequest = {
        supplierId,
        productType: ProductType.SERVICES,
        pageNumber: pageRequest.pageNumber,
        pageSize: pageRequest.pageSize,
        sortOrder,
        ...(selectedCategory && { categoryId: selectedCategory }),
        ...(filteredValues && { ...filteredValues }),
      };
      const response = await getGoodsList(fetchRequest);
      if (response) {
        setProducts(response.data);
      }
    };

    const fetchSupplierTags = async () => {
      if (!supplierId) return;
      const response = await getTags(supplierId);
      if (response?.isSuccess) {
        setTags(response.data);
      }
    };

    useEffect(() => {
      fetchProducts();
      fetchSupplierTags();
    }, []);

    useImperativeHandle(ref, () => ({
      fetchProducts,
    }));

    const onSort = (value: SortEnum) => {
      setSortOrder(value);
      fetchProducts();
    };

    const handleViewProduct = (productId: any) => {
      navigate(`/services/detail?Id=${productId}`, {
        state: {
          productType: ProductType.SERVICES,
        },
      });
    };

    const [shellCards, setShellCards] = useState<MyCards[]>([
      { imagePath: 'images/ShellPix.svg', title: 'Shell Official Store' },
      { imagePath: 'images/ShellPix.svg ', title: 'Shell Official Store' },
      { imagePath: 'images/ShellPix.svg', title: 'Shell Official Store' },
      { imagePath: 'images/ShellPix.svg', title: 'Shell Official Store' },
      { imagePath: 'images/ShellPix.svg', title: 'Shell Official Store' },
    ]);

    const sortOptions = [
      { label: 'Newest (Default)', action: () => onSort(SortEnum.NEWEST) },
      {
        label: 'Price: Low to High',
        action: () => onSort(SortEnum.PRICE_LOW_HIGH),
      },
      {
        label: 'Price: High to Low',
        action: () => onSort(SortEnum.PRICE_HIGH_LOW),
      },
      { label: 'Alphabet: A-Z', action: () => onSort(SortEnum.ASCENDING) },
      { label: 'Alphabet: Z-A', action: () => onSort(SortEnum.DESCENDING) },
    ];

    return (
      <>
        <div className="bg-white rounded-2xl p-8 border border-[#E5E5E5]">
          <div className="flex items-center justify-between mb-8">
            <h2 className="text-2xl font-semibold">All Services</h2>

            <div className="flex justify-between">
              <div className="relative">
                <FilterButton label="Sort by" onClick={handleSortClick} />
                {isDropdownOpen && (
                  <DropdownMenu
                    open={isDropdownOpen}
                    onClose={() => setIsDropdownOpen(false)}
                    items={sortOptions}
                  />
                )}
              </div>
              <div>
                <FilterButton label="Filters" onClick={handleFilterClick} />
              </div>
              <div
                className={`fixed top-0 right-0 h-full w-1/3 transition-transform duration-300 ${
                  isFilterFormVisible ? 'translate-x-0' : 'translate-x-full'
                }`}
              >
                <FilterForm onClose={handleFilterClose} />
              </div>
            </div>
          </div>
          <div className="flex gap-4 mb-8">
            {tags.map((tag, index) => (
              <Chips key={index} title={tag.tagName} bgColor={'bg-green-50'} />
            ))}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-10">
            {products.map((product, index) => (
              <Cards
                key={index}
                imagePath={
                  product?.productImages.length > 0
                    ? product?.productImages[0].sharepointPath
                    : 'images/compressor.svg'
                }
                showDefaultImage={product?.productImages.length <= 0}
                title={product.productName}
                ratings={product.ratings ?? '0'}
                ratingsCount={product.ratingsCount ?? '0'}
                location={product.location ?? 'USA'}
                price={product.price.toString()}
                showPrice={true}
                showRatings={true}
                showLocation={true}
                onClick={() => handleViewProduct(product.productId)}
              />
            ))}
          </div>
          {products.length > 12 && (
            <div className="flex w-full justify-center">
              <AppButton
                title={'See More'}
                onClick={viewMoreProducts}
                className="py-3 px-12 bg-primary rounded-xl font-semibold mb-10"
              />
            </div>
          )}
          <div className="flex w-full justify-between">
            <div className="text-2xl font-bold">
              <p>Recommended Suppliers for you</p>
            </div>
            <div className="flex text-gray-700 text-xl items-center underline decoration-green-700">
              <p className="mr-1">See All</p>
              <FaChevronRight size={8} />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-10 mt-10">
            {shellCards.map((shellCard, index) => (
              <div
                key={index}
                className="flex flex-col items-center bg-white border border-gray-300 rounded-lg"
              >
                <Cards
                  imagePath={shellCard.imagePath}
                  title={shellCard.title}
                  showDescription={false}
                  showPrice={false}
                  styleConfig={{
                    image: 'w-48 h-48 object-cover rounded-t-lg',
                    title: 'font-bold',
                  }}
                />
              </div>
            ))}
          </div>
          <div className="flex w-full justify-between bg-blue-300 p-2 mb-6">
            <div className="text-2xl font-bold">
              <p>Limited Deals</p>
            </div>
            <div className="flex text-gray-700 text-xl items-center underline decoration-green-700">
              <p className="mr-1">See All</p>
              <FaChevronRight size={8} />
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default AllServices;
