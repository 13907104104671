import React, { useEffect, useState } from 'react';
import Cards from '../../../components/CatalogueCards/Cards';
import { useNavigate } from 'react-router-dom';
import { ProductType } from '../../../types/enums';
import { IProduct } from '../../../types/interfaces';
import { IPageRequest } from '../../../components/CustomTable';
import { getGoodsList } from '../../../services/catalogService';

interface GoodsSearchProps {
  searchProps: {
    searchValue: string;
    productType: ProductType;
    startDate: string;
    endDate: string;
    categoryId: string;
    country: string;
  };
}

const GoodsSearch: React.FC<GoodsSearchProps> = ({ searchProps }) => {
  const [products, setProducts] = useState<IProduct[]>([]);

  const navigate = useNavigate();
  const pageRequest: IPageRequest = {
    pageNumber: 1,
    pageSize: 12,
  };

  const handleViewProduct = (productId: any) => {
    navigate(`/goods/detail?Id=${productId}`, {
      state: {
        productType: ProductType.GOODS,
      },
    });
  };

  const fetchProducts = async () => {
    const fetchRequest: any = {};
    if (searchProps.searchValue?.trim()) fetchRequest.searchValue = searchProps.searchValue;
    fetchRequest.productType = searchProps.productType;
    if (searchProps.startDate?.trim()) fetchRequest.startDate = searchProps.startDate;
    if (searchProps.endDate?.trim()) fetchRequest.endDate = searchProps.endDate;
    if (searchProps.categoryId?.trim()) fetchRequest.categoryId = searchProps.categoryId;
    if (searchProps.country?.trim()) fetchRequest.country = searchProps.country;
    const response = await getGoodsList(fetchRequest);
    if (response) {
      setProducts(response.data);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [searchProps]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-10">
      {products.map((product, index) => (
        <Cards
          key={index}
          imagePath={
            product?.productImages.length > 0
              ? product?.productImages[0].sharepointPath
              : 'images/compressor.svg'
          }
          showDefaultImage={product?.productImages.length <= 0}
          title={product.productName}
          description={product.description}
          currency={product.currency}
          price={product.price.toString()}
          showPrice={true}
          showDescription={true}
          onClick={() => handleViewProduct(product.productId)}
        />
      ))}
    </div>
  );
};

export default GoodsSearch;
