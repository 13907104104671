import React, { useMemo } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DocumentInterface } from '../../types/interfaces';
import { uploadDocument } from '../../services/edmsService';
import UploadInput from '../CustomUploadInput/CustomUploadInput';
import FileUpload from '../FileUpload/file-upload';

interface AddDocumentProps {
  onClose: () => void;
  onAdd: () => void;
  initialValues?: DocumentInterface | null;
  isEditable: boolean;
  entityId: string | null;
  module: string;
  subModule: string | null;
  documentTypeList: { key: string; value: string }[];
}

const validationSchema = Yup.object({
  documentType: Yup.string().required('Document Type is required'),
  documents: Yup.mixed().required('Document is required'),
  //documents: Yup.array().min(1, 'At least one document is required').required('Documents are required'),
});

const AddDocument: React.FC<AddDocumentProps> = ({
  onClose,
  onAdd,
  initialValues,
  isEditable,
  entityId,
  module,
  subModule,
  documentTypeList,
}) => {
  const { t } = useTranslation();

  const formInitialValues = useMemo<any>(() => {
    if (initialValues) {
      return {
        ...initialValues,
      };
    }
    return {
      documentType: '',
      documents: {},
    };
  }, [initialValues]);

  const handleSubmit = async (values: any) => {
    if (!entityId) {
      toast.error(t('entityIdRequired', 'Entity ID is required'));
      return;
    }
    try {
      const payload = {
        entityId,
        module,
        ...(subModule && { subModule }),
        documentTypeName: values.documentType,
        file: values.documents,
      };

      const formData = new FormData();
      formData.append('entityId', payload.entityId);
      formData.append('module', payload.module);
      if (payload.subModule) {
        formData.append('subModule', payload.subModule);
      }
      formData.append('documentTypeName', payload.documentTypeName);
      formData.append('file', payload.file);

      const response = await uploadDocument(formData);
      if (response?.isSuccess) {
        toast.success(response?.data?.message);
        onAdd();
      } else {
        toast.error(response?.errorMessage);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-10">
      <div className="bg-white rounded-2xl shadow-lg w-full max-w-3xl p-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{t('addDocument', 'Add Document')}</h2>
          <button className="text-gray-500 text-xl" onClick={onClose}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
              alt={t('close', 'close')}
            />
          </button>
        </div>

        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="grid grid-cols-2 gap-6">
                {/* Document Type */}
                <div className="mb-4 col-span-2">
                  <label
                    htmlFor="documentType"
                    className="block mb-1 text-sm font-medium text-[#344054]"
                  >
                    {t('documentType', 'Document Type')}
                    <span className="text-red-500">*</span>
                  </label>
                  <Field
                    as="select"
                    name="documentType"
                    disabled={!isEditable}
                    className="w-full border rounded-lg p-2 text-sm h-11"
                  >
                    <option value="">{t('select', 'Select')}</option>
                    {documentTypeList.map((type) => (
                      <option key={type.key} value={type.value}>
                        {t(type.value, type.value)}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="documentType"
                    component="span"
                    className="text-red-500 text-sm"
                  />
                </div>

                {/* Upload Documents */}
                <div className="mb-4 col-span-2">
                  <label className="block mb-1 text-sm font-medium text-[#344054]">
                    {t('uploadDocument', 'Upload Document')}
                    <span className="text-red-500">*</span>
                  </label>
                  <FileUpload
                    onFileSelect={(file: File | null) => setFieldValue('documents', file)}
                    showUpload={false}
                  />
                  <ErrorMessage
                    name="documents"
                    component="span"
                    className="text-red-500 text-sm"
                  />
                </div>
              </div>

              {isEditable && <div className="divider my-6 !bg-[#E5E5E5]"></div>}

              <div className="flex justify-end mt-4">
                {isEditable && (
                  <button
                    type="submit"
                    className="bg-primary text-black px-12 h-12 py-2 rounded-lg font-semibold"
                  >
                    {initialValues ? t('update', 'Update') : t('save', 'Save')}
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddDocument;
