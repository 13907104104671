import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../pages/Auth/Login/Login';
import PageNotFound from '../components/WildCard/PageNotFound';
import SignUp from '../pages/Auth/SignUp/SignUp';
import Otp from '../components/Otp/Otp';
import SuccessCard from '../components/SuccessCard/SuccessCard';
import ForgetPassword from '../pages/Auth/Forget-Password/ForgetPassword';
import PasswordReset from '../pages/Auth/Forget-Password/PassswordReset';
import ChooseDemo from '../pages/ChooseDemo/ChooseDemo';

import SupplierManagement from '../pages/SupplierManagement/SupplierManagement';
import SupplierApplication from '../pages/SupplierManagement/SupplierApplication/SupplierApplication';
import ProtectedRoute from '../guards/ProtectedRoute';
import WorkflowConfiguration from '../pages/Configurations/WorkFlowConfiguration/WorkflowList';
import CommandConfig from '../pages/Configurations/WorkFlowConfiguration/CommandConfig/CommandConfig';
import StepConfig from '../pages/Configurations/WorkFlowConfiguration/StepConfig/AddEditStepConfig';
import ProtectedLayout from '../layouts/ProtectedLayout';
import WorkFlowSteps from '../pages/Configurations/WorkFlowConfiguration/StepConfig/StepList';
import ApplicationsOnMyDesk from '../pages/ApplicationDesk/ApplicationDesk';
import UserManagement from '../pages/Configurations/UserManagement/UserManagement';
import Operator from '../pages/Configurations/UserManagement/Operator';
import CreateProcurementForm from '../pages/ProcurementProject/CreateProcurementForm';
import ParameterConfig from '../pages/Configurations/WorkFlowConfiguration/ParameterConfig/ParameterConfig';
import RolePermission from '../pages/Configurations/RoleManagement/RolePermission';
import NavigationMenu from '../pages/Configurations/NavigationMenu/NavigationMenu';
import SupplierRequests from '../pages/SupplierRequests/SupplierRequests';
import AccessibleModules from '../pages/AccessibleModules/ApplicationModules';
import OperatorModule from '../pages/OperatorModule/OperatorModule';
import OperatorDashboard from '../pages/OperatorModule/OperatorDashboard';
import Dashboard from '../pages/Dashboard/Dashboard';
import GoodsServiceCatalogue from '../pages/GoodsServiceCatalogue/GoodsServiceCatalogue';
import ProductDetails from '../pages/GoodsServiceCatalogue/components/ProductDetails';
import CartPage from '../pages/GoodsServiceCatalogue/components/CartPage';
import SearchResults from '../pages/GoodsServiceCatalogue/SearchResults/SearchResults';
import Profile from '../pages/Configurations/Profile/Profile';
import OrderDetailsPage from '../pages/GoodsServiceCatalogue/components/OrdersPage/OrderDetailsPage';
import ProcurementRequests from '../pages/ProcurementProject/ProcurementRequests';
import SupplierRequestsView from '../pages/ProcurementProject/SupplierRequestsView';
import ExpressionOfInterestBid from '../pages/ProcurementProject/ExpressionOfInterestBid';
import ProcurementDashboard from '../pages/ProcurementProject/ProcurementDashboard';
import LookupComponent from '../pages/Configurations/Lookup/Lookup';
import { NotificationPage } from '../pages/Notification';
import ProcurementHomepage from '../pages/ProcurementProject/ProjectProcurementHomepage';
import ProcurementTenderPage from '../pages/ProcurementTender/ProcurementTenderPage';
import ExpressionOfInterestPage from '../pages/ProcurementTender/ExpressionOfInterestPage';
import ProcurementProposalPage from '../pages/ProcurementProposal/ProcurementProposalPage';
import Settings from '../pages/Settings/Settings';

const RouterConfig: React.FC = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/success-card" element={<SuccessCard title="Success" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/otp" element={<Otp />} />
      <Route path="reset-password" element={<PasswordReset />} />
      <Route path="/forgot-password" element={<ForgetPassword />} />
      <Route path="/choose-demo" element={<ChooseDemo />} />
      <Route path="/application-module" element={<AccessibleModules />} />
      <Route path="/catalogue/search" element={<SearchResults />} />

      {/* Protected Routes with Shared Layout */}
      <Route
        element={
          <ProtectedRoute>
            <ProtectedLayout />
          </ProtectedRoute>
        }
      >
        <Route element={<ProtectedLayout />}></Route>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/supplier-registration" element={<SupplierManagement />} />
        <Route path="/operator-module" element={<OperatorModule />} />
        <Route path="/supplier-application" element={<SupplierApplication />} />
        <Route path="/configuration/workflow" element={<WorkflowConfiguration />} />
        <Route path="/configuration/workflow/parameter" element={<ParameterConfig />} />
        <Route path="/catalogue" element={<GoodsServiceCatalogue />} />
        <Route path="/configuration/workflow/command" element={<CommandConfig />} />
        <Route path="/configuration/workflow/steps" element={<WorkFlowSteps />} />
        <Route path="/configuration/workflow/step" element={<StepConfig />} />
        <Route path="/settings/supplier-profile" element={<Profile />} />
        <Route path="/catalogue/orderdetailspage" element={<OrderDetailsPage />} />

        <Route path="/requests/supplier-requests" element={<SupplierRequests />} />
        <Route path="/operator-dashboard" element={<OperatorDashboard />} />
        <Route path="/application-desk" element={<ApplicationsOnMyDesk />} />
        <Route path="/configuration/roles" element={<RolePermission />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/configuration/menu" element={<NavigationMenu />} />
        <Route path="/configuration/lookup" element={<LookupComponent />} />
        <Route path="/configuration/users" element={<UserManagement isEditable={false} />} />
        <Route path="/goods/detail" element={<ProductDetails />} />
        <Route path="/services/detail" element={<ProductDetails />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/operator" element={<Operator isEditable={false} />} />
        <Route path="/procurement" element={<ProcurementHomepage />} />
        <Route path="/procurement/:id" element={<ProcurementTenderPage />} />
        <Route path="/procurement/proposal/:id" element={<ProcurementProposalPage />} />
        <Route
          path="/procurement/:id/expressionOfInterest"
          element={<ExpressionOfInterestPage />}
        />
        <Route path="/appdesk" element={<ApplicationsOnMyDesk />} />
        <Route path="/procurement/create" element={<CreateProcurementForm />} />
        <Route path="/procurement/edit/:id" element={<CreateProcurementForm />} />
        <Route path="/requests/procurement-requests" element={<ProcurementRequests />} />
        {/* <Route path="/supplierProcurements" element={<ProcurementsForSupplier />} /> */}
        <Route
          path="/procurement/supplierRequests/:procurementId"
          element={<SupplierRequestsView onClose={() => {}} />}
        />
        <Route path="/supplierProcurements/bid" element={<ExpressionOfInterestBid />} />
        <Route
          path="/supplierProcurement/procurement-dashboard"
          element={<ProcurementDashboard />}
        />
        <Route path="/notification" element={<NotificationPage />} />
        {/* Wildcard Route */}
        <Route path="*" element={<PageNotFound />} />
      </Route>

      {/* Wildcard Route */}
      {/* <Route path="*" element={<PageNotFound />} /> */}
    </Routes>
  );
};

export default RouterConfig;
