import axiosInstance from '../axiosInstance';
import { objToQueryParams } from '../utils/helper';
import { Endpoint } from './endpoint/endpoint';

interface PageRequest {
  pageNumber: number;
  pageSize: number;
}

export const getGoodsList = async (queryParams: any) => {
  const params = objToQueryParams(queryParams);
  const url = Endpoint.goods.list + params;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getProductById = async (productId: string) => {
  const url = Endpoint.goods.getProduct(productId);
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const createGoods = async (data: any) => {
  const url = Endpoint.goods.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const updateGoods = async (data: any) => {
  const url = Endpoint.goods.update;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const setGoodsStatus = async (data: any) => {
  const url = Endpoint.goods.setStatus;
  try {
    const response = await axiosInstance.patch(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const deleteGoods = async (productId: string) => {
  const url = Endpoint.goods.delete;
  const body = { productId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const createCategory = async (data: any) => {
  const url = Endpoint.goods.category.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const updateCategory = async (data: any) => {
  const url = Endpoint.goods.category.update;
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const deleteCategory = async (categoryId: string) => {
  const url = Endpoint.goods.category.delete;
  const body = { categoryId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getAllCategories = async (queryParams: any) => {
  const params = objToQueryParams(queryParams);
  const url = Endpoint.goods.category.getAll + params;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const addToCart = async (data: any) => {
  const url = Endpoint.goods.cart.add;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getCartList = async (customerId: string, pageRequest: PageRequest) => {
  const url = Endpoint.goods.cart.list(customerId, pageRequest.pageNumber, pageRequest.pageSize);
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const removeFromCart = async (itemId: string) => {
  const url = `${Endpoint.goods.cart.remove}`;
  const body = { itemId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const createReview = async (data: any) => {
  const url = Endpoint.goods.review.create;
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const updateReview = async (data: any) => {
  const url = Endpoint.goods.review.update;
  try {
    const response = await axiosInstance.put(url, data);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const deleteReview = async (reviewId: string) => {
  const url = Endpoint.goods.review.delete;
  const body = { reviewId };
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getReviews = async (productId: string) => {
  const url = Endpoint.goods.review.get(productId);
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getSupplierReviews = async (supplierId: string) => {
  const url = Endpoint.goods.review.getSupplierReviews(supplierId);
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

export const getSupplierRating = async (supplierId: string) => {
  const url = Endpoint.goods.review.getSupplierRating(supplierId);
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};
