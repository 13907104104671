import { useState } from 'react';

interface Notification {
  id: number;
  title: string;
  description: string;
  timestamp: string;
  status: string;
  day: string;
  read: boolean;
}

const notifications: Notification[] = [
  {
    id: 1,
    title: 'Your renewal request has been rejected',
    description:
      'The status of pipeline inspection and maintenance has been updated to In-progress. Review the changes to stay on track with the project milestones.',
    timestamp: '12:05 PM',
    status: 'rejected',
    day: 'Today',
    read: false,
  },
  {
    id: 2,
    title: 'Congratulations. You’ve been invited to bid for pipeline inspection and maintenance',
    description:
      'The status of pipeline inspection and maintenance has been updated to In-progress. Review the changes to stay on track with the project milestones.',
    timestamp: '12:05 PM',
    status: 'invitation',
    day: 'Today',
    read: false,
  },
  {
    id: 3,
    title: 'Your renewal request has been accepted',
    description:
      'The status of pipeline inspection and maintenance has been updated to In-progress. Review the changes to stay on track with the project milestones.',
    timestamp: '2:05 PM',
    status: 'accepted',
    day: 'Yesterday',
    read: true,
  },
  {
    id: 4,
    title: 'Your request to view has been approved',
    description:
      'The status of pipeline inspection and maintenance has been updated to In-progress. Review the changes to stay on track with the project milestones.',
    timestamp: '12:05 PM',
    status: 'approved',
    day: 'Yesterday',
    read: false,
  },
  // Add more sample data here
];

export const NotificationPage = () => {
  const [activeTab, setActiveTab] = useState<'All' | 'Unread'>('All');
  const [data, setData] = useState(notifications);

  // Filter notifications based on the active tab
  const filteredNotifications = activeTab === 'Unread' ? data.filter((n) => !n.read) : data;

  // Mark all notifications as read
  const markAllAsRead = () => {
    const updatedNotifications = data.map((n) => ({ ...n, read: true }));
    setData(updatedNotifications);
  };

  return (
    <div className="max-w-[1440px] mx-6 md:mx-auto min-h-screen">
      <div className="pt-8 pb-36">
        <h2 className="text-2xl text-[#101828] font-semibold">Notifications</h2>
        <p className="text-base text-[#525252]">
          Stay informed about important updates and insights tailored to your projects and
          interests.
        </p>

        {/* Tabs Section */}
        <div className="flex items-center gap-x-8 border-b border-[#F5F5F5] mt-5">
          <button
            onClick={() => setActiveTab('All')}
            className={`pb-4 px-1 text-sm ${
              activeTab === 'All'
                ? 'text-[#101828] font-semibold border-b-2 border-[#FFC726]'
                : 'text-[#525252] border-b-2 border-transparent'
            }`}
          >
            All
          </button>
          <button
            onClick={() => setActiveTab('Unread')}
            className={`pb-4 px-1 text-sm ${
              activeTab === 'Unread'
                ? 'text-[#101828] font-semibold border-b-2 border-[#FFC726]'
                : 'text-[#525252] border-b-2 border-transparent'
            }`}
          >
            Unread ({data.filter((n) => !n.read).length})
          </button>
          <button
            onClick={markAllAsRead}
            className="pb-4 px-1 text-base text-[#525252] hover:text-black border-b-2 border-transparent cursor-pointer flex items-center gap-x-2"
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/quill_checkmark-double.svg`}
              alt="notification bell icon"
              className=""
            />
            <span>Mark all as read</span>
          </button>
        </div>

        {/* Notification List */}
        <div className="bg-white border border-[#E5E5E5] px-4 pb-[107px] mt-7 rounded-2xl min-h-[70vh] ">
          {filteredNotifications.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-20 min-h-[60vh]">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/notifications-empty-state.svg`}
                alt="empty state icon"
                className="mb-4 w-[118.9px] h-[104.18px] object-contain"
              />
              <h3 className="text-2xl text-[#161616] font-semibold">
                {activeTab === 'All' ? 'No Notifications' : 'No Unread Notifications'}
              </h3>
              <p className="text-sm text-[#7F7F7F] mt-2.5">
                {activeTab === 'All'
                  ? 'You do not have any notifications yet.'
                  : 'You do not have any unread notifications.'}
              </p>
            </div>
          ) : (
            Object.keys(
              filteredNotifications.reduce(
                (groups, notification) => {
                  const { day } = notification;
                  if (!groups[day]) groups[day] = [];
                  groups[day].push(notification);
                  return groups;
                },
                {} as Record<string, (typeof notifications)[number][]>
              )
            ).map((day) => (
              <div key={day}>
                <h3 className="text-sm text-[#8D8D8D] mb-3 mt-6">{day}</h3>
                {filteredNotifications
                  .filter((notification) => notification.day === day)
                  .map((notification) => (
                    <div
                      key={notification.id}
                      className="pt-6 pb-4 border-b border-[#F5F5F5] xl:flex xl:justify-between"
                    >
                      <div className="flex items-start gap-x-6 max-w-[715px]">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/notification-bell.svg`}
                          alt="notification bell icon"
                          className=""
                        />
                        <div>
                          <div className="flex items-center gap-x-2">
                            <h4
                              className={`text-sm font-semibold ${
                                notification.read ? 'text-[#8D8D8D]' : 'text-[#161616]'
                              }`}
                            >
                              {notification.title}
                            </h4>
                            {!notification.read ? (
                              <span className="block bg-[#DA1E28] w-2 h-2 rounded-full" />
                            ) : null}
                          </div>

                          <p className="text-sm text-[#525252] mt-2">{notification.description}</p>
                        </div>
                      </div>

                      <div className="flex justify-end items-center gap-x-2 mt-4 xl:mt-[unset]  xl:mr-[22px]">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/clock.svg`}
                          alt="clock icon"
                          className="w-4 h-4"
                        />
                        <span className=" text-sm text-[#8D8D8D]">{notification.timestamp}</span>
                      </div>
                    </div>
                  ))}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
