import React, { useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { ShareholderInterface } from './../Shareholder/ShareHolders';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { createShareholder, updateShareholder } from '../../../../services/supplierService';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface ModalShareholderFormProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: ShareholderInterface | null;
  isEditable: boolean;
}

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .required('Full name is required')
    .matches(/^[A-Za-z-\s]+$/, 'Only alphabets and hyphens are allowed'),
  // maritalStatus: Yup.string().required('Marital status is required'),
  tin: Yup.string().required('TIN is required'),
  identificationNumber: Yup.string().required('Identification number is required'),
  identificationType: Yup.string().required('Identification type is required'),
  percentageOwned: Yup.number()
    .required('Percentage owned is required')
    .min(0, 'Percentage must be at least 0')
    .max(100, 'Percentage cannot exceed 100'),
});

export const AddShareholderModal: React.FC<ModalShareholderFormProps> = ({
  onClose,
  initialValues,
  isEditable,
}) => {
  const { supplierId } = useGlobalContext();
  const { t } = useTranslation();

  const formInitialValues = useMemo<ShareholderInterface>(() => {
    if (initialValues) {
      return {
        ...initialValues,
      };
    }
    return {
      shareholderId: '',
      fullName: '',
      maritalStatus: '',
      tin: '',
      identificationNumber: '',
      identificationType: '',
      percentageOwned: 0,
    };
  }, [initialValues]);

  const handleSubmit = async (values: ShareholderInterface) => {
    try {
      const payload = {
        ...values,
        companyId: supplierId,
      };

      if (initialValues) {
        payload.shareholderId = initialValues.shareholderId;
        const response = await updateShareholder(payload);
        if (response.isSuccess) {
          toast.success(t('shareholderUpdatedSuccessfully', 'Shareholder updated successfully'));
          onClose();
        }
      } else {
        const { shareholderId, ...payloadWithoutShareholderId } = payload;
        const response = await createShareholder({
          ...payloadWithoutShareholderId,
        });
        if (response.isSuccess) {
          toast.success(t('shareholderCreatedSuccessfully', 'Shareholder created successfully'));
          onClose();
        }
      }
    } catch (error) {
      toast.error(t('failedToCreateShareholder', 'Failed to create shareholder'));
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white w-full max-w-4xl p-8 rounded-2xl relative">
        <button onClick={onClose} className="absolute top-3 right-3 text-gray-500 text-3xl">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
            alt={t('close', 'close')}
          />
        </button>
        <h2 className="text-lg font-bold mb-4">
          {t('addShareholderDetail', "Add Shareholder's Detail")}
        </h2>

        <div className="divider my-6 !bg-[#E5E5E5]"></div>

        <Formik
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="grid grid-cols-3 gap-x-4 gap-y-6 mb-4">
                <div className="col-span-3">
                  <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
                    {t('shareholder_Name', "Shareholder's Name")}
                    <span className="text-red-500">*</span>
                  </label>
                  <Field
                    disabled={!isEditable}
                    name="fullName"
                    placeholder={t('enterShareholderName', "Enter Shareholder's name")}
                    className="w-full border border-gray-300 h-[3rem] text-sm rounded-md focus:outline-none focus:border-gray-700"
                  />
                  {errors.fullName && touched.fullName ? (
                    <div className="text-red-500 text-sm">{errors.fullName}</div>
                  ) : null}
                </div>
                {/* <div>
                  <label
                    htmlFor="maritalStatus"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t('maritalStatus', 'Marital Status')}
                    <span className="text-red-500">*</span>
                  </label>
                  <Field
                    as="select"
                    disabled={!isEditable}
                    name="maritalStatus"
                    className="w-full border border-gray-300 h-[3rem] text-sm rounded-md focus:outline-none focus:border-gray-700"
                  >
                    <option value="">{t('select', 'Select')}</option>
                    <option value="single">{t('single', 'Single')}</option>
                    <option value="married">{t('married', 'Married')}</option>
                  </Field>
                  {errors.maritalStatus && touched.maritalStatus ? (
                    <div className="text-red-500 text-sm">{errors.maritalStatus}</div>
                  ) : null}
                </div> */}
                <div>
                  <label htmlFor="tin" className="block text-sm font-medium text-gray-700">
                    {t('TIN', 'Individual TIN')}
                    <span className="text-red-500">*</span>
                  </label>
                  <Field
                    disabled={!isEditable}
                    name="tin"
                    placeholder={t('enterNumber', 'Enter Number')}
                    className="w-full border border-gray-300 h-[3rem] text-sm rounded-md focus:outline-none focus:border-gray-700"
                  />
                  {errors.tin && touched.tin ? (
                    <div className="text-red-500 text-sm">{errors.tin}</div>
                  ) : null}
                </div>
                <div>
                  <label
                    htmlFor="identificationType"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t('IdType', 'Type of ID')}
                    <span className="text-red-500">*</span>
                  </label>
                  <Field
                    as="select"
                    disabled={!isEditable}
                    name="identificationType"
                    className="w-full border border-gray-300 h-[3rem] text-sm rounded-md focus:outline-none focus:border-gray-700"
                  >
                    <option value="">{t('select', 'Select')}</option>
                    <option value="drivers_license">
                      {t('driversLicense', "Driver's License")}
                    </option>
                    <option value="passport">{t('passport', 'Passport')}</option>
                  </Field>
                  {errors.identificationType && touched.identificationType ? (
                    <div className="text-red-500 text-sm">{errors.identificationType}</div>
                  ) : null}
                </div>
                <div>
                  <label
                    htmlFor="identificationNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t('identificationNum', 'Identification Number')}
                    <span className="text-red-500">*</span>
                  </label>
                  <Field
                    disabled={!isEditable}
                    name="identificationNumber"
                    placeholder={t('enterIdentificationNumber', 'Enter Identification Number')}
                    className="w-full border border-gray-300 h-[3rem] text-sm rounded-md focus:outline-none focus:border-gray-700"
                  />
                  {errors.identificationNumber && touched.identificationNumber ? (
                    <div className="text-red-500 text-sm">{errors.identificationNumber}</div>
                  ) : null}
                </div>
                <div>
                  <label
                    htmlFor="percentageOwned"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t('percentageQuotaHeld', 'Shares Quota/Percentage Held')}
                    <span className="text-red-500">*</span>
                  </label>
                  <Field
                    disabled={!isEditable}
                    name="percentageOwned"
                    placeholder="%"
                    className="w-full border border-gray-300 h-[3rem] text-sm rounded-md focus:outline-none focus:border-gray-700"
                  />
                  {errors.percentageOwned && touched.percentageOwned ? (
                    <div className="text-red-500 text-sm">{errors.percentageOwned}</div>
                  ) : null}
                </div>
              </div>

              {isEditable && <div className="divider my-8 !bg-[#E5E5E5]"></div>}

              <div className="flex justify-end">
                {isEditable && (
                  <button
                    type="submit"
                    className="bg-primary text-black px-12 h-12 py-2 rounded-lg font-semibold"
                    disabled={!isEditable}
                  >
                    {initialValues ? t('update', 'Update') : t('save', 'Save')}
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
