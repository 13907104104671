import { useTranslation } from 'react-i18next';

interface ProgressBarProps {
  percentage: number;
}

const ProgressBar = ({ percentage }: ProgressBarProps) => {
  return (
    <div className="w-full bg-[#F2F4F7] rounded-full h-2 mb-4">
      <div className="bg-[#24A148] h-2 rounded-full" style={{ width: `${percentage}%` }}></div>
    </div>
  );
};

const ApplicationProgress: React.FC<ProgressBarProps> = ({ percentage }) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-2">
      <ProgressBar percentage={percentage} />
      <span>{t('percentage', { percentage })}</span>
    </div>
  );
};

export default ApplicationProgress;
