import React, { useEffect, useState } from 'react';
import StarRatings from '../../../components/StarRatings/StarRatings';
import AppButton from '../../../components/AppButton/AppButton';
import { toast } from 'react-toastify';
import { ProductsProps } from '../../../types/interfaces';
import { getSupplierRating, getSupplierReviews } from '../../../services/catalogService';

const Reviews: React.FC<ProductsProps> = ({ companyId }) => {
  const [reviews, setReviews] = useState<any[]>([]);
  const [ratings, setRatings] = useState<any>(null);
  const [pageSize, setPageSize] = useState(12);
  const detailedRatings = [
    { category: 'Accurate description', stars: 4 },
    { category: 'Product quality', stars: 3 },
    { category: 'Transparency', stars: 5 },
    { category: 'Transaction smoothness', stars: 5 },
    { category: 'Communication', stars: 4 },
    { category: 'Professionalism', stars: 4 },
  ];

  // const reviews = [
  //   {
  //     username: 'Cameron Williamson',
  //     date: 'Today',
  //     comment: 'Nice seller, good deal, fast shipping and perfect packaging...',
  //     avatar: '/images/reviewProfile.svg',
  //   },
  //   {
  //     username: 'Ralph Edwards',
  //     date: '30 Sept, 2024',
  //     comment: 'The quality of the product was not as described...',
  //     avatar: '/images/reviewProfile.svg',
  //   },
  //   {
  //     username: 'Cameron Williamson',
  //     date: 'Today',
  //     comment: 'Nice seller, good deal, fast shipping and perfect packaging...',
  //     avatar: '/images/reviewProfile.svg',
  //   },
  //   {
  //     username: 'Ralph Edwards',
  //     date: '30 Sept, 2024',
  //     comment: 'The quality of the product was not as described...',
  //     avatar: '/images/reviewProfile.svg',
  //   },
  //   {
  //     username: 'Cameron Williamson',
  //     date: 'Today',
  //     comment: 'Nice seller, good deal, fast shipping and perfect packaging...',
  //     avatar: '/images/reviewProfile.svg',
  //   },
  //   {
  //     username: 'Ralph Edwards',
  //     date: '30 Sept, 2024',
  //     comment: 'The quality of the product was not as described...',
  //     avatar: '/images/reviewProfile.svg',
  //   },
  // ];

  useEffect(() => {
    // Fetch data
    const fetchReviews = async () => {
      if (!companyId) return;
      const response = await getSupplierReviews(companyId);
      if (response?.isSuccess) {
        setReviews(response.data);
      } else {
        toast.error(response?.errorMessage);
      }
    };

    const fetchDetailedRatings = async () => {
      if (!companyId) return;
      const response = await getSupplierRating(companyId);
      if (response?.isSuccess) {
        setRatings(response.data);
      } else {
        toast.error(response?.errorMessage);
      }
    };

    fetchReviews();
    fetchDetailedRatings();
  }, []);

  return (
    <>
      <div className="border border-gray-300 bg-white shadow-sm rounded-lg px-6 py-4">
        <div>
          <h2 className="text-2xl font-medium mb-2">Reviews and Ratings</h2>
        </div>
        <hr />
        <div className="flex">
          <div className="w-[45%]">
            <div className="mt-6">
              <span className="text-8xl font-semibold">{ratings?.value ?? '2.0'}</span>
              <span className="text-xl">/5</span>
            </div>
            <div className="mt-4">
              <h3 className="text-xl font-semibold text-gray-700 underline">
                Detailed Profile Ratings
              </h3>
              <div className="mt-2 space-y-2">
                {detailedRatings.map((rating, index) => (
                  <div key={index} className="flex items-center">
                    <span className="text-lg text-gray-600 w-1/2">{rating.category}</span>
                    <div className="flex items-center">
                      <StarRatings stars={rating.stars} />
                      <span className="ml-2 text-lg text-gray-600">{rating.stars} Stars</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-[45%]">
            {/* Profile Reviews */}
            <div className="mt-6">
              <h3 className="text-lg font-semibold text-gray-700 underline underline-offset-2">
                Profile Reviews {reviews.length}
              </h3>
              <div className="mt-4 space-y-4">
                {reviews.map((review, index) => (
                  <div key={index} className="  space-x-4">
                    <div className="flex items-center gap-4">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/${review.avatar}`}
                        alt={review.username}
                        className=" rounded-full object-cover"
                      />
                      <p className="font-medium text-gray-900">{review.username}</p>
                      <p className="text-gray-500 text-sm">{review.date}</p>
                    </div>
                    <div>
                      <p className="text-gray-700 mt-1 text-lg">{review.comment}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <AppButton
            title={'View More'}
            onClick={() => toast.info('View More')}
            className="border border-gray-400 py-3 px-10 rounded-xl font-semibold"
          />
        </div>
      </div>
    </>
  );
};

export default Reviews;
