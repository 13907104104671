import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleAuth } from '../services/AccountServices';
import { useGlobalContext } from '../contexts/GlobalContext';
import { toast } from 'react-toastify';

interface GoogleAuthResponse {
  clientId: string;
  credential: string;
  select_by: string;
}
const GoogleLoginButton = ({
  title = 'signin',
}: {
  title?: 'signin' | 'signin_with' | 'signup_with' | 'continue_with';
}) => {
  const { login } = useGlobalContext();
  const handleSuccess = async (response: GoogleAuthResponse) => {
    // Send Google token to backend for verification
    const token = response.credential;

    try {
      const response = await GoogleAuth({ token });

      if (response) {
        localStorage.setItem('userId', response.userId);
        localStorage.setItem('refreshToken', response.refreshToken);
        localStorage.setItem('accessToken', response.accessToken);
        localStorage.setItem('userName', response.userName);
        //login(); // Call context login to set the state and update localStorage
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  const handleFailure = () => {
    // console.error('Login Failed:', error);
  };

  return (
    <GoogleLogin
      onSuccess={() => handleSuccess}
      onError={() => handleFailure}
      text={title}
      useOneTap // Optional: adds Google One Tap sign-in
    />
  );
};

export default GoogleLoginButton;
