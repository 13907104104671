import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import {
  getStaff,
  getPermissions,
  updateStaff,
  createStaff,
  getRoles,
  getOneStaff,
} from '../../../services/AccountServices';
import { ClipLoader } from 'react-spinners';
import { t } from 'i18next';

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  employeeId: Yup.string().required('Staff member is required'),
  roleId: Yup.string().required('Role is required'),
  // department: Yup.string().required('Department is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
});

interface RoleItem {
  role: string;
  roleId: string;
}

interface UserManagementModalProps {
  onSubmit: () => void;
  onClose: () => void;
  staff?: any;
}

const UserManagementModal: React.FC<UserManagementModalProps> = ({ onSubmit, onClose, staff }) => {
  const [staffOptions, setStaffOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [rolesOptions, setRolesOptions] = useState<any[]>([]);

  const [initialValues, setInitialValues] = useState({
    employeeId: '',
    roleId: '',
    department: '',
    phoneNumber: '',
    email: '',
  });
  // const fetchRolesData = async () => {

  //   try {
  //     const response = await getRoles(pageRequest);
  //     if (response) {
  //       setRoles(response);
  //     }
  //   } catch (error) {
  //     // console.error('Error fetching roles:', error);
  //   }
  // };

  const fetchData = async () => {
    try {
      const pageRequest = { pageNumber: 1, pageSize: 500 };
      const [staffResponse, roleResponse] = await Promise.all([getStaff(), getRoles(pageRequest)]);
      if (staffResponse?.data) {
        const staffOptions = staffResponse.data.map((staff: any) => ({
          value: staff.id,
          label: staff.displayName,
          email: staff.mail,
          department: staff.department,
        }));
        setStaffOptions(staffOptions);
      }
      if (roleResponse) {
        const roleOptions = roleResponse.map((item: RoleItem) => ({
          label: item.role,
          value: item.roleId,
        }));
        // console.log(roleOptions);
        setRolesOptions(roleOptions);
      }

      if (staff) {
        const response = await getOneStaff(staff.employeeId);
        if (!response.isSuccess) {
          toast.error('Role not found');
          return;
        }
        const selectedStaff = response.data;
        setInitialValues({
          employeeId: selectedStaff.employeeId || '',
          roleId: selectedStaff.roleId || '',
          department: selectedStaff.department || '',
          phoneNumber: selectedStaff.phoneNumber || '',
          email: selectedStaff.email || '',
        });
      }
      setLoading(false);
    } catch (error) {
      toast.error('Error fetching staff.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [staff, loading]);

  if (loading) {
    return (
      <div>
        <div className="spinner-container">
          <ClipLoader size={50} color={'#123abc'} loading={loading} />
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="shadow-lg bg-white w-full max-w-5xl gap-[20px] rounded-3xl p-8  relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{staff ? 'Update Staff' : 'Add New Staff'}</h2>
          <button className="text-red-500 text-xl" onClick={onClose}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/close.svg`} alt="close" />
          </button>
        </div>

        <div className="divider my-6 !bg-[#E5E5E5]"></div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              if (staff) {
                const response = await updateStaff(values);
                if (response?.isSuccess) {
                  toast.success(response?.data?.message);
                }
              } else {
                const response = await createStaff(values);
                if (response?.isSuccess) {
                  toast.success(response?.data?.message);
                }
              }
              onSubmit();
            } catch (error) {
              toast.error('Error updating staff');
            }
          }}
          enableReinitialize
        >
          {({ setFieldValue, values }) => (
            <Form className="space-y-4">
              <div className="flex flex-col gap-10">
                {/* Single-select Staff Name */}
                <div>
                  <label htmlFor="employeeId" className="block mb-1">
                    Staff Name<span className="text-red-500"> *</span>
                  </label>
                  <Select
                    options={staffOptions}
                    value={staffOptions.find((option) => option.value === values.employeeId)}
                    onChange={(selected) => {
                      setFieldValue('employeeId', selected?.value);
                      setFieldValue('email', selected?.email || '');
                      setFieldValue('department', selected?.department || '');
                    }}
                    placeholder="Select Staff"
                  />
                  <ErrorMessage
                    name="employeeId"
                    component="div"
                    className="text-red-500 text-sm"
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  {/* Role Dropdown based on Permissions */}
                  <div>
                    <label htmlFor="role" className="block mb-1">
                      Role<span className="text-red-500"> *</span>
                    </label>
                    <Select
                      options={rolesOptions}
                      value={rolesOptions.find((option) => option.value === values.roleId) || {}}
                      onChange={(option) => setFieldValue('roleId', option?.value)}
                      placeholder="Select Role"
                    />
                    <ErrorMessage name="roleId" component="div" className="text-red-500 text-sm" />
                  </div>

                  {/* Department (prefilled) */}
                  <div>
                    <label htmlFor="department" className="block mb-1">
                      Department<span className="text-red-500"> *</span>
                    </label>
                    <Field
                      name="department"
                      as="input"
                      className="w-full border h-14 text-sm rounded-lg p-4"
                      readOnly
                      disabled={true}
                    />
                    <ErrorMessage
                      name="department"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  {/* Phone Number with Country Code */}
                  <div>
                    <label htmlFor="phoneNumber" className="block mb-1">
                      Phone Number<span className="text-red-500"> *</span>
                    </label>
                    <PhoneInput
                      country={'us'}
                      value={values.phoneNumber}
                      onChange={(phone) => setFieldValue('phoneNumber', phone)}
                      inputClass="!w-full border rounded-lg h-11"
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </div>

                  {/* Email (prefilled) */}
                  <div>
                    <label htmlFor="email" className="block mb-1">
                      Email<span className="text-red-500"> *</span>
                    </label>
                    <Field
                      name="email"
                      type="email"
                      placeholder="name@email.com"
                      className="w-full h-14 text-sm border rounded-lg p-2"
                      readOnly
                      disabled={true}
                    />
                    <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
                  </div>
                </div>

                <div className="divider my-6 !bg-[#E5E5E5]"></div>

                {/* Submit Button */}
                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="bg-[#FFC726] text-black my-[30px] px-8 py-4 rounded-lg font-semibold"
                  >
                    {staff ? 'Update Staff' : 'Add New Staff'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UserManagementModal;
