import React, { SelectHTMLAttributes } from 'react';
import { useField } from 'formik';

type FormikSelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  label: string;
  name: string;
  options: { value: string; label: string }[];
  isRequired?: boolean;
};

const FormikSelect: React.FC<FormikSelectProps> = ({ label, isRequired, options, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="w-full mb-5">
      {label ? (
        <label
          htmlFor={props.id || props.name}
          className="block text-sm font-medium text-[#344054] mb-1.5"
        >
          {label}
          {isRequired && <span className="text-red-500 ml-1">*</span>}
        </label>
      ) : null}

      <div className="relative">
        <select
          {...field}
          {...props}
          className={`
              w-full px-3.5 py-2.5 border rounded-md shadow-sm appearance-none
              bg-white 
              ${meta.touched && meta.error ? 'border-red-500' : 'border-[#D0D5DD]'}
              ${props.className || ''}
            `}
        >
          <option value="">Select</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/outline/chevron-down.svg`}
          alt="chevron-down"
          className="absolute right-[10px] top-[14px]"
        />
      </div>

      {meta.touched && meta.error ? (
        <div className="mt-1 text-sm text-red-600">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default FormikSelect;
