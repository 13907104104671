import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FaChevronRight, FaFilter, FaShoppingCart } from 'react-icons/fa';
import Chips from '../../../../components/Chips/Chips';
import Cards from '../../../../components/CatalogueCards/Cards';
import { ChipBtn, IOrder, IProduct, MyCards, MyShellCard } from '../../../../types/interfaces';
import FilterButton from '../../../../components/FilterButton/FilterButton';
import FilterForm from '../../../../components/FilterForm/FilterForm';
import { getCartList, getGoodsList } from '../../../../services/catalogService';
import { IPageRequest } from '../../../../components/CustomTable';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import DropdownMenu from '../../../../components/DropdownMenu';
import { useNavigate } from 'react-router-dom';
import { getTags } from '../../../../services/supplierService';
import AppButton from '../../../../components/AppButton/AppButton';
import { ProductType, SortEnum } from '../../../../types/enums';
import { objToQueryParams } from '../../../../utils/helper';

interface AllGoodsProps {
  selectedCategory: string | null;
}

const AllGoods = forwardRef<{ fetchProducts: () => void }, AllGoodsProps>(
  ({ selectedCategory }, ref) => {
    const [isFilterFormVisible, setIsFilterFormVisible] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({
      top: 0,
      left: 0,
    });
    const [tags, setTags] = useState<any[]>([]);
    const [pageSize, setPageSize] = useState(12);
    const [sortOrder, setSortOrder] = useState<SortEnum>(SortEnum.NEWEST);
    const [cartItems, setCartItems] = useState<IOrder[]>([]);

    const { supplierId, setSupplierId } = useGlobalContext();
    const navigate = useNavigate();

    const [products, setProducts] = useState<IProduct[]>([]);
    const [shellCards, setShellCards] = useState<MyShellCard[]>([
      { imagePath: 'images/ShellPix.svg', title: 'Shell Official Store' },
      { imagePath: 'images/ShellPix.svg ', title: 'Shell Official Store' },
      { imagePath: 'images/ShellPix.svg', title: 'Shell Official Store' },
      { imagePath: 'images/ShellPix.svg', title: 'Shell Official Store' },
      { imagePath: 'images/ShellPix.svg', title: 'Shell Official Store' },
    ]);

    const sortOptions = [
      { label: 'Newest (Default)', action: () => onSort(SortEnum.NEWEST) },
      {
        label: 'Price: Low to High',
        action: () => onSort(SortEnum.PRICE_LOW_HIGH),
      },
      {
        label: 'Price: High to Low',
        action: () => onSort(SortEnum.PRICE_HIGH_LOW),
      },
      { label: 'Alphabet: A-Z', action: () => onSort(SortEnum.ASCENDING) },
      { label: 'Alphabet: Z-A', action: () => onSort(SortEnum.DESCENDING) },
    ];

    const pageRequest: IPageRequest = {
      pageNumber: 1,
      pageSize: pageSize,
    };

    const handleFilterClose = (values: any) => {
      setIsFilterFormVisible(false);
      fetchProducts(values);
    };

    const handleFilterClick = (): void => {
      setIsFilterFormVisible((prev) => !prev);
    };

    const handleSortClick = () => {
      setDropdownPosition({ top: 20, left: 20 });
      setIsDropdownOpen((prev) => !prev);
    };

    const viewMoreProducts = () => {
      setPageSize((prevPageSize) => prevPageSize + 12);
      fetchProducts();
    };

    const fetchProducts = async (filteredValues?: any) => {
      // if (!supplierId) return setProducts([]);

      const fetchRequest = {
        supplierId,
        productType: ProductType.GOODS,
        pageNumber: pageRequest.pageNumber,
        pageSize: pageRequest.pageSize,
        sortOrder,
        ...(selectedCategory && { categoryId: selectedCategory }),
        ...(filteredValues && { ...filteredValues }),
      };
      const response = await getGoodsList(fetchRequest);
      if (response) {
        setProducts(response.data);
      }
    };

    const fetchSupplierTags = async () => {
      if (!supplierId) return;
      const response = await getTags(supplierId);
      if (response?.isSuccess) {
        setTags(response.data);
      }
    };

    const fetchCartItems = async () => {
      if (!supplierId) return;
      const response = await getCartList(supplierId, {
        pageNumber: 1,
        pageSize: 100,
      });
      if (response) {
        setCartItems(response.data);
      }
    };

    useEffect(() => {
      fetchProducts();
      fetchSupplierTags();
      fetchCartItems();
    }, []);

    useImperativeHandle(ref, () => ({
      fetchProducts,
    }));

    const onSort = (value: SortEnum) => {
      setSortOrder(value);
      fetchProducts();
    };

    const handleViewProduct = (productId: any) => {
      navigate(`/goods/detail?Id=${productId}`, {
        state: {
          productType: ProductType.GOODS,
        },
      });
    };

    return (
      <>
        <div className="bg-white rounded-2xl p-8 border border-[#E5E5E5]">
          <div className="flex items-center justify-between mb-8">
            <h2 className="text-2xl font-semibold">All Goods</h2>

            <div className="flex justify-between gap-x-4">
              <div
                className="flex items-center cursor-pointer space-x-2 border rounded-lg border-gray-400 px-2"
                onClick={() => navigate('/cart')}
              >
                {/* Cart Icon */}
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/outline/bag-happy.svg`}
                  alt="cart bag"
                />

                {/* Cart Title */}
                <span className="text-[#667085] ">Cart</span>

                {/* Notification Badge */}
                <span className="flex items-center justify-center bg-[#FA4D56] text-white text-[8px] font-bold rounded-full h-4 w-4">
                  {cartItems.length}
                </span>
              </div>
              <div className="relative">
                <FilterButton label="Sort by" onClick={handleSortClick} />
                {isDropdownOpen && (
                  <DropdownMenu
                    open={isDropdownOpen}
                    onClose={() => setIsDropdownOpen(false)}
                    items={sortOptions}
                  />
                )}
              </div>
              <div>
                <FilterButton label="Filters" onClick={handleFilterClick} />
              </div>
              <div
                className={`fixed top-0 right-0 h-full w-1/3 transition-transform duration-300 ${
                  isFilterFormVisible ? 'translate-x-0' : 'translate-x-full'
                }`}
              >
                <FilterForm onClose={handleFilterClose} />
              </div>
            </div>
          </div>
          <div className="flex gap-4 mb-8">
            {tags.map((tag, index) => (
              <Chips key={index} title={tag.tagName} bgColor={'bg-green-50'} />
            ))}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-10">
            {products.map((product, index) => (
              <Cards
                key={index}
                imagePath={
                  product?.productImages.length > 0
                    ? product?.productImages[0].sharepointPath
                    : 'images/compressor.svg'
                }
                showDefaultImage={product?.productImages.length <= 0}
                title={product.productName}
                description={product.description}
                currency={product.currency}
                price={product.price.toString()}
                showPrice={true}
                showDescription={true}
                onClick={() => handleViewProduct(product.productId)}
              />
            ))}
          </div>
          {products.length > 12 && (
            <div className="flex w-full justify-center">
              <AppButton
                title={'See More'}
                onClick={viewMoreProducts}
                className="py-3 px-12 bg-primary rounded-xl font-semibold mb-10"
              />
            </div>
          )}
          <div className="flex w-full justify-between">
            <h3 className="text-2xl font-semibold text-[#222]">Recommended Suppliers for you</h3>

            <div className="flex items-center text-gray-700 text-lg font-medium ">
              <p className="mr-1 border-b-2 border-[#525252]">See All</p>
              <FaChevronRight size={12} />
            </div>
          </div>
          <div className="flex gap-4 overflow-auto mb-10 mt-10">
            {shellCards.map((shellCard, index) => (
              <div
                key={index}
                className="flex flex-col items-center bg-white border border-gray-300 rounded-lg"
              >
                <Cards
                  imagePath={shellCard.imagePath}
                  title={shellCard.title}
                  showDescription={false}
                  showPrice={false}
                  styleConfig={{
                    image: 'w-48 h-48 object-cover rounded-t-lg',
                    title: 'font-bold',
                  }}
                />
              </div>
            ))}
          </div>
          <div className="flex w-full justify-between bg-blue-300 p-2 mb-6">
            <div className="text-2xl font-bold">
              <p>Limited Deals</p>
            </div>
            <div className="flex text-gray-700 text-xl items-center underline decoration-green-700">
              <p className="mr-1">See All</p>
              <FaChevronRight size={8} />
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default AllGoods;
