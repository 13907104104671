import React from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns'; // Add this library for date formatting
import 'react-datepicker/dist/react-datepicker.css';

type FormikDateInputProps = {
  label: string;
  name: string;
  isRequired?: boolean;
};

const FormikDateInput: React.FC<FormikDateInputProps> = ({ label, isRequired, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  // Helper function to parse and format dates
  const formattedDate = field.value
    ? format(new Date(field.value), 'dd/MM/yyyy') // Format for display
    : ''; // Empty if no value

  return (
    <div className="mb-5 w-full">
      {label && (
        <label htmlFor={props.name} className="block text-sm font-medium text-[#344054] mb-1.5">
          {label}
          {isRequired && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}

      <div className="relative">
        <DatePicker
          id={props.name}
          {...field}
          selected={field.value ? new Date(field.value) : null} // Keep Date object for state
          onChange={(date: Date | null) => setFieldValue(props.name, date)}
          dateFormat="dd/MM/yyyy"
          className={`block w-full px-3.5 pl-[38px] py-2.5 border rounded-md shadow-sm appearance-none bg-white placeholder:text-[#667085] placeholder:text-sm ${
            meta.touched && meta.error ? 'border-red-500' : 'border-[#D0D5DD]'
          }`}
          placeholderText="DD/MM/YYYY"
          value={formattedDate} // Display formatted value
          wrapperClassName="custom-date-input"
        />

        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/outline/calendar.svg`}
          alt="Calendar icon"
          className="absolute left-[14px] top-[10px]"
        />
      </div>

      {meta.touched && meta.error && <div className="mt-1 text-sm text-red-600">{meta.error}</div>}
    </div>
  );
};

export default FormikDateInput;
