import React from 'react';
import { useFormik } from 'formik';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { RegisterValidationSchema } from '../ValidationSchema';
import AppButton from '../../../components/AppButton/AppButton';
import '../SignUp/Register.css';
import { useNavigate } from 'react-router-dom';
import { Register as RegistrationService } from '../../../services/AccountServices';
import Navbar from '../../../layouts/NavBar/Navbar';
import GoogleLoginButton from '../../../components/GoogleLoginButton';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';

interface RegisterFormValues {
  firstName?: string;
  lastName?: string;
  userName: string;
  password: string;
  confirmPassword: string;
  email: string;
  phoneNumber: string;
  termsAccepted: boolean;
}

const Register: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
  const formik = useFormik({
    initialValues: {
      userName: '',
      password: '',
      confirmPassword: '',
      email: '',
      phoneNumber: '',
      termsAccepted: false,
    },
    validationSchema: RegisterValidationSchema,
    onSubmit: async (values: RegisterFormValues) => {
      try {
        const payload = {
          ...values,
          phoneNumber: `+${values.phoneNumber}`,
        };
        setIsLoading(true);
        const response = await RegistrationService(payload);

        if (response?.isSuccess) {
          navigate('/otp', { state: { email: values.email } });

          return response.data;
        } else {
          toast.error(response?.errorMessage);
        }
      } catch {
        toast.error('Error signing up');
      }
      setIsLoading(false);
    },
  });
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    formik.setFieldValue('email', email);
    formik.setFieldValue('userName', email); // Automatically set userName to email
  };

  const handleOutlookSignUp = () => {
    toast.info(t('outlookSignUpNotAvailable', 'Outlook Sign up is not available at the moment'));
  };

  return (
    <div>
      <Navbar />
      <div className="register-form">
        <div className="form-header">
          <h4 className="text-xl font-semibold mt-5">
            {t(
              'welcomeToAnpEsscPortal',
              'Welcome to Autoridade Nacional Do Petroleo (ANP) Timor-Leste - ESSC PORTAL'
            )}
          </h4>
        </div>

        <form onSubmit={(e) => e.preventDefault()} className="register-card">
          <h1 className="text-3xl font-bold md:text-center mb-8">{t('register', 'Register')}</h1>

          <div className="social-login-buttons">
            <GoogleOAuthProvider clientId={clientId}>
              <div className="">
                <GoogleLoginButton />
              </div>
            </GoogleOAuthProvider>
            {/* <AppButton
              leftIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/login/goggle.png`}
                  alt="goggle"
                />
              }
              className="flex gap-2 items-center justify-center w-full text-[10px] border border-gray-300 py-2 px-1 rounded-md"
              title={t('google', 'Google')}
              onClick={() => navigate('www.google.com')}
            /> */}
            <AppButton
              leftIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/microsoft_365.svg`}
                  alt="microsoft"
                />
              }
              className="flex gap-1.5 items-center justify-center w-full min-w-[142px] text-xs border border-[#dadce0] py-2 px-1 rounded-md"
              title="Microsoft 365"
              onClick={handleOutlookSignUp}
            />

            <AppButton
              leftIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/outlook.svg`}
                  className="w-[23px] h-[23px]"
                  alt="outlook"
                />
              }
              className="flex gap-1.5 items-center justify-center w-full text-xs border border-[#dadce0] py-2 px-1 rounded-md"
              title="Outlook"
              onClick={handleOutlookSignUp}
            />
          </div>

          <div className="divider-container">
            <div className="divider"></div>
            <span className="divider-text">{t('or', 'OR')}</span>
            <div className="divider"></div>
          </div>

          <div className="mb-6">
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
              {t('firstName', 'First Name')}
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="firstName"
              placeholder={t('enterFirstName', 'Enter First Name')}
              name="firstName"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              className="mt-1.5 block w-full px-3.5 py-2.5 border border-[#D0D5DD] rounded-lg shadow-sm"
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <span className="text-red-500 text-sm">{formik.errors.firstName}</span>
            )}
          </div>

          <div className="mb-6">
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
              {t('lastName', 'Last Name')}
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="lastName"
              placeholder={t('enterLastName', 'Enter Last Name')}
              name="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              className="mt-1.5 block w-full px-3.5 py-2.5 border border-[#D0D5DD] rounded-lg shadow-sm"
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <span className="text-red-500 text-sm">{formik.errors.lastName}</span>
            )}
          </div>
          <div className="mb-6">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              {t('email', 'Email')}
              <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              placeholder={t('enterEmail', 'Enter Email Address')}
              name="email"
              onChange={handleEmailChange}
              value={formik.values.email}
              className="mt-1.5 block w-full px-3.5 py-2.5 border border-[#D0D5DD] rounded-lg shadow-sm"
            />
            {formik.touched.email && formik.errors.email && (
              <span className="text-red-500 text-sm">{formik.errors.email}</span>
            )}
          </div>

          <div className="mb-6">
            <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
              {t('phone_Number', 'Phone Number')}
              <span className="text-red-500">*</span>
            </label>
            {/* <input
              maxLength={15}
              minLength={6}
              type="text"
              id="phoneNumber"
              placeholder={t('enterphoneNumber', '+234 803 123 4567')}
              name="phoneNumber"
              onChange={formik.handleChange}
              value={formik.values.phoneNumber}
              className="mt-1.5 block w-full px-3.5 py-2.5 border border-[#D0D5DD] rounded-lg shadow-sm"
            /> */}

            <PhoneInput
              country={'us'}
              containerStyle={{ width: '410px' }}
              inputStyle={{ width: '100%' }}
              placeholder="+234 803 123 4567"
              value={formik.values.phoneNumber}
              onChange={(value) => formik.setFieldValue('phoneNumber', value)}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <span className="text-red-500 text-sm">{formik.errors.phoneNumber}</span>
            )}
          </div>

          <div className="mb-6">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              {t('password', 'Password')}
              <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder={t('enterPassword', 'Enter Password')}
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                className="mt-1.5 block w-full px-3.5 py-2.5 border border-[#D0D5DD] rounded-lg shadow-sm"
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {formik.touched.password && formik.errors.password && (
              <span className="text-red-500 text-sm">{formik.errors.password}</span>
            )}
          </div>

          <div className="mb-6">
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
              {t('confirmPassword', 'Confirm Password')}
              <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                placeholder={t('confirmPassword', 'Confirm Password')}
                name="confirmPassword"
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                className="mt-1.5 block w-full px-3.5 py-2.5 border border-[#D0D5DD] rounded-lg shadow-sm"
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <span className="text-red-500 text-sm">{formik.errors.confirmPassword}</span>
            )}
          </div>

          <div className="mb-6">
            <div className="flex items-start gap-4">
              <input
                type="checkbox"
                id="termsAccepted"
                name="termsAccepted"
                onChange={formik.handleChange}
                checked={formik.values.termsAccepted}
                className=" w-fit  bg-[#FFC726] text-black "
              />

              <span className="text-sm text-[#525252] max-w-[350px]">
                {t(
                  'iUnderstand',
                  'I understand and agree to ESSC Privacy Policy and Terms and conditions and I authorise ESSC to contact me for account management purposes via the contact information I provide.'
                )}
              </span>
            </div>
            {formik.touched.termsAccepted && formik.errors.termsAccepted && (
              <span className="text-red-500 text-sm">{formik.errors.termsAccepted}</span>
            )}
          </div>

          <div className="flex justify-center mt-4">
            <AppButton
              className="w-full bg-primary text-black font-semibold p-4 rounded-md"
              title={t('register', 'Register')}
              onClick={() => formik.handleSubmit()}
              disabled={isLoading}
            />
          </div>

          <div className="copyright">
            <h6>{t('copyright', 'Copyright  2024 ANP ESSC Portal. All rights reserved')}</h6>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
