import { useEffect, useState } from 'react';
import TenderOpportunitiesComponent from './TenderOpportunitiesComponent';
import BiddingComponent from './BiddingComponent';
import { useSearchParams } from 'react-router-dom';

const SupplierProcurementComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Retrieve the tab from query params or default to 'Tender Opportunities'
  const initialTab = searchParams.get('tab') || 'Tender Opportunities';
  const [activeTab, setActiveTab] = useState(initialTab);

  // Update the query parameter when the active tab changes
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setSearchParams({ tab });
  };

  // Sync the active tab with query params when the component mounts
  useEffect(() => {
    if (searchParams.get('tab') !== activeTab) {
      setSearchParams({ tab: activeTab });
    }
  }, [activeTab, searchParams, setSearchParams]);

  return (
    <div className="mx-auto flex max-w-screen-xl">
      <div className="flex-1">
        <div className="p-8">
          <>
            <div className="mb-8">
              <div className="flex items-center justify-between">
                <div>
                  <h1 className="mb-1 text-2xl font-semibold text-gray-900">
                    Electronic Procurement
                  </h1>
                  <p className="text-base text-[#525252]">
                    Create tender opportunities and get qualified suppliers (update copy)
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-6 flex space-x-8 border-b border-gray-200">
              {['Tender Opportunities', 'Bidding'].map((tab) => (
                <button
                  key={tab}
                  className={`px-4 py-3 text-base font-semibold text-gray-900 ${activeTab === tab ? 'border-b-2 border-primary' : ''}`}
                  onClick={() => handleTabChange(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>

            {activeTab === 'Tender Opportunities' && <TenderOpportunitiesComponent />}
            {activeTab === 'Bidding' && <BiddingComponent />}
          </>
        </div>
      </div>
    </div>
  );
};

export default SupplierProcurementComponent;
