import React, { TextareaHTMLAttributes } from 'react';
import { useField } from 'formik';

type FormikTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string;
  name: string;
  isRequired?: boolean;
};

const FormikTextarea: React.FC<FormikTextareaProps> = ({ label, isRequired, ...props }) => {
  const [field, meta] = useField({
    name: props.name,
    value: props.value,
  });

  return (
    <div className="mb-5">
      {label ? (
        <label
          htmlFor={props.id || props.name}
          className="block text-sm font-medium text-[#344054] mb-1.5"
        >
          {label}
          {isRequired && <span className="text-red-500 ml-1">*</span>}
        </label>
      ) : null}

      <textarea
        {...field}
        {...props}
        className={`
          w-full min-h-32 px-3.5 py-2.5 border rounded-md shadow-sm resize-none

          ${meta.touched && meta.error ? 'border-red-500' : 'border-[#D0D5DD]'}
          ${props.className || ''}
        `}
      />

      {meta.touched && meta.error ? (
        <div className="mt-1 text-sm text-red-600">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default FormikTextarea;
