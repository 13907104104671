import React, { useEffect, useState } from 'react';
import { FaPlus, FaTimes, FaTrash } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ProgressBarNew from '../../../../components/ProgressBarNew/ProgressBarNew';
import {
  createGoods,
  getAllCategories,
  getProductById,
  updateGoods,
} from '../../../../services/catalogService';
import { toast } from 'react-toastify';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { getTags } from '../../../../services/supplierService';
import Select, { ActionMeta, MultiValue } from 'react-select';
import { OptionType } from '../../../../types/interfaces';
import { deleteImage, uploadImage } from '../../../../services/edmsService';
import { ProductType } from '../../../../types/enums';

interface AddProductsProps {
  show: boolean;
  close: (value: boolean) => void;
  onProductAdded: () => void;
  initialValues?: any;
}

interface productImage {
  sharepointPath: string;
  imageId: string;
}

const EditProducts: React.FC<AddProductsProps> = ({
  show,
  close,
  onProductAdded,
  initialValues,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedImages, setSelectedImages] = useState<productImage[]>([]);
  const [categories, setCategories] = useState<{ categorId: string; categoryName: string }[]>([]);
  const [subCategories, setSubCategories] = useState<{ categorId: string; categoryName: string }[]>(
    []
  );
  const [isSubCategoryDisabled, setIsSubCategoryDisabled] = useState(true);
  const [tags, setTags] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<OptionType[]>([]);

  const { supplierId, setSupplierId } = useGlobalContext();

  const formatNumber = (value: any) => (!value ? '' : new Intl.NumberFormat().format(value));
  const parseNumber = (value: any) => value.replace(/,/g, '');

  useEffect(() => {
    const fetchCategories = async () => {
      const params = { isParentCategory: true };
      const response = await getAllCategories(params);
      if (response?.isSuccess) {
        setCategories(response.data);
      }
    };

    const fetchSupplierTags = async () => {
      if (!supplierId) return;
      const response = await getTags(supplierId);
      if (response?.isSuccess) {
        setTags(response.data);
      }
    };

    fetchCategories();
    fetchSupplierTags();
  }, []);

  useEffect(() => {
    if (initialValues) {
      getSubCategories(initialValues.productCategory.mainCategoryId);
      fetchProduct();
      setSelectedTags(initialValues.productTags);
    }
  }, [initialValues]);

  const handleTagChange = (
    newValue: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    const selectedOptions = newValue as OptionType[];
    setSelectedTags(selectedOptions);
    formik.setFieldValue('tags', selectedOptions);
  };

  const handleCategoryChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCategoryId = event.target.value;
    formik.setFieldValue('category', selectedCategoryId);

    getSubCategories(selectedCategoryId);
  };

  const getSubCategories = async (selectedCategoryId: string) => {
    if (selectedCategoryId) {
      setIsSubCategoryDisabled(false);
      const params = {
        categoryId: selectedCategoryId,
        isParentCategory: false,
      };
      const response = await getAllCategories(params);
      if (response?.isSuccess) {
        setSubCategories(response.data);
      }
    } else {
      setIsSubCategoryDisabled(true);
      setSubCategories([]);
    }
  };

  const getValidationSchema = (step: number) => {
    switch (step) {
      case 1:
        return Yup.object({
          title: Yup.string().required('Title is required'),
          category: Yup.string().required('Category is required'),
          subCategory: Yup.string().required('Sub-category is required'),
          location: Yup.string().required('Location is required'),
          description: Yup.string().nullable(),
          // images: Yup.array().min(1, 'At least one image is required'),
        });
      case 2:
        return Yup.object({
          brand: Yup.string().required('Brand is required'),
          serialNumber: Yup.string().required('Serial number is required'),
          certification: Yup.string().required('Certification is required'),
          model: Yup.string().required('Model is required'),
          productionCountry: Yup.string().required('Production Country is required'),
          note: Yup.string().nullable(),
          productLine: Yup.string().required('Product Line is required'),
          sizeDimensions: Yup.string().required('Size Dimensions are required'),
          warrantyDuration: Yup.string().required('Warranty Duration is required'),
          warrantyType: Yup.string().required('Warranty Type is required'),
          productAvailability: Yup.number()
            .required('Product Availability is required')
            .positive('Product Availability must be a positive number')
            .typeError('Product Availability must be a valid number'),
          distributionMechanism: Yup.string().required('Distribution Mechanism is required'),
          comment: Yup.string().nullable(),
        });
      case 3:
        return Yup.object({
          globalPrice: Yup.number()
            .required('Global Price is required')
            .positive('Price must be a positive number')
            .typeError('Global Price must be a valid number'),
          salePrice: Yup.number()
            .required('Sale Price is required')
            .positive('Sale Price must be a positive number')
            .typeError('Sale Price must be a valid number'),
        });
      default:
        return Yup.object({});
    }
  };

  const formik = useFormik({
    initialValues: initialValues
      ? {
          title: initialValues.productName || '',
          category: initialValues.productCategory.mainCategoryId || '',
          subCategory: initialValues.productCategory.categoryId || '',
          location: initialValues.location || '',
          description: initialValues.description || '',
          images: initialValues.images || [],
          brand: initialValues.brand || '',
          serialNumber: initialValues.serialNumber || '',
          certification: initialValues.certification || '',
          model: initialValues.model || '',
          productionCountry: initialValues.productionCountry || '',
          productLine: initialValues.productLine || '',
          sizeDimensions: initialValues.sizeDimensions || '',
          warrantyDuration: initialValues.warrantyDuration || '',
          warrantyType: initialValues.warrantType || '',
          productAvailability: initialValues.availability || '',
          distributionMechanism: initialValues.distributionMechanism || '',
          comment: initialValues.comment || '',
          globalPrice: initialValues.globalPrice || '',
          salePrice: initialValues.price || '',
          tags: initialValues.tags || [],
          isVatInclusive: initialValues.isTaxInclusive || false,
          percentageVat: initialValues.tax || 0,
        }
      : {
          title: '',
          category: '',
          subCategory: '',
          location: '',
          description: '',
          images: [],
          brand: '',
          serialNumber: '',
          certification: '',
          model: '',
          productionCountry: '',
          productLine: '',
          sizeDimensions: '',
          warrantyDuration: '',
          warrantyType: '',
          productAvailability: '',
          distributionMechanism: '',
          comment: '',
          globalPrice: '',
          salePrice: '',
          tags: [],
          isVatInclusive: false,
          percentageVat: 0,
        },
    validationSchema: getValidationSchema(currentStep),
    validateOnChange: false,
    enableReinitialize: true,
    // validateOnBlur: false,
    onSubmit: (values) => {
      if (currentStep < 3) {
        setCurrentStep((prev) => prev + 1);
      } else {
        // console.log('Final Submit', values);
        const payload = {
          productId: initialValues?.productId,
          productName: values.title,
          description: values.description,
          categoryId: values.subCategory,
          location: values.location,
          globalPrice: values.globalPrice,
          price: values.salePrice,
          brand: values.brand,
          serialNumber: values.serialNumber,
          certification: values.certification,
          productLine: values.productLine,
          warrantyDuration: parseInt(values.warrantyDuration, 10),
          warrantType: values.warrantyType,
          availability: parseInt(values.productAvailability, 10),
          quantity: parseInt(values.productAvailability, 10),
          distributionMechanism: values.distributionMechanism,
          comment: values.comment,
          model: values.model,
          productionCountry: values.productionCountry,
          tagIds: values.tags.map((tag: OptionType) => tag.value),
          currency: 'USD',
          status: 'Draft',
          tax: values.percentageVat,
          isTaxInclusive: values.isVatInclusive,
          productType: ProductType.GOODS,
          companyId: supplierId,
        };

        handleUpdateProduct(payload);
      }
    },
  });

  const saveProductImages = async (productId: string, images: any) => {
    const formData = new FormData();
    images.forEach((image: any) => {
      formData.append('files', image);
    });
    formData.append('entityId', productId);
    // formData.append('module', '1');
    formData.append('documentTypeName', 'ProductImages');

    const response = await uploadImage(formData);
    if (response?.isSuccess) {
      toast.success(response?.data.message);
      fetchProduct();
    } else {
      toast.error('Error while uploading product images');
    }
  };

  const fetchProduct = async () => {
    if (!initialValues) return;
    const response = await getProductById(initialValues.productId);
    if (response?.isSuccess) {
      setSelectedImages(response.data.productImages);
    }
  };

  const handleUpdateProduct = async (payload: any) => {
    try {
      const response = await updateGoods(payload);
      // console.log('Product created successfully', response);
      if (response?.isSuccess) {
        toast.success(response?.data.message);
        formik.resetForm();
        setCurrentStep(1);
        setSelectedImages([]);
        onProductAdded();
      } else {
        toast.error(response?.errorMessage);
      }
    } catch (error) {
      toast.error('Error updating product');
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      event.target.value = '';
      if (initialValues) saveProductImages(initialValues.productId, [...fileArray]);
    }
  };

  const handleImageClick = () => {
    const fileInput = document.getElementById('imageUpload') as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleDeleteImage = async (imageId: string) => {
    const response = await deleteImage(imageId);
    if (response?.isSuccess) {
      fetchProduct();
      toast.success(response?.data.message);
    }
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white rounded-3xl shadow-lg w-full max-w-5xl py-8 sm:px-16 h-[90vh] overflow-y-auto">
        <button
          onClick={() => close(false)}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
          aria-label="Close modal"
        >
          <FaTimes size={20} />
        </button>
        <h2 className="text-2xl font-bold mt-8 mb-4">Add a New Product</h2>

        {/* Progress Bar */}
        <ProgressBarNew
          steps={['Product Information', 'Product Specification', 'Pricing']}
          currentStep={currentStep}
        />

        <form onSubmit={formik.handleSubmit}>
          {/* Step 1: Product Information */}
          {currentStep === 1 && (
            <>
              <div className="flex items-center space-x-4 mt-3 mb-2">
                {/* Upload button */}
                <div
                  className={`border border-gray-400 rounded-lg w-28 h-28 flex justify-center cursor-pointer ${selectedImages.length >= 6 ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={selectedImages.length < 6 ? handleImageClick : undefined}
                >
                  <span className="text-center flex flex-col items-center justify-between px-2 py-4 text-sm text-gray-500">
                    <FaPlus size={10} />
                    Add Image or Video
                  </span>
                  <input
                    id="imageUpload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                    multiple
                    disabled={selectedImages.length >= 6}
                  />
                </div>
                {/* Carousel for Image Preview */}
                <div className="flex overflow-x-auto space-x-4 w-full">
                  {selectedImages.map((image, index) => (
                    <div
                      key={index}
                      className="relative w-28 h-28 border border-gray-400 rounded-lg overflow-hidden"
                    >
                      <img
                        src={image.sharepointPath}
                        alt={`Uploaded ${index}`}
                        className="object-cover w-full h-full"
                      />

                      <button
                        type="button"
                        className="absolute top-1 right-1 text-white bg-red-500 rounded-full p-1"
                        onClick={() => handleDeleteImage(image.imageId)}
                      >
                        <FaTrash size={10} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <p className="text-gray-500 text-sm">
                First image is cover image. No watermarks. Upload jpeg, jpg, png, or mp4. Max size:
                10MB. Dimensions: 400x400 - 1500x1500.
              </p>

              <div className="mt-4 mb-5">
                <label className="block mb-2 font-medium">
                  Title of Good or Product <span className="text-red-700">*</span>
                </label>
                <input
                  type="text"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter the name of the good or product"
                  className="block w-full border border-gray-400 rounded-lg p-2 outline-none text-base"
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className="text-red-500 text-sm">{String(formik.errors.title)}</div>
                ) : null}
              </div>

              <div className="grid grid-cols-3 mb-5 gap-4 mt-4">
                <div>
                  <label className="block mb-2 font-medium">
                    Category <span className="text-red-700">*</span>
                  </label>
                  <select
                    name="category"
                    value={formik.values.category}
                    onChange={handleCategoryChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                  >
                    <option value="">Select</option>
                    {categories.map((category) => (
                      <option key={category.categorId} value={category.categorId}>
                        {category.categoryName}
                      </option>
                    ))}
                  </select>
                  {formik.touched.category && formik.errors.category ? (
                    <div className="text-red-500 text-sm">{String(formik.errors.category)}</div>
                  ) : null}
                </div>

                <div>
                  <label className="block mb-2 font-medium">
                    Sub-category <span className="text-red-700">*</span>
                  </label>
                  <select
                    name="subCategory"
                    value={formik.values.subCategory}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                    disabled={isSubCategoryDisabled}
                  >
                    <option value="">Select</option>
                    {subCategories.map((subCategory) => (
                      <option key={subCategory.categorId} value={subCategory.categorId}>
                        {subCategory.categoryName}
                      </option>
                    ))}
                  </select>
                  {formik.touched.subCategory && formik.errors.subCategory ? (
                    <div className="text-red-500 text-sm">{String(formik.errors.subCategory)}</div>
                  ) : null}
                </div>

                <div>
                  <label className="block mb-2 font-medium">
                    Location <span className="text-red-700">*</span>
                  </label>
                  <select
                    name="location"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                  >
                    <option value="">Select</option>
                    <option value="New York">New York</option>
                    <option value="San Francisco">San Francisco</option>
                  </select>
                  {formik.touched.location && typeof formik.errors.location === 'string' ? (
                    <div className="text-red-500 text-sm">{formik.errors.location}</div>
                  ) : null}
                </div>
              </div>
              <div>
                <label className=" col-span-1 mb-3 font-medium">Description</label>
                <textarea
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full border border-gray-400 rounded-lg p-2 outline-none text-base"
                  rows={3}
                />
                {/* {formik.touched.description && formik.errors.description ? (
                    <div className="text-red-500 text-sm">{formik.errors.description}</div>
                  ) : null} */}
              </div>
            </>
          )}

          {/* Step 2: Product Specification */}
          {currentStep === 2 && (
            <>
              <div>
                <p className="text-xl font-medium mt-4">Product Specification</p>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-2 mb-5">
                <div>
                  <label className="block mb-2 font-medium">
                    Brand<span className="text-red-700">*</span>
                  </label>
                  <input
                    type="text"
                    name="brand"
                    value={formik.values.brand}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="State the Brand name"
                    className="w-full border border-gray-400 rounded-lg p-2 outline-none"
                  />
                  {formik.touched.brand && typeof formik.errors.brand === 'string' ? (
                    <div className="text-red-500 text-sm">{formik.errors.brand}</div>
                  ) : null}
                </div>

                <div>
                  <label className="block mb-2 font-medium">
                    Serial Number<span className="text-red-700">*</span>
                  </label>
                  <input
                    type="text"
                    name="serialNumber"
                    value={formik.values.serialNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Whats the product unique number?"
                    className="w-full border border-gray-400 rounded-lg p-2 outline-none"
                  />
                  {formik.touched.serialNumber && typeof formik.errors.serialNumber === 'string' ? (
                    <div className="text-red-500 text-sm">{formik.errors.serialNumber}</div>
                  ) : null}
                </div>
              </div>

              <div className="grid grid-cols-3 gap-4 mt-4 space-y-2">
                <div>
                  <label className="block mb-2 font-medium">
                    Certification<span className="text-red-700">*</span>
                  </label>
                  <select
                    name="certification"
                    value={formik.values.certification}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                  >
                    <option value="">Select Certification</option>
                    <option value="Cert 1">Cert 1</option>
                    <option value="Cert 2">Cert 2</option>
                  </select>
                  {formik.touched.certification &&
                  typeof formik.errors.certification === 'string' ? (
                    <div className="text-red-500 text-sm">{formik.errors.certification}</div>
                  ) : null}
                </div>

                <div>
                  <label className="block mb-2 font-medium">
                    Model<span className="text-red-700">*</span>
                  </label>
                  <input
                    type="text"
                    name="model"
                    value={formik.values.model}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg p-2 outline-none"
                    placeholder="Model ID of Manufacturer"
                  />
                  {formik.touched.model && typeof formik.errors.model === 'string' ? (
                    <div className="text-red-500 text-sm">{formik.errors.model}</div>
                  ) : null}
                </div>
                <div>
                  <label className="block mb-2 font-medium">
                    Production Country<span className="text-red-700">*</span>
                  </label>
                  <select
                    name="productionCountry"
                    value={formik.values.productionCountry}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                  >
                    <option value="">Select Production Country</option>
                    <option value="Country 1">Country 1</option>
                    <option value="Country 2">Country 2</option>
                  </select>
                  {formik.touched.productionCountry &&
                  typeof formik.errors.productionCountry === 'string' ? (
                    <div className="text-red-500 text-sm">{formik.errors.productionCountry}</div>
                  ) : null}
                </div>

                <div>
                  <label className="block mb-2 font-medium">
                    Product Line<span className="text-red-700">*</span>
                  </label>
                  <input
                    type="text"
                    name="productLine"
                    value={formik.values.productLine}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Product Line/ Range/ Sub-Brand"
                    className="w-full border border-gray-400 rounded-lg p-2 outline-none"
                  />
                  {formik.touched.productLine && typeof formik.errors.productLine === 'string' ? (
                    <div className="text-red-500 text-sm">{formik.errors.productLine}</div>
                  ) : null}
                </div>

                <div>
                  <label className="block mb-2 font-medium">
                    Size/Dimensions <span className="text-red-700">*</span>
                  </label>
                  <input
                    type="text"
                    name="sizeDimensions"
                    value={formik.values.sizeDimensions}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Size (L x W x H x CM)"
                    className="w-full border border-gray-400 rounded-lg p-2 outline-none"
                  />
                  {formik.touched.sizeDimensions &&
                  typeof formik.errors.sizeDimensions === 'string' ? (
                    <div className="text-red-500 text-sm">{formik.errors.sizeDimensions}</div>
                  ) : null}
                </div>
                <div>
                  <label className="block mb-2 font-medium">
                    WarrantyDuration <span className="text-red-700">*</span>
                  </label>
                  <select
                    name="warrantyDuration"
                    value={formik.values.warrantyDuration}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                  >
                    <option value="">Select Warranty Duration</option>
                    <option value="1">1 year</option>
                    <option value="2">2 years</option>
                  </select>
                  {formik.touched.warrantyDuration && formik.errors.warrantyDuration ? (
                    <div className="text-red-500 text-sm">
                      {String(formik.errors.warrantyDuration)}
                    </div>
                  ) : null}
                </div>

                <div>
                  <label className="block mb-2 font-medium">
                    Warranty Type <span className="text-red-700">*</span>
                  </label>
                  <select
                    name="warrantyType"
                    value={formik.values.warrantyType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                  >
                    <option value="">Select Warranty Available</option>
                    <option value="Type 1">Type 1</option>
                    <option value="Type 2">Type 2</option>
                  </select>
                  {formik.touched.warrantyType && typeof formik.errors.warrantyType === 'string' ? (
                    <div className="text-red-500 text-sm">{formik.errors.warrantyType}</div>
                  ) : null}
                </div>
                <div>
                  <label className="block mb-2 font-medium">
                    Product Availability<span className="text-red-700">*</span>
                  </label>
                  <input
                    type="number"
                    name="productAvailability"
                    value={formik.values.productAvailability}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Number Of Product Available"
                    className="w-full border border-gray-400 rounded-lg p-2 outline-none"
                  />
                  {formik.touched.productAvailability && formik.errors.productAvailability ? (
                    <div className="text-red-500 text-sm">
                      {String(formik.errors.productAvailability)}
                    </div>
                  ) : null}
                </div>

                <div>
                  <label className="block mb-2 font-medium">
                    Distribution Mechanisms <span className="text-red-700">*</span>
                  </label>
                  <select
                    name="distributionMechanism"
                    value={formik.values.distributionMechanism}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                  >
                    <option value="">Select Distribution Mechanism</option>
                    <option value="Mechanism 1">Mechanism 1</option>
                    <option value="Mechanism 2">Mechanism 2</option>
                  </select>
                  {formik.touched.distributionMechanism &&
                  typeof formik.errors.distributionMechanism === 'string' ? (
                    <div className="text-red-500 text-sm">
                      {formik.errors.distributionMechanism}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Add tags multi select field here */}
              <div className="grid mt-4">
                <label className="block mb-2 font-medium">
                  Tags<span className="text-red-700">*</span>
                </label>
                <Select
                  id="tags"
                  isMulti
                  options={tags.map((tag) => ({
                    value: tag.tagId,
                    label: tag.tagName,
                  }))}
                  value={selectedTags}
                  onChange={handleTagChange}
                  className="text-sm"
                />
                {formik.touched.tags && formik.errors.tags ? (
                  <div className="text-red-500 text-sm">
                    {typeof formik.errors.tags === 'string' ? formik.errors.tags : ''}
                  </div>
                ) : null}
              </div>

              <div className="grid gap-4 mt-4">
                <div className=" col-span-1">
                  <label className="block mb-2 font-medium">Note or Comment</label>
                  <textarea
                    name="comment"
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Name/Comment"
                    className="w-full border border-gray-400 rounded-lg p-2 outline-none"
                    rows={3}
                  />
                </div>
              </div>
            </>
          )}

          {/* Step 3: Pricing */}
          {currentStep === 3 && (
            <>
              <div className="mt-8 mb-6 flex  w-[70%] gap-8">
                <div className="">
                  <label className="block mb-2 font-medium">
                    Global Price(USD)<span className="text-red-700">*</span>
                  </label>
                  <input
                    type="text"
                    name="globalPrice"
                    onBlur={formik.handleBlur}
                    placeholder="Global Price"
                    className="w-full border border-gray-400 rounded-lg p-2"
                    onChange={(e: any) => {
                      const rawValue = parseNumber(e.target.value);
                      const numericValue = rawValue ? Number(rawValue) : 0;
                      formik.setFieldValue('globalPrice', numericValue);
                    }}
                    value={formatNumber(formik.values.globalPrice)}
                  />
                  {formik.touched.globalPrice && typeof formik.errors.globalPrice === 'string' ? (
                    <div className="text-red-500 text-sm">{formik.errors.globalPrice}</div>
                  ) : null}
                </div>
                <div>
                  <label className="block mb-2 font-medium">
                    Sale Price(USD)<span className="text-red-700">*</span>
                  </label>
                  <input
                    type="text"
                    name="salePrice"
                    onBlur={formik.handleBlur}
                    placeholder="Sale Price"
                    className="w-full border border-gray-400 rounded-lg p-2"
                    onChange={(e: any) => {
                      const rawValue = parseNumber(e.target.value);
                      const numericValue = rawValue ? Number(rawValue) : 0;
                      formik.setFieldValue('salePrice', numericValue);
                    }}
                    value={formatNumber(formik.values.salePrice)}
                  />
                  {formik.touched.salePrice && typeof formik.errors.salePrice === 'string' ? (
                    <div className="text-red-500 text-sm">{formik.errors.salePrice}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex w-[100%] gap-2 items-center mb-6">
                <input
                  type="checkbox"
                  id="isVatInclusive"
                  name="isVatInclusive"
                  checked={formik.values.isVatInclusive}
                  onChange={formik.handleChange}
                  className="h-6 w-6"
                />
                <label htmlFor="isVatInclusive" className="text-base">
                  Is your sale price inclusive of VAT?
                </label>
              </div>
              <div className="w-[30%] mb-8">
                <label className="block mb-2 font-medium">
                  Percentage VAT% (USD)<span className="text-red-700">*</span>
                </label>
                <input
                  type="number"
                  name="percentageVat"
                  value={formik.values.percentageVat}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Percentage VAT"
                  className="w-full border border-gray-400 rounded-lg p-2"
                  disabled={formik.values.isVatInclusive}
                />
                {formik.touched.percentageVat && formik.errors.percentageVat ? (
                  <div className="text-red-500 text-sm">
                    {typeof formik.errors.percentageVat === 'string'
                      ? formik.errors.percentageVat
                      : ''}
                  </div>
                ) : null}
              </div>
            </>
          )}

          <div className="mt-6 flex float-end gap-8">
            <button
              type="button"
              className="px-8 py-2 bg-gray-300 rounded-lg"
              onClick={() => setCurrentStep((prev) => Math.max(prev - 1, 1))}
            >
              {currentStep < 3 ? 'Back' : 'Back'}
            </button>
            <button type="submit" className="px-8 py-2 bg-primary rounded-lg">
              {currentStep < 3 ? 'Next' : 'Update'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProducts;
