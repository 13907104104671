import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../../components/BreadCrumb/BreadCrumb';
import Certifications from './Certifications';
import Products from './Products/Products';
import Services from './Services';
import Reviews from './Reviews';
import Orders from './Orders';
import Overview from './Overview';
import { TabItemProps } from '../../../types/interfaces';
import AppButton from '../../../components/AppButton/AppButton';
import { toast } from 'react-toastify';
import AffiliatesAndSubsidiaries from './AffiliatesAndSubsidiaries';
import { useLocation } from 'react-router-dom';
import { getSupplierById } from '../../../services/supplierService';

const Profile = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<
    | 'Profile'
    | 'Certifications'
    | 'Affiliates and Subsidiaries'
    | 'Products'
    | 'Services'
    | 'Reviews'
    | 'Orders'
  >('Profile');
  // Handler for switching tabs
  const handleTabClick = (
    tab:
      | 'Profile'
      | 'Certifications'
      | 'Affiliates and Subsidiaries'
      | 'Products'
      | 'Services'
      | 'Reviews'
      | 'Orders'
  ) => {
    setActiveTab(tab);
  };

  const [company, setCompany] = useState<any>(null);
  const location = useLocation();
  const companyId = location.state?.companyId;

  const getSupplierInformation = async () => {
    try {
      if (!companyId) return;
      const response = await getSupplierById(companyId);
      if (response) {
        const supplierData = response;
        setCompany(supplierData);
      }
    } catch (error) {
      toast.error(t('errorGettingSupplierInformation', 'Error getting supplier information'));
    }
  };

  useEffect(() => {
    getSupplierInformation();
  }, [companyId]);

  return (
    <>
      <div className="flex mt-4 gap-8">
        <div className="w-[75%]">
          <div className="w-full px-3 pt-4 pb-2 bg-white border border-gray-300 shadow-sm rounded-lg">
            <div className="w-full flex items-center gap-4 ">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/ShellPix.svg`}
                alt={'Shell Pix'}
                className={`w-16 h-16 object-cover rounded-t-lg`}
              />
              <p className="underline text-lg">Shell USA</p>
            </div>
            <Breadcrumb
              paths={[
                { label: 'Catalogue', path: '/catalogue' },
                // { label: 'Search', path: '/search' },
                // { label: 'Gas Compressor Series', path: '/search' },
                { label: 'Profile', path: '/settings/supplier-profile' },
              ]}
            />
          </div>
          {/* Tabs Section */}
          <div className=" mb-4 mt-4 ">
            <div className="flex justify-start space-x-8 w-full px-3 pt-4 mb-4 border border-gray-300 bg-white shadow-sm rounded-lg">
              <TabItem
                title={t('Profile', 'Profile')}
                isActive={activeTab === 'Profile'}
                onClick={() => handleTabClick('Profile')}
              />
              <TabItem
                title={t('Certifications', ' Certifications')}
                isActive={activeTab === 'Certifications'}
                onClick={() => handleTabClick('Certifications')}
              />
              <TabItem
                title={t('Affiliates and Subsidiaries', ' Affiliates and Subsidiaries')}
                isActive={activeTab === 'Affiliates and Subsidiaries'}
                onClick={() => handleTabClick('Affiliates and Subsidiaries')}
              />
              <TabItem
                title={t('Products', 'Products')}
                isActive={activeTab === 'Products'}
                onClick={() => handleTabClick('Products')}
              />
              <TabItem
                title={t('Services', 'Services')}
                isActive={activeTab === 'Services'}
                onClick={() => handleTabClick('Services')}
              />
              <TabItem
                title={t('Reviews', 'Reviews')}
                isActive={activeTab === 'Reviews'}
                onClick={() => handleTabClick('Reviews')}
              />
              {/* <TabItem
                title={t('Orders', 'Orders')}
                isActive={activeTab === 'Orders'}
                onClick={() => handleTabClick('Orders')}
              /> */}
            </div>
            {/* Content for Each Tab */}
            <div>
              {activeTab === 'Profile' && <Overview company={company} />}
              {activeTab === 'Affiliates and Subsidiaries' && (
                <AffiliatesAndSubsidiaries company={company} />
              )}
              {activeTab === 'Certifications' && <Certifications company={company} />}
              {activeTab === 'Products' && <Products company={company} />}
              {activeTab === 'Services' && <Services companyId={company?.companyId} />}
              {activeTab === 'Reviews' && <Reviews companyId={company?.companyId} />}
              {/* {activeTab === 'Orders' && <Orders />} */}
            </div>
          </div>
        </div>
        <div className="w-[20%]">
          <div className="border border-gray-300 bg-white shadow-sm rounded-lg px-8 py-8">
            <h2 className="mb-2 text-lg">Contact Supplier</h2>
            <hr />
            <div className="flex gap-4 items-center">
              <p className="text-gray-500 mt-2 mb-2 text-lg">Member Since: Aug 2022</p>
              <span>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/heart.svg`}
                  alt={'love'}
                  className={`w-6 h-6 object-cover rounded-t-lg`}
                />
              </span>
            </div>
            <hr />
            <div>
              <p className="text-xl mt-2">{company?.phoneNumber}</p>
              <p className="text-lg mt-1 mb-1">{company?.website}</p>
              <p>{company?.email}</p>
            </div>
            <div className="flex gap-6 mt-2 mb-2">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/X-icon.svg`}
                alt={'X'}
                className={`w-10 h-10 object-cover rounded-t-lg`}
              />
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/facebookBW.svg`}
                alt={'love'}
                className={`w-10 h-10 object-cover rounded-t-lg`}
              />
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/instagramBW.svg`}
                alt={'love'}
                className={`w-10 h-10 object-cover rounded-t-lg`}
              />
            </div>
            <hr />
            <div className="flex flex-col items-center mt-3 gap-4">
              <AppButton
                title={'Send Message'}
                onClick={() => toast.info('Send Message')}
                className="w-full bg-primary py-3 px-7 rounded-xl font-semibold"
              />
              <AppButton
                title={'Contact Supplier'}
                onClick={() => toast.info('Contact Supplier')}
                className="w-full border border-gray-400 py-3 px-5 rounded-xl font-semibold"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const TabItem: React.FC<TabItemProps> = ({ title, isActive, onClick }) => {
  return (
    <button
      className={`pb-2 text-lg font-medium focus:outline-none mb-8 ${
        isActive ? 'border-b-4 border-[#FFC726] font-bold' : 'text-gray-500'
      }`}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default Profile;
