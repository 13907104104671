import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FaTimes } from 'react-icons/fa';
import { createCommand, updateCommand } from '../../../../services/workflowServices';
import { toast } from 'react-toastify';
import { CommandData } from './CommandConfig';
import { useTranslation } from 'react-i18next';

interface CommandModalProps {
  isOpen: boolean;
  onClose: () => void;
  onEdit: () => void;
  workflowId: string;
  initialValues?: CommandData | null;
}

export enum WorkflowCommandType {
  Query,
  StoredProc,
  WebService,
  Url,
}

enum WorkflowActionType {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Canceled = 'Canceled',
  InProgress = 'InProgress',
}

const CommandModal: React.FC<CommandModalProps> = ({
  isOpen,
  onClose,
  workflowId,
  onEdit,
  initialValues,
}) => {
  const { t } = useTranslation();

  const formik = useFormik<CommandData>({
    initialValues: initialValues
      ? {
          ...initialValues,
          commandType:
            initialValues.commandType !== undefined
              ? WorkflowCommandType[initialValues.commandType as keyof typeof WorkflowCommandType]
              : '',
        }
      : {
          descriptions: '',
          queryCommand: '',
          className: '',
          methodName: '',
          commandType: '',
          actionType: '',
          workflowId,
          workflowCommandId: '',
        },
    validationSchema: Yup.object({
      descriptions: Yup.string().required(t('descriptionRequired', 'Description is required')),
      commandType: Yup.string().required(t('commandTypeRequired', 'Command Type is required')),
      actionType: Yup.string().required(t('actionTypeRequired', 'Action Type is required')),
      // className: Yup.string().required(
      //   t('classNameRequired', 'Class Name is required')
      // ),
      // methodName: Yup.string().required(
      //   t('methodNameRequired', 'Method Name is required')
      // ),
      queryCommand: Yup.string().required(t('queryCommandRequired', 'Query Command is required')),
    }),
    onSubmit: (values) => {
      if (initialValues) {
        values.commandType = values.commandType.toString();
        updateACommand(values);
      } else {
        const { workflowCommandId, ...payloadWithoutWorkflowCommandId } = values;
        createNewCommand(payloadWithoutWorkflowCommandId);
      }

      //onClose();
    },
  });

  const createNewCommand = async (values: any) => {
    try {
      const response: any = await createCommand(values);
      if (response.isSuccess) {
        toast.success(response?.data?.message);
        onEdit();
      } else {
        toast.error(response?.errorMessage);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };
  const updateACommand = async (values: any) => {
    try {
      const response: any = await updateCommand(values);
      if (response.isSuccess) {
        toast.success(response?.data?.message);
        onEdit();
      } else {
        toast.error(response?.errorMessage);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-2xl w-full max-w-3xl relative">
        {/* Close button */}
        <button className="absolute top-4 right-4 text-gray-600" onClick={onClose}>
          <FaTimes size={15} />
        </button>

        <h2 className="text-2xl mb-4">{t('addCommand', 'Add Command')}</h2>

        <form onSubmit={formik.handleSubmit}>
          {/* Description */}
          <div className="mb-4">
            <label htmlFor="descriptions" className="block mb-2">
              {t('description', 'Description')}
            </label>
            <textarea
              id="descriptions"
              name="descriptions"
              rows={3}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.descriptions}
              className="border p-2 w-full rounded-lg"
            />
            {formik.touched.descriptions && formik.errors.descriptions && (
              <div className="text-red-500">{formik.errors.descriptions}</div>
            )}
          </div>

          {/* CommandType and ActionType */}
          <div className="flex space-x-4 mb-4">
            <div className="flex-1">
              <label htmlFor="commandType" className="block mb-2">
                {t('commandType', 'Command Type')}
              </label>
              <select
                id="commandType"
                name="commandType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.commandType}
                className="border p-2 w-full rounded-lg"
              >
                <option value="" label={t('selectType', 'Select a type')} />
                <option value={WorkflowCommandType.Query} label={t('query', 'Query')}>
                  {t('query', 'Query')}
                </option>
                <option
                  value={WorkflowCommandType.StoredProc}
                  label={t('storedProcedure', 'Stored Procedure')}
                >
                  {t('storedProcedure', 'Stored Procedure')}
                </option>
                <option
                  value={WorkflowCommandType.WebService}
                  label={t('webService', 'WebService')}
                >
                  {t('webService', 'WebService')}
                </option>
                <option value={WorkflowCommandType.Url} label={t('url', 'Url')}>
                  {t('url', 'Url')}
                </option>
              </select>
              {formik.touched.commandType && formik.errors.commandType && (
                <div className="text-red-500">{formik.errors.commandType}</div>
              )}
            </div>

            <div className="flex-1">
              <label htmlFor="actionType" className="block mb-2">
                {t('actionType', 'Action Type')}
              </label>
              <select
                id="actionType"
                name="actionType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.actionType}
                className="border p-2 w-full rounded-lg"
              >
                <option value="" label={t('selectType', 'Select a type')} />
                <option value={WorkflowActionType.Approved} label={t('approved', 'Approved')}>
                  {t('approved', 'Approved')}
                </option>
                <option value={WorkflowActionType.Rejected} label={t('rejected', 'Rejected')}>
                  {t('rejected', 'Rejected')}
                </option>
                <option value={WorkflowActionType.Canceled} label={t('canceled', 'Canceled')}>
                  {t('canceled', 'Canceled')}
                </option>
                <option value={WorkflowActionType.InProgress} label={t('inProgress', 'InProgress')}>
                  {t('inProgress', 'InProgress')}
                </option>
              </select>
              {formik.touched.actionType && formik.errors.actionType && (
                <div className="text-red-500">{formik.errors.actionType}</div>
              )}
            </div>
          </div>

          {/* ClassName and MethodName */}
          <div className="flex space-x-4 mb-4">
            <div className="flex-1">
              <label htmlFor="className" className="block mb-2">
                {t('fullClassName', 'Full Class Name')}
              </label>
              <input
                id="className"
                name="className"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.className}
                className="border p-2 w-full rounded-lg"
              />
              {formik.touched.className && formik.errors.className && (
                <div className="text-red-500">{formik.errors.className}</div>
              )}
            </div>

            <div className="flex-1">
              <label htmlFor="methodName" className="block mb-2">
                {t('methodName', 'Method Name')}
              </label>
              <input
                id="methodName"
                name="methodName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.methodName}
                className="border p-2 w-full rounded-lg"
              />
              {formik.touched.methodName && formik.errors.methodName && (
                <div className="text-red-500">{formik.errors.methodName}</div>
              )}
            </div>
          </div>

          {/* Command Query */}
          <div className="mb-4">
            <label htmlFor="queryCommand" className="block mb-2">
              {t('commandQuery', 'Command Query')}
            </label>
            <textarea
              id="queryCommand"
              name="queryCommand"
              rows={3}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.queryCommand}
              className="border p-2 w-full rounded-lg"
            />
            {formik.touched.queryCommand && formik.errors.queryCommand && (
              <div className="text-red-500">{formik.errors.queryCommand}</div>
            )}
          </div>

          {/* Save Button */}
          <div className="flex justify-end">
            <button
              type="submit"
              className="bg-green-500 text-white px-12 h-12 py-2 rounded-lg font-semibold"
            >
              {t('saveCommand', 'Save Command')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CommandModal;
