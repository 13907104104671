import React, { useState } from 'react';
import UserProfile from './UserProfile/UserProfile';
import PasswordSecurity from './PasswordSecurity/PasswordSecurity';
import PopUpNotification from './PopUpNotification/PopUpNotification';
import Language from './Language/Language';
import { FaChevronRight } from 'react-icons/fa';

const Settings: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('My Profile');

  const renderContent = () => {
    switch (selectedTab) {
      case 'My Profile':
        return <UserProfile />;
      case 'Password and Security':
        return <PasswordSecurity />;
      case 'Push Notifications':
        return <PopUpNotification />;
      case 'Language':
        return <Language />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-gray-50 p-6">
      <h1 className="text-xl font-semibold">Settings</h1>
      <p className="text-gray-600 text-sm mb-6">
        Manage your preferences, securities and account details to tailor your experience.
      </p>
      <div className="flex gap-4">
        {/* Sidebar */}
        <div className="flex flex-col w-[35%] shadow-sm bg-white rounded-lg px-4 py-5 gap-4 ">
          <div
            className={`flex gap-2 items-center p-3 border rounded-lg cursor-pointer shadow-sm bg-white ${
              selectedTab === 'My Profile' ? 'border-amber-400' : 'hover:bg-gray-100'
            }`}
            onClick={() => setSelectedTab('My Profile')}
          >
            <div className="bg-amber-100 rounded-full px-2 py-2">
              <img src={`${process.env.PUBLIC_URL}/assets/icons/outline/user.svg`} alt="profile" />
            </div>
            <div>
              <p className="font-semibold">My Profile</p>
              <p className="text-gray-500 text-sm">
                Provide personal details and how we can reach you
              </p>
            </div>
            <FaChevronRight />
          </div>
          <div
            className={`flex gap-2 items-center p-3 border rounded-lg cursor-pointer shadow-sm bg-white ${
              selectedTab === 'Password and Security' ? 'border-amber-400' : 'hover:bg-gray-100'
            }`}
            onClick={() => setSelectedTab('Password and Security')}
          >
            <div className="bg-amber-100 rounded-full px-2 py-2">
              <img src={`${process.env.PUBLIC_URL}/assets/icons/outline/lock.svg`} alt="lock" />
            </div>
            <div>
              <p className="font-semibold">Password and Security</p>
              <p className="text-gray-500 text-sm">Update your password and secure your account</p>
            </div>
            <FaChevronRight />
          </div>
          <div
            className={`flex gap-2 items-center p-3 border rounded-lg cursor-pointer shadow-sm bg-white ${
              selectedTab === 'Push Notifications' ? 'border-amber-400' : 'hover:bg-gray-100'
            }`}
            onClick={() => setSelectedTab('Push Notifications')}
          >
            <div className="bg-amber-100 rounded-full px-2 py-2">
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/outline/receipt-edit.svg`}
                alt="receipt"
              />
            </div>
            <div>
              <p className="font-semibold">Push Notifications</p>
              <p className="text-gray-500 text-sm">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              </p>
            </div>
            <FaChevronRight />
          </div>
          <div
            className={`flex gap-2 items-center p-3 border rounded-lg cursor-pointer shadow-sm bg-white ${
              selectedTab === 'Language' ? 'border-amber-400' : 'hover:bg-gray-100'
            }`}
            onClick={() => setSelectedTab('Language')}
          >
            <div className="bg-amber-100 rounded-full px-2 py-2">
              <img src={`${process.env.PUBLIC_URL}/assets/icons/outline/key.svg`} alt="key" />
            </div>
            <div>
              <p className="font-semibold">Language</p>
              <p className="text-gray-500 text-sm">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              </p>
            </div>
            <FaChevronRight />
          </div>
        </div>
        {/* Content */}
        <div className="w-[60%] ">{renderContent()}</div>
      </div>
    </div>
  );
};

export default Settings;
