import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import AppButton from '../AppButton/AppButton';
import './Otp.css';
import { maskEmail } from '../../utils/helper';
import { OtpData } from '../../services/AccountServices';
import { ConfirmEmail } from '../../services/AccountServices';
import { ResendOtp } from '../../services/AccountServices';
import Navbar from '../../layouts/NavBar/Navbar';
import { FaArrowLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';

Modal.setAppElement('#root');

const Otp: React.FC = () => {
  const [otpValues, setOtpValues] = useState(new Array(6).fill(''));
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [otpSent, setOtpSent] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isForgetPassword = location.state?.isForgetPassword;
  const email = location.state?.email;
  const maskedEmail = maskEmail(email);

  // Check if all OTP inputs are filled
  const isOtpComplete = otpValues.every((value) => value !== '');

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (countdown !== null && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      setCountdown(null);
      setOtpSent(false); // Reset OTP sent state after countdown finishes
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [countdown]);

  useEffect(() => {
    if (!isForgetPassword) {
      setOtpSent(true);
      setCountdown(185); // Start a 3-minute 5 secs countdown
    }
  }, []);

  const onSubmitOtp = () => {
    try {
      setIsLoading(true);
      const otpString = otpValues.join('');
      if (isForgetPassword) {
        handleResetPassword(otpString);
      } else {
        handleSignUp(otpString);
      }
    } catch {
      // console.log(error);
      setIsLoading(false);
    }
  };

  const resendOtp = async () => {
    try {
      setIsLoading(true);
      const otpData: { email: string } = {
        email,
      };
      const response = await ResendOtp(otpData);
      if (response.isSuccess) {
        setOtpSent(true);
        setCountdown(185); // Start a 3-minute 5 secs countdown
      }
    } catch {
      // console.log(error);
    }
    setIsLoading(false);
  };

  const handleResetPassword = (otpString: string) => {
    navigate('/reset-password', { state: { email, token: otpString } });
  };

  const handleSignUp = async (otpString: string) => {
    const otpData: OtpData = {
      code: otpString,
      email,
    };
    const response = await ConfirmEmail(otpData);
    if (response?.isSuccess) {
      setIsOpen(false);

      const messageData: string = response?.data?.message;
      // Navigate to the success page
      navigate('/success-card', {
        state: { route: '/login', message: messageData },
      });
    } else {
      toast.error(response?.errorMessage);
    }
    setIsLoading(false);
  };

  const handleChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '' || /^[0-9]$/.test(value)) {
      // Replace the value at the current index
      setOtpValues(otpValues.map((data, i) => (i === index ? value : data)));

      // Move to the next input if the value is entered
      if (value && e.target.nextSibling) {
        (e.target.nextSibling as HTMLInputElement).focus();
      }
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      if (otpValues[index] === '') {
        // Move to the previous input if the current one is empty
        if (e.currentTarget.previousSibling) {
          (e.currentTarget.previousSibling as HTMLInputElement).focus();
        }
      }
      setOtpValues(otpValues.map((data, i) => (i === index ? '' : data)));
    }
  };

  return (
    <div>
      <Navbar />

      <div className="flex justify-center items-start  min-h-screen pt-[108px] bg-[#f9fafb]">
        <form onSubmit={(e) => e.preventDefault()} className="otp-card flex flex-col">
          <h4 className="text-3xl font-bold mb-8">{t('verifyEmail', 'Verify Email')}</h4>

          <p className="text-sm text-[#101828]">
            {t('insertOtpCode', 'Insert your one-time code sent to')} {maskedEmail}
          </p>

          <div className="otp-input-container">
            {otpValues.map((data, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={data}
                onChange={(e) => handleChange(index, e)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                className="otp-input"
              />
            ))}
          </div>

          {!isForgetPassword && (
            <div className="flex justify-center items-center mt-4">
              {otpSent ? (
                <p className="otp-sent">
                  {t('codeExpires', 'Your code expires in')}{' '}
                  {countdown !== null
                    ? ` (${Math.floor(countdown / 60)}:${countdown % 60 < 10 ? '0' : ''}${countdown % 60})`
                    : ''}
                </p>
              ) : (
                <p
                  onClick={resendOtp}
                  className={`request-new-code ${countdown !== null ? 'text-[#161616]' : ''}`}
                  style={{
                    cursor: countdown === null ? 'pointer' : 'not-allowed',
                  }}
                  role="link"
                >
                  {countdown !== null
                    ? `${t('requestNewCode', 'Request a new code')} (${countdown}s)`
                    : `${t('requestNewCode', 'Request a new code')}`}
                </p>
              )}
            </div>
          )}

          <div className="flex justify-center mt-6">
            <AppButton
              className={`w-full font-semibold py-4 rounded-md ${
                isOtpComplete ? 'bg-[#FFC726] text-black' : 'bg-[#F5F5F5] text-[#C6C6C6]'
              }`}
              title={t('confirm', 'Confirm')}
              onClick={onSubmitOtp}
              disabled={!isOtpComplete || isLoading}
            />
          </div>
          {/* Forgot Password */}
          <div className="flex items-center gap-2 mt-6 justify-center">
            <FaArrowLeft size={10} />
            <AppButton
              className="text-base border-b-2 border-black"
              title={t('backToLogin', 'Back to Login')}
              onClick={() => {
                navigate('/login');
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Otp;
