import axiosInstance from '../axiosInstance';
import { objToQueryParams } from '../utils/helper';
import { Endpoint } from './endpoint/endpoint';

/**
 * Uploads an image to the server.
 *
 * @param data - The image data to be uploaded.
 * @returns The response data from the server.
 * @throws Will throw an error if the request fails.
 */
export const uploadImage = async (data: any) => {
  const url = Endpoint.edms.imageUpload;
  try {
    const response = await axiosInstance.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch {
    // console.log(error);
  }
};

/**
 * Deletes an image from the server.
 *
 * @param imageId - The ID of the image to be deleted.
 * @returns The response data from the server.
 * @throws Will throw an error if the request fails.
 */
export const deleteImage = async (imageId: string) => {
  const url = Endpoint.edms.delete(imageId);
  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

/**
 * Uploads a document to the server.
 *
 * @param data - The document data to be uploaded.
 * @returns The response data from the server.
 * @throws Will throw an error if the request fails.
 */
export const uploadDocument = async (data: any) => {
  const url = Endpoint.edms.uploadDocument;
  try {
    const response = await axiosInstance.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch {
    // console.log(error);
  }
};

/**
 * Retrieves all documents from the server.
 *
 * @returns The response data from the server.
 * @throws Will throw an error if the request fails.
 */
export const getDocuments = async (queryParams: any) => {
  const params = objToQueryParams(queryParams);
  const url = Endpoint.edms.getDocuments + params;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

/**
 * Retrieves supplier documents from the server.
 *
 * @returns The response data from the server.
 * @throws Will throw an error if the request fails.
 */
export const getSupplierDocuments = async (queryParams: any) => {
  const params = objToQueryParams(queryParams);
  const url = Endpoint.edms.getSupplierDocuments + params;
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch {
    // console.log(error);
  }
};

/**
 * View any document from the server by documentId.
 *
 * @returns The response data from the server.
 * @throws Will throw an error if the request fails.
 */
export const viewDocuments = async (queryParams: any) => {
  const params = objToQueryParams(queryParams);
  const url = Endpoint.edms.view + params;
  try {
    const response = await axiosInstance.get(url, {
      headers: {
        'Content-Type': 'application/pdf',
      },
    });
    return response.data;
  } catch {
    // console.log(error);
  }
};

/**
 * Downloads a document from the server.
 *
 * @param documentId - The ID of the document to be downloaded.
 * @returns The response data from the server.
 * @throws Will throw an error if the request fails.
 */
export const downloadDocument = async (queryParams: any) => {
  const params = objToQueryParams(queryParams);
  const url = Endpoint.edms.view + params;
  try {
    const response = await axiosInstance.get(url, {
      responseType: 'blob',
    });
    return response.data;
  } catch {
    // console.log(error);
  }
};

/**
 * Deletes a document from the server.
 *
 * @param documentId - The ID of the document to be deleted.
 * @returns The response data from the server.
 * @throws Will throw an error if the request fails.
 */
export const deleteDocument = async (data: any) => {
  const url = Endpoint.edms.deleteDocument;
  try {
    const response = await axiosInstance.delete(url, {
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch {
    // console.log(error);
  }
};
