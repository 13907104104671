import React, { act, useState } from 'react';
import { ArrowLeft, FileText } from 'lucide-react';
import CreateProcurementForm from '../../CreateProcurementForm';

import { useNavigate } from 'react-router-dom';
import { UnpublishAProcurement, getAProcurement } from '../../../../services/procurementService';
import { ProcurementProject } from '../../../../types/procurement-types';
import { toast } from 'react-toastify';

interface ProcurementProjectDetailsProps {
  project: ProcurementProject;
  activeStatus: string;
  onClose: () => void;
}

const ProcurementProjectDetails: React.FC<ProcurementProjectDetailsProps> = ({
  project,
  activeStatus,
  onClose,
}) => {
  const navigate = useNavigate();

  const formatDate = (dateString: string | null) => {
    if (!dateString) return <div className="text-green-500">In Review</div>;
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(date);
  };

  const handleEditClick = async () => {
    navigate(`/procurement/edit/${project.serviceInformationId}`);
    onClose();
  };

  const handleUnpublish = async () => {
    try {
      const response = await UnpublishAProcurement(project.serviceInformationId);

      if (response.isSuccess) {
        onClose();
        navigate('/procurement', { state: { refresh: true } });
      }
    } catch {
      //catch the error
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900/50">
      <div className="flex h-5/6 w-5/6 flex-col rounded-2xl bg-white shadow-lg md:w-4/6 xl:w-3/6">
        <div className="flex-1 overflow-y-auto px-8 py-6">
          <button onClick={onClose} className="mb-6 flex items-center gap-2 text-gray-600">
            <ArrowLeft size={20} />
            Back
          </button>

          <h1 className="mb-8 text-2xl font-bold">{project.title}</h1>

          <div className="mb-8 flex flex-col gap-6 xl:flex-row">
            <div className="flex flex-col gap-4">
              <div className="flex items-start">
                <span className="w-36 text-sm text-gray-500">Submission date:</span>
                <span>September 13, 2024</span>
              </div>
              <div className="flex items-start">
                <span className="w-36 text-sm text-gray-500">Approved date:</span>
                <span>approvedDate</span>
              </div>
              <div className="flex items-start">
                <span className="w-36 text-sm text-gray-500">Bidding start date:</span>
                <span>{formatDate(project.bidStartDate?.toString() || null)}</span>
              </div>
              <div className="flex items-start">
                <span className="w-36 text-sm text-gray-500">Bidding end date:</span>
                <span>{formatDate(project.bidEndDate?.toString() || null)}</span>
              </div>
            </div>

            {/* Procurement Activity Section */}
            <div className="w-64 rounded-lg border border-green-200 bg-green-50 p-4 xl:ml-16">
              <h3 className="mb-4 font-semibold text-gray-700">Procurement Activity</h3>
              <div className="space-y-2">
                <div className="flex justify-between text-gray-600">
                  <p>Interests:</p>
                  <p>112 suppliers</p>
                </div>
                <div className="flex justify-between text-gray-600">
                  <p>Bidding:</p>
                  <p>18 suppliers</p>
                </div>
                <div className="flex justify-between text-gray-600">
                  <p>Contract:</p>
                  <p>0 suppliers</p>
                </div>
              </div>
            </div>
          </div>

          <section className="space-y-6">
            <div>
              <h2 className="mb-2 font-semibold">Description:</h2>
              <p className="text-gray-600">{project.description}</p>
            </div>
            <div>
              <h2 className="mb-2 font-semibold">Prerequisites or Submission requirements</h2>
              <ul className="list-disc space-y-1 pl-5 text-gray-600">
                {project.prerequisite.split('\n').map((item, index) => (
                  <li key={index}>{item.trim()}</li>
                ))}
              </ul>
            </div>
            <div>
              <h2 className="mb-2 font-semibold">Bid requirements</h2>
              <ul className="list-disc pl-5 text-gray-600">
                <li>{project.bidRequirement}</li>
              </ul>
            </div>
            <div>
              <h2 className="mb-2 font-semibold">Additional information</h2>
              <p className="text-gray-600">{project.additionalInformation}</p>
            </div>
            <div>
              <h2 className="mb-2 font-semibold">Supporting document</h2>
              <div className="flex cursor-pointer items-center gap-2 bg-gray-50 p-2 text-blue-600 hover:text-blue-700">
                <FileText size={20} />
                <span>Pipeline Inspection</span>
              </div>
            </div>
            {/* <div>
              <h2 className="font-semibold mb-2">Tags</h2>
              <div className="flex flex-wrap gap-2">
                {project.tags.map((tag, index) => (
                  <span
                    key={index}
                    className="bg-gray-100 text-gray-700 px-3 py-1 rounded-full text-sm"
                  >
                    {tag.tagName}
                  </span>
                ))}
              </div>
            </div> */}
          </section>
        </div>

        {/* <div className="border-t border-gray-200 p-4 rounded-2xl bg-white"> */}
        <div className="mb-12 mr-7 flex justify-end gap-3">
          <button
            className={`rounded-lg border px-6 py-2.5 font-medium ${
              activeStatus === 'Approved'
                ? 'cursor-not-allowed bg-gray-50 text-gray-400'
                : 'text-gray-700 hover:bg-gray-50'
            }`}
            onClick={handleUnpublish}
            disabled={
              activeStatus === 'Approved' ||
              activeStatus === 'Rejected' ||
              activeStatus === 'Processing'
            }
          >
            Unpublish
          </button>
          <button
            className={`rounded-lg px-6 py-2.5 font-medium ${
              activeStatus === 'Approved'
                ? 'cursor-not-allowed bg-yellow-200 text-gray-500'
                : 'bg-yellow-400 text-black hover:bg-yellow-500'
            }`}
            onClick={handleEditClick}
            disabled={
              activeStatus === 'Approved' ||
              activeStatus === 'Rejected' ||
              activeStatus === 'Processing'
            }
          >
            Edit
          </button>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ProcurementProjectDetails;
