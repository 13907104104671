import { ArrowLeft, XIcon } from 'lucide-react';
import { useState } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const RequestAccessModal: React.FC<ModalProps> = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-start justify-center bg-black bg-opacity-50">
      <div className="mt-[20vh] w-full max-w-md rounded-[30px] bg-white p-8">
        <div className="mb-12 flex justify-end">
          <button
            className="rounded-full border-[1.5px] border-[#101828] p-0.5 hover:bg-gray-200"
            onClick={onClose}
          >
            <XIcon className="h-3 w-3 text-[#1C1B1F]" />
          </button>
        </div>

        <h2 className="mb-4 text-center text-3xl font-semibold text-[#101828]">Request Access?</h2>
        <p className="mb-11 text-base text-[#667085]">
          Request to access detailed project information. Once accepted, you'll be able to view more
          details and submit your expression of interest.
        </p>

        <button
          className="w-full rounded-lg bg-[#FFC726] px-5 py-3 text-base text-[#161616]"
          onClick={onConfirm}
        >
          Send Request
        </button>
      </div>
    </div>
  );
};

export const RequestProposalModal: React.FC<ModalProps> = ({ isOpen, onClose, onConfirm }) => {
  const [isFavorite, setIsFavorite] = useState(false);

  const handleFavoriteItem = () => {
    setIsFavorite((prev) => !prev);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-[80vw] rounded-[30px] bg-white px-6 pb-[70px] pt-[50px] md:px-[61px]">
        <button onClick={onClose} className="mb-6 flex items-center gap-2 text-gray-600">
          <ArrowLeft size={20} />
          Back
        </button>

        <div className="h-[60vh] overflow-auto">
          <h2 className="mb-4 text-[40px] font-medium text-[#161616]">
            Pipeline Inspection and Maintenance
          </h2>

          <div className="flex flex-col-reverse justify-between gap-y-3 md:flex-row md:items-center">
            <div className="flex items-center gap-x-6">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/ShellPix.svg`}
                alt="vendor icon"
                className="h-[38px] w-[38px] rounded-full"
              />

              <div className="flex flex-col items-start gap-y-2">
                <span className="font-semibold text-[#101828] underline">
                  Green Earth Consultants Ltd
                </span>

                <span className="rounded-[30px] bg-[#42BE65] px-2.5 py-1 text-[10px] font-medium text-white">
                  Verified Operator
                </span>
              </div>
            </div>

            <button className="ml-auto cursor-pointer" onClick={handleFavoriteItem}>
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/outline/${
                  isFavorite ? 'favourite-filled.svg' : 'favourite.svg'
                }`}
                alt="favorite-icon"
              />
            </button>
          </div>

          <p className="mt-7 text-sm text-[#525252]">Posted 24 minutes ago</p>

          <hr className="my-7 border border-[#E5E5E5]" />

          <div className="flex flex-col justify-between gap-y-8 xl:flex-row xl:items-center">
            <div>
              <h3 className="mb-2 text-base font-semibold text-[#161616]">About Project Owner</h3>
              <ul className="flex list-none flex-col gap-y-4 text-sm text-[#667085]">
                <li>Project verified</li>
                <li>United States</li>
                <li>
                  <span className="font-medium underline">16 projects</span> posted
                </li>
                <li>Member since July 12, 2024</li>
              </ul>
            </div>

            <div className="flex flex-col gap-y-4 text-sm text-[#667085]">
              <div>
                <span>Bidding start date:</span>
                <span className="ml-[30px] font-medium text-[#161616]">October 30, 2024</span>
              </div>

              <div>
                <span>Bidding start date:</span>
                <span className="ml-[30px] font-medium text-[#161616]">October 30, 2024</span>
              </div>
            </div>

            <div className="rounded-lg border border-[#42BE65] bg-[#42be650d] p-2.5">
              <span className="mb-2 flex text-sm">Procurement Activity</span>

              <ul className="flex list-none flex-col gap-y-4 text-sm text-[#667085]">
                <li>Interests: 112 suppliers</li>
                <li>Bidding: 18 suppliers</li>
                <li>Contract: 0 suppliers</li>
              </ul>
            </div>
          </div>

          <hr className="mt-7 border border-[#E5E5E5]" />

          <div className="py-[22px]">
            <h3 className="mb-2 text-base font-semibold text-[#161616]">Description:</h3>
            <p className="text-sm text-[#667085]">
              A leading oil and gas company is looking for qualified contractors with extensive
              experience in pipeline inspection and maintenance. The project involves the routine
              inspection, assessment, and maintenance of critical pipeline infrastructure across
              multiple states in North America. Contractors will be responsible for conducting
              thorough inspections to identify potential risks, such as corrosion, leaks, or
              structural weaknesses, using advanced inspection technologies including smart pigs,
              ultrasonic testing, and drone surveillance. In addition to inspections, the scope
              includes performing necessary maintenance tasks to ensure the pipelines' integrity and
              compliance with safety and environmental regulations. Contractors should be familiar
              with both above-ground and underground pipeline systems and have a proven track record
              of working in diverse geographical conditions, from remote rural areas to densely
              populated urban regions.
            </p>
          </div>

          <hr className="border border-[#E5E5E5]" />

          <div className="py-[22px]">
            <h3 className="mb-2 text-base font-semibold text-[#161616]">
              Prerequisites or Submission requirements
            </h3>

            <ul className="list-disc pl-6 text-sm text-[#667085]">
              <li>Requires bidding process</li>
              <li>Available to start immediately</li>
              <li>Must meet the project description</li>
              <li>Company with xyz capacity preferable an enterprise or joint venture</li>
              <li>Detail reporting</li>
            </ul>
          </div>

          <hr className="border border-[#E5E5E5]" />

          <div className="py-[22px]">
            <h3 className="mb-2 text-base font-semibold text-[#161616]">Bid requirements</h3>

            <ul className="list-disc pl-6 text-sm text-[#667085]">
              <li>Company registration document</li>
              <li>Licenses and Permits</li>
            </ul>
          </div>

          <hr className="border border-[#E5E5E5]" />

          <div className="py-[22px]">
            <h3 className="mb-2 text-base font-semibold text-[#161616]">Additional information</h3>
            <p className="text-sm text-[#667085]">
              Contractors should demonstrate strong project management capabilities and a proven
              track record in diverse geographical conditions. All work must adhere to the highest
              industry standards for safety and quality.
            </p>
          </div>

          <div className="py-[22px]">
            <h3 className="mb-2 text-base font-semibold text-[#161616]">Supporting document</h3>
            <div className="flex items-center rounded-[5px] bg-[#F5F5F5] py-2.5">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/dashicons_pdf.svg`}
                alt="PDF icon"
              />
              <span className="ml-[30px]">Pipeline inspection</span>
            </div>
          </div>

          <div className="py-[22px]">
            <h3 className="mb-2 text-base font-semibold text-[#161616]">Tags</h3>

            <div className="flex space-x-4 overflow-x-auto py-2">
              {['Maintenance', 'Pipeline Inspection', 'Contract', 'Equipment', 'Oil and Gas'].map(
                (tag) => (
                  <span
                    key={tag}
                    className="whitespace-nowrap rounded-full border border-[#E9EFF2] bg-[#FAFDFF] px-3 py-4 text-[#292D32]"
                  >
                    {tag}
                  </span>
                )
              )}
            </div>
          </div>

          <div className="mt-[61px] flex justify-end">
            <button
              className="rounded-lg bg-[#FFC726] px-5 py-3 text-base font-semibold text-[#101828]"
              onClick={onConfirm}
            >
              Request For Proposal (RFP)
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
