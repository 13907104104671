import { useState } from 'react';
import { SupplierTender } from '../../../../types/procurement-types';
import moment from 'moment';

interface ProcurementCardProps {
  procurement: SupplierTender;
  onRequestAccess: (serviceInformationId: string) => void;
}

export const ProcurementCard: React.FC<ProcurementCardProps> = ({
  procurement,
  onRequestAccess: _onRequestAccess,
}) => {
  const [isFavorite, setIsFavorite] = useState(false);

  const handleFavoriteItem = () => {
    setIsFavorite((prev) => !prev);
  };

  const formattedCreatedDate = moment(procurement.createdDate).fromNow();

  return (
    <div className="cursor-pointer rounded-xl border border-gray-200 bg-white px-4 py-3 shadow-sm">
      <div className="mb-4 flex items-center justify-between">
        <span className="rounded-[30px] border border-[#E5E5E5] px-2 py-1 text-xs text-[#8D8D8D]">
          {formattedCreatedDate}
        </span>

        <button className="cursor-pointer" onClick={handleFavoriteItem}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/outline/${
              isFavorite ? 'favourite-filled.svg' : 'favourite.svg'
            }`}
            alt="favorite-icon"
          />
        </button>
      </div>

      <h3 className="mb-3 line-clamp-2 min-h-11 text-base font-medium text-[#161616]">
        {procurement.title}
      </h3>

      <p className="mb-6 line-clamp-3 text-sm text-[#667085]">{procurement.description}</p>

      <button
        className={`flex w-full justify-center rounded-lg border px-3 py-2.5 text-base font-medium ${
          procurement.viewAcess && procurement.allowProposalRequest
            ? 'border-[#24A148] text-[#24A148]'
            : procurement.viewAcess && !procurement.allowProposalRequest
              ? 'cursor-not-allowed border-gray-200 text-gray-400'
              : 'border-[#E5E5E5] text-[#101828]'
        }`}
        onClick={() => _onRequestAccess(procurement.serviceInformationId)}
        disabled={!!procurement.viewAcess && !procurement.allowProposalRequest}
      >
        {procurement.viewAcess && procurement.allowProposalRequest
          ? ' View Details'
          : procurement.viewAcess && !procurement.allowProposalRequest
            ? 'Pending'
            : 'Request to View'}
      </button>
    </div>
  );
};
