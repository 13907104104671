import { useEffect } from 'react';
import SupplierProcurementComponent from './components/SupplierProcurement';
import RegulatorProcurementComponent from './components/RegulatorProcurement';

const ProcurementHomepage = () => {
  const isSupplier = localStorage.getItem('supplierId');

  useEffect(() => {
    console.log('isSupplier: ', isSupplier);
  }, [isSupplier]);

  return <>{isSupplier ? <SupplierProcurementComponent /> : <RegulatorProcurementComponent />}</>;
};

export default ProcurementHomepage;
