import React from 'react';
import { ProfileProps } from '../../../types/interfaces';

const Overview: React.FC<ProfileProps> = ({ company }) => {
  return (
    <>
      <div className="border border-gray-300 bg-white shadow-sm rounded-lg px-6 py-4">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-4">
          <div>
            <h2 className="text-2xl font-medium">Overview</h2>
          </div>
          <div className="flex gap-3">
            <p className="underline text-xl">Download Report</p>
            <p className="text-xl text-green-600">Verified ANP Regulators</p>
          </div>
        </div>
        <hr />

        {/* Content Section */}
        <div className="grid grid-cols-2 gap-y-4 px-20 mt-4">
          <div className="">
            <div className="mb-5">
              <h2 className="font-medium text-gray-500 mb-2 text-xl">Type of Supplier</h2>
              <p className="text-lg">{company?.typeOfSupplier || '-'}</p>
            </div>
            <div className="mb-5">
              <h2 className="font-medium text-gray-500 mb-2 text-xl">Country</h2>
              <p className="text-lg">{company?.country || '-'}</p>
            </div>
            <div className="mb-5">
              <h2 className="font-medium text-gray-500 mb-2 text-xl">Commercial Reg. No</h2>
              <p className="text-lg">{company?.registrationNumber || '-'}</p>
            </div>
            <div className="mb-5">
              <h2 className="font-medium text-gray-500 mb-2 text-xl">Office Address Line 1</h2>
              <p className="text-lg">{company?.streetName || '-'}</p>
            </div>
            <div className="mb-5">
              <h2 className="font-medium text-gray-500 mb-2 text-xl">Post Administrative/City</h2>
              <p className="text-lg">{company?.city || '-'}</p>
            </div>
            <div className="mb-5">
              <h2 className="font-medium text-gray-500 mb-2 text-xl">Accepted Languages</h2>
              <p className="text-lg">{company?.languages || '-'}</p>
            </div>
          </div>
          <div className="">
            <div className="mb-5">
              <h2 className="font-medium text-gray-500 mb-2 text-xl">Date of Establishment</h2>
              <p className="text-lg">
                {company?.dateOfEstablishment
                  ? new Date(
                      new Date(company?.dateOfEstablishment).getTime() -
                        new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .split('T')[0]
                  : '-'}
              </p>
            </div>
            <div className="mb-5">
              <h2 className="font-medium text-gray-500 mb-2 text-xl">Business Name</h2>
              <p className="text-lg">{company?.businessName || '-'}</p>
            </div>
            <div className="mb-5">
              <h2 className="font-medium text-gray-500 mb-2 text-xl">Type of Business</h2>
              <p className="text-lg">{company?.businessType || '-'}</p>
            </div>
            <div className="mb-5">
              <h2 className="font-medium text-gray-500 mb-2 text-xl">Municipality/Region</h2>
              <p className="text-lg">{company?.region || '-'}</p>
            </div>
            <div className="mb-5">
              <h2 className="font-medium text-gray-500 mb-2 text-xl">Suburb or Town</h2>
              <p className="text-gray-800 text-lg">{company?.town || '-'}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
