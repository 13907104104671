import React, { useState } from 'react';
import { Search } from 'lucide-react';
import { SupplierTender } from '../../../../types/procurement-types';
import { ProcurementCard } from './ProcurementCardComponent';
import { toast } from 'react-toastify';
import { getSupplierTenders, requestTenderAccess } from '../../../../services/procurementService';
import { RequestAccessModal, RequestProposalModal } from './ModalComponent';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

const TenderOpportunitiesComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialStatus = searchParams.get('status') || 'Top Matches';

  const [searchTerm, setSearchTerm] = useState('');
  const [activeStatus, setActiveStatus] = useState(initialStatus);

  const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);
  const [isProposalModalOpen, setIsProposalModalOpen] = useState(false);
  const [selectedServiceInformationId, setSelectedServiceInformationId] = useState<string | null>(
    null
  );

  const supplierId = localStorage.getItem('supplierId') || '';

  const statusMap = ['Top Matches', 'Saved', 'Approved', 'Denied'];

  const filterProcurementsByStatus = (data: SupplierTender[]) => {
    switch (activeStatus) {
      case 'Top Matches':
        return data; // Show all procurements
      case 'Saved':
        return data.filter((item) => item.viewAcess === false);
      case 'Approved':
        return data.filter((item) => item.viewAcess === true && item.allowProposalRequest === true);
      case 'Denied':
        return []; // Denied tenders aren't displayed
      default:
        return data;
    }
  };

  const {
    data: allProcurements = [],
    isLoading,
    refetch,
    isError,
  } = useQuery({
    queryKey: ['supplierTenders', supplierId],
    queryFn: async () => {
      if (!supplierId) throw new Error('Supplier ID is missing.');
      try {
        const response = await getSupplierTenders(supplierId);
        return response?.data || [];
      } catch (error) {
        toast.error('Failed to fetch procurements. Please try again later.');
        throw error;
      }
    },
    enabled: !!supplierId, // Fetch only if supplierId exists
  });

  const procurements = filterProcurementsByStatus(allProcurements);

  const handleStatusChange = (status: string) => {
    setActiveStatus(status);
    setSearchParams({ tab: 'Tender Opportunities', status });
    // queryClient.invalidateQueries({ queryKey: ['procurements', status] });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleRequestAccessOrProposals = (serviceInformationId: string) => {
    const selectedProcurement = procurements.find(
      (item) => item.serviceInformationId === serviceInformationId
    );

    setSelectedServiceInformationId(serviceInformationId);

    if (selectedProcurement?.viewAcess) {
      setIsProposalModalOpen(true);
    } else {
      setIsAccessModalOpen(true);
    }
  };

  const handleAccessModalClose = () => {
    setIsAccessModalOpen(false);
    setSelectedServiceInformationId(null);
    refetch();
  };

  const handleAccessModalConfirm = async () => {
    if (selectedServiceInformationId) {
      try {
        const response = await requestTenderAccess({
          serviceInformationId: selectedServiceInformationId,
          supplierId: supplierId,
          isAccessRequest: true,
        });

        if (response.isSuccess) {
          toast.success('Request sent successfully!');
        }
      } catch (error) {
        console.error('Failed to request access:', error);
        toast.error('Failed to request access. Please try again later.');
      }
    } else {
      toast.error('No service information selected.');
    }

    handleAccessModalClose();
  };

  const handleProposalModalClose = () => {
    setIsProposalModalOpen(false);
    setSelectedServiceInformationId(null);
  };

  const handleProposalModalConfirm = () => {
    if (selectedServiceInformationId) {
      console.log(`Proposal sent for Service Information ID: ${selectedServiceInformationId}`);
      toast.success('Proposal sent successfully!');
    } else {
      toast.error('No service information selected.');
    }
    setIsProposalModalOpen(false);
  };

  return (
    <div className="min-h-[75vh] rounded-2xl border border-[#E5E5E5] bg-white p-8">
      <div className="relative mb-6">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full rounded-lg border border-[#D0D5DD] bg-white px-3.5 py-2.5 pl-12 text-base text-gray-800"
        />
        <Search
          className="absolute left-4 top-1/2 -translate-y-1/2 transform text-gray-500"
          size={20}
        />
      </div>

      <>
        <div className="mb-6 flex space-x-10 border-b border-[#E5E5E5] pb-4">
          {statusMap.map((status) => (
            <button
              key={status}
              className={`rounded-full px-3.5 py-2.5 text-base ${
                activeStatus === status
                  ? 'bg-green-600 font-semibold text-white'
                  : 'bg-none text-gray-700 hover:bg-gray-300'
              }`}
              onClick={() => handleStatusChange(status)}
            >
              {status}
            </button>
          ))}
        </div>

        {isLoading ? (
          <div className="flex min-h-[40vh] items-center justify-center">
            <p className="text-center text-lg font-medium">Loading procurements...</p>
          </div>
        ) : isError ? (
          <div className="flex min-h-[40vh] items-center justify-center">
            <p className="text-center text-lg font-medium text-red-500">
              Failed to load procurements.
            </p>
          </div>
        ) : procurements.length > 0 ? (
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3 2xl:w-5/6">
            {procurements.map((procurementItem, index) => (
              <ProcurementCard
                key={index}
                procurement={procurementItem}
                onRequestAccess={handleRequestAccessOrProposals}
              />
            ))}
          </div>
        ) : (
          <div className="flex min-h-[40vh] items-center justify-center">
            <div>
              <h3 className="text-center text-2xl font-semibold text-[#161616]">
                No Tender Opportunities
              </h3>
              <p className="text-center text-sm font-medium text-[#7F7F7F]">
                Tender opportunities are not available for this tab
              </p>
            </div>
          </div>
        )}
      </>

      <RequestAccessModal
        isOpen={isAccessModalOpen}
        onClose={handleAccessModalClose}
        onConfirm={handleAccessModalConfirm}
      />

      <RequestProposalModal
        isOpen={isProposalModalOpen}
        onClose={handleProposalModalClose}
        onConfirm={handleProposalModalConfirm}
      />
    </div>
  );
};

export default TenderOpportunitiesComponent;
