import { Search } from 'lucide-react';
import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

const statuses = ['Active', 'Inactive', 'In progress'];

const BiddingComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialStatus = searchParams.get('status') || 'All Bids';

  const [searchTerm, setSearchTerm] = useState('');
  const [activeStatus, setActiveStatus] = useState(initialStatus);

  const statusMap = {
    'All Bids': 0,
    'In Progress': 1,
    Active: 2,
    Denied: 3,
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleStatusChange = (status: string) => {
    setActiveStatus(status);
    setSearchParams({ tab: 'Bidding', status });
    // queryClient.invalidateQueries({ queryKey: ['procurements', status] });
  };

  const getRandomStatus = () => {
    return statuses[Math.floor(Math.random() * statuses.length)];
  };

  return (
    <div className="min-h-[85vh] rounded-2xl border border-[#E5E5E5] bg-white p-8">
      <div className="relative mb-6">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full rounded-lg border border-[#D0D5DD] bg-white px-3.5 py-2.5 pl-12 text-base text-gray-800"
        />
        <Search
          className="absolute left-4 top-1/2 -translate-y-1/2 transform text-gray-500"
          size={20}
        />
      </div>

      <>
        <div className="mb-6 flex space-x-10">
          {Object.keys(statusMap).map((status) => (
            <button
              key={status}
              className={`min-w-[106px] rounded-full px-3.5 py-2.5 text-base ${
                activeStatus === status
                  ? 'bg-green-600 font-semibold text-white'
                  : 'bg-none text-gray-700 hover:bg-gray-300'
              }`}
              onClick={() => {
                handleStatusChange(status);
              }}
            >
              {status}
            </button>
          ))}
        </div>

        <div className="overflow-auto rounded-xl border border-[#E4E7EC] shadow-sm">
          <div className="grid grid-cols-[1fr_1.3fr_.5fr_.5fr_.5fr] gap-4 rounded-t-xl border-b border-[#D2D6DB] bg-[#F5F5F5] px-5 py-[14px] text-sm font-semibold text-[#40474F]">
            <span>Business Name</span>
            <span>Contract Name</span>
            <span>Type</span>
            <span>Date</span>
            <span>Status</span>
          </div>

          {Array.from({ length: 6 }).map((_, index) => {
            const randomStatus = getRandomStatus();
            return (
              <Link
                key={index}
                to="/procurement/123"
                className="grid grid-cols-[1fr_1.3fr_.5fr_.5fr_.5fr] items-center gap-4 border-b border-[#D2D6DB] px-5 py-[14px]"
              >
                <span className="text-base font-semibold text-[#101828]">
                  Green Earth Consultant Ltd
                </span>
                <span className="text-sm font-medium text-[#525252]">
                  Pipeline Inspection and Maintenance
                </span>
                <span className="text-sm font-medium text-[#525252]">Joint Venture</span>
                <span className="text-sm font-medium text-[#525252]">31/08/2024</span>
                <span
                  className={`w-fit min-w-[93px] rounded-[60px] px-2.5 py-1 text-center text-sm font-medium text-white ${randomStatus === 'Active' ? 'bg-[#24A148]' : randomStatus === 'In progress' ? 'bg-[#134C9D]' : 'bg-[#FA4D56]'}`}
                >
                  {randomStatus}
                </span>
              </Link>
            );
          })}

          <div className="flex items-center justify-between px-6 pb-4 pt-3">
            <span className="text-sm text-[#344054]">Page 1 of 10</span>

            <div className="flex gap-x-3">
              <button className="rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm">
                Previous
              </button>

              <button className="rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm">
                Next
              </button>
            </div>
          </div>
        </div>

        {/* //TODO: Use this as the empty state */}
        {/* <div className="flex min-h-[40vh] items-center justify-center">
          <div>
            <h3 className="text-center text-2xl font-semibold text-[#161616]">No Bids Available</h3>
            <p className="text-center text-sm font-medium text-[#7F7F7F]">
              Bids are not available for this tab
            </p>
          </div>
        </div> */}
      </>
    </div>
  );
};

export default BiddingComponent;
