import React, { useEffect, useRef, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import FormComponent from './SupplierForm/SupplierRegistration';
import { ShareholdersTable } from './SupplierForm/Shareholder/ShareHolders';
import { KeyPersonnelTable } from './SupplierForm/KeyPersonnel/KeyPersonels';
import { PrimaryContactTable } from './SupplierForm/PrimaryContact/PrimaryContact';
import PricingComponent from './Payment/Payments';
import { ProjectAndAwardTable } from './SupplierForm/ProjectAndAward/ProjectAndAward';
import { useGlobalContext } from '../../contexts/GlobalContext';
import { AffiliateTable } from './SupplierForm/Affiliate/Affiliate';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmationModal from '../../components/ConfirmationModal';
import { getParameterTypes, initiateWorkflow } from '../../services/workflowServices';
import { use } from 'i18next';
import { toast } from 'react-toastify';
import DocumentRequestModal from '../SupplierRequests/components/RequestAddDoc';
import {
  checkSupplierSetup,
  createAdditional,
  getSupplierById,
  setSupplierStatus,
} from '../../services/supplierService';
import { useTranslation } from 'react-i18next';
import ReviewModal from '../SupplierRequests/components/ReviewAppModal';
import { OnboardingStatus } from '../../types/enums';
import { CompanyRegisterFormValues } from '../../types/interfaces';
import ApplicationProgress from '../../components/ApplicationProgressBar';
import { DocumentTab } from './SupplierForm/Documents/DocumentTab';

interface IWorkflowInitiateValues {
  entityId: string;
  entityName: string;
  parameters: Array<{ parameterName: string; parameterValue: string }>;
  workflowDescription: string;
  selectedWorkflowType: string;
}

interface IParameterData {
  workflowParameterId: string;
  parameterName: string;
  parameterType: string;
  workflowId: string;
}

const SupplierManagement: React.FC = () => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { supplierId } = useGlobalContext();
  const [affiliates, setAffiliates] = useState<
    Array<{ businessName: string; country: string; commercialReg: string }>
  >([]);
  const [parameterTypes, setParameterTypes] = useState<IParameterData[]>([]);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isDocumentRequestModalOpen, setIsDocumentRequestModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSaveAndContinueDisabled, setIsSaveAndContinueDisabled] = useState(false);
  const [supplierInformation, setSupplierInformation] = useState<CompanyRegisterFormValues | null>(
    null
  );

  const [supplierUserId, setSupplierUserId] = useState<any>(null);

  const location = useLocation();
  const isSupplier = location.state?.isSupplier;
  const requestId = location.state?.requestId;
  const entityName = location.state?.entityName;
  const entityId = location.state?.entityId;
  const isReview = location.state?.isReview;
  const isTerminatingStep = location.state?.isTerminatingStep;
  const userId = localStorage.getItem('userId') || '';
  const urlParams = new URLSearchParams(window.location.search);
  const companyId = urlParams.get('supplierId') || '';
  const navigate = useNavigate();

  const formRef = useRef<{ submitForm: () => void }>(null);

  // Define your tab titles
  const tabTitles = [
    t('companyInformation', 'Company Information'),
    t('affiliates', 'Affiliates'),
    t('primaryContact', 'Primary Contact'),
    t('keyPersonnel', 'Key Personnel'),
    t('shareholders', 'Shareholders'),
    t('projectsAndAwards', 'Projects and Awards'),
    t('documentsAttestation', 'Documents & Attestation'),
  ];

  // Calculate progress (in percentage)
  const progressPercentage = (selectedIndex / (tabTitles.length - 1)) * 100;

  // const handleNext = () => {
  //   setSelectedIndex((prevIndex) =>
  //     prevIndex < tabTitles.length - 1 ? prevIndex + 1 : 0
  //   );
  // };

  const handleNext = async () => {
    if (selectedIndex === 0 && formRef.current) {
      // Trigger form submission in SupplierRegistration
      if (isSupplier) {
        if (formRef.current) {
          formRef.current.submitForm();
        }
      } else {
        setSelectedIndex((prevIndex) => prevIndex + 1);
      }
    } else {
      if (isSupplier && selectedIndex === tabTitles.length - 1) {
        const status = OnboardingStatus.NEW;
        const supplierId = localStorage.getItem('supplierId');
        if (!supplierId) return;
        const setStatusResponse = await setSupplierStatus(supplierId, status);
        if (setStatusResponse?.isSuccess) {
          toast.success(
            t('applicationSubmittedSuccessfully', 'Your application was submitted successfully')
          );
          setTimeout(() => {
            navigate('/supplier-application');
          }, 2000);
        } else {
          toast.error(setStatusResponse?.errorMessage);
        }
      } else if (!isSupplier && selectedIndex === tabTitles.length - 1) {
        navigate('/requests/supplier-requests');
      } else {
        setSelectedIndex((prevIndex) => prevIndex + 1);
      }
    }
  };

  const moveToNextTab = () => {
    setSelectedIndex((prevTab) => prevTab + 1); // Move to the next tab
  };

  const handlePrevious = () => {
    setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : tabTitles.length - 1));
  };

  const handleExit = () => {
    if (isSupplier) {
      navigate('/supplier-application');
    } else {
      navigate(-1);
    }
  };

  const checkSupplierRole = async () => {
    const response = await checkSupplierSetup();
    if (response?.isSuccess) {
      reviewApplication(response?.data?.roleId);
    } else {
      toast.error(response?.errorMessage);
    }
  };

  const reviewApplication = async (roleId: string) => {
    if (parameterTypes.length < 1)
      return toast.error(t('noParameterTypeFound', 'No parameter type found'));
    const payload: IWorkflowInitiateValues = {
      entityId: companyId,
      entityName: 'Supplier',
      parameters: parameterTypes.map((parameterType) => {
        let parameterValue = '';
        if (parameterType.parameterName.toLowerCase().includes('baseurl')) {
          //parameterValue = `${window.location.origin}${window.location.pathname}`;
          parameterValue = window.location.origin;
        }
        if (parameterType.parameterName.toLowerCase().includes('supplierid')) {
          parameterValue = companyId;
        }
        if (parameterType.parameterName.toLowerCase().includes('roleid')) {
          parameterValue = roleId;
        }
        if (parameterType.parameterName.toLowerCase().includes('userid')) {
          parameterValue = supplierUserId;
        }
        return {
          parameterName: parameterType.parameterName,
          parameterValue,
        };
      }),
      workflowDescription: `${supplierInformation?.businessName} with registration number ${supplierInformation?.registrationNumber} initiated`,
      selectedWorkflowType: 'Supplier',
    };

    const response = await initiateWorkflow(payload);
    if (response?.isSuccess) {
      const status = OnboardingStatus.PROCESSING;
      const setStatusResponse = await setSupplierStatus(companyId, status);
      if (setStatusResponse?.isSuccess) {
        toast.success(response?.data?.message);
        setTimeout(() => {
          navigate('/requests/supplier-requests');
        }, 2000);
      } else {
        toast.error(setStatusResponse?.errorMessage);
      }
    } else {
      toast.error(response?.errorMessage);
    }

    setIsConfirmationModalOpen(false);
  };

  const handleReview = () => {
    if (isReview) {
      return setIsReviewModalOpen(true);
    }
    return setIsConfirmationModalOpen(true);
  };

  const getParameters = async () => {
    const response = await getParameterTypes(0);

    if (response?.isSuccess) {
      setParameterTypes(response.data);
    }
  };

  const handleApproval = () => {
    setIsReviewModalOpen(true);
    navigate('/application-desk');
  };

  const requestAdditionalDocument = async (data: any) => {
    const payload = {
      documentTypes: data?.documentTypes.map((doc: { name: string; value: string }) => doc.value),
      comment: data?.remarks,
      companyId: companyId,
    };

    const response = await createAdditional(payload);
    if (response?.isSuccess) {
      const status = OnboardingStatus.REJECTED;
      const setStatusResponse = await setSupplierStatus(companyId, status);
      if (setStatusResponse?.isSuccess) {
        toast.success(response?.data?.message);
        setIsDocumentRequestModalOpen(false);
        setTimeout(() => {
          navigate('/requests/supplier-requests');
        }, 2000);
      } else {
        toast.error(setStatusResponse?.errorMessage);
      }
    }
  };

  const checkListLength = (listLength: number) => {
    if (isSupplier && listLength === 0) {
      setIsSaveAndContinueDisabled(true);
    } else {
      setIsSaveAndContinueDisabled(false);
    }
  };

  const handleValuesChange = (values: CompanyRegisterFormValues) => {
    setSupplierInformation(values);
  };

  const getSupplierInformation = async () => {
    try {
      if (!companyId) return;
      const response = await getSupplierById(companyId);
      if (response) {
        setSupplierUserId(response?.supplierUserId);
      }
    } catch (error) {
      toast.error(t('errorGettingSupplierInformation', 'Error getting supplier information'));
    }
  };

  useEffect(() => {
    if (!isSupplier) {
      getSupplierInformation();
      getParameters();
    }
  }, [isSupplier]);

  return (
    <div className="max-w-screen-xl mx-auto pb-12 pt-10 ">
      <div className="flex justify-between">
        <h1 className="font-semibold text-3xl mb-8">
          {t('supplierManagement', 'Supplier Management')}
        </h1>
        {!isSupplier && (
          <div className="flex gap-4">
            {!isReview && (
              <button
                type="button"
                className="text-black px-12 h-12 py-6 flex items-center border border-[#E5E5E5] rounded-lg font-semibold"
                onClick={() => setIsDocumentRequestModalOpen(true)}
              >
                {t('requestAdditionalDocument', 'Request Additional Document')}
              </button>
            )}
            <button
              type="button"
              className="bg-green-500 text-white px-12 h-12 py-6 flex items-center rounded-lg font-semibold"
              onClick={() => handleReview()}
            >
              {t('reviewApplication', 'Review Application')}
            </button>
          </div>
        )}
      </div>
      {/* Progress Bar */}
      <div className="mb-4">
        {isSupplier && <ApplicationProgress percentage={progressPercentage} />}
      </div>

      {/* Tabs Component */}
      <Tabs
        selectedIndex={selectedIndex}
        onSelect={(index) => {
          if (index === 0 || supplierId) {
            setSelectedIndex(index);
          }
        }}
      >
        <TabList className="flex border-0">
          {tabTitles.map((title, index) => (
            <Tab
              key={index}
              className={`px-4 py-2.5 text-xs font-semibold text-[#101828] ${index !== 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              selectedClassName="bg-[#FFC726] rounded-t-lg"
              disabledClassName="text-[#101828]"
              disabled={index !== 0}
              // disabled={index !== 0 && !supplierId}
            >
              {title}
            </Tab>
          ))}
        </TabList>

        <div className="formCard  bg-white border border-[#E5E5E5] p-6 py-[53px] rounded-[30px] xl:mr-28">
          <TabPanel>
            <FormComponent
              ref={formRef}
              isEditable={isSupplier}
              affiliates={affiliates}
              onFormSubmitSuccess={moveToNextTab}
              onValuesChange={(values) => handleValuesChange(values)}
            />
          </TabPanel>

          <TabPanel>
            <AffiliateTable isEditable={isSupplier} checkListLength={checkListLength} />
          </TabPanel>

          <TabPanel>
            <PrimaryContactTable isEditable={isSupplier} checkListLength={checkListLength} />
          </TabPanel>

          <TabPanel>
            <KeyPersonnelTable isEditable={isSupplier} checkListLength={checkListLength} />
          </TabPanel>

          <TabPanel>
            <ShareholdersTable isEditable={isSupplier} checkListLength={checkListLength} />
          </TabPanel>

          <TabPanel>
            <ProjectAndAwardTable isEditable={isSupplier} checkListLength={checkListLength} />
          </TabPanel>

          <TabPanel>
            <DocumentTab isEditable={isSupplier} checkListLength={checkListLength} />
          </TabPanel>

          <div className="flex justify-end w-full gap-6 mt-8">
            {selectedIndex > 0 || !isSupplier ? (
              <button
                type="button"
                onClick={
                  !isSupplier && selectedIndex === 0
                    ? handleExit
                    : isSupplier
                      ? handlePrevious //handleExit
                      : handlePrevious
                }
                className="px-8 py-3 text-sm font-semibold text-[#101828] bg-[#F5F5F5] rounded-lg min-w-[172px]"
              >
                {!isSupplier && selectedIndex === 0
                  ? t('exit', 'Exit')
                  : isSupplier
                    ? t('previous', 'Previous') //t('saveAndExit', 'Save & Exit')
                    : t('previous', 'Previous')}
              </button>
            ) : null}

            {selectedIndex === 0 && isSupplier && supplierId && (
              <button
                type="button"
                onClick={moveToNextTab}
                className="px-8 py-3 text-sm font-semibold text-[#101828] bg-[#F5F5F5] rounded-lg min-w-[172px]"
              >
                {t('next', 'Next')}
              </button>
            )}

            <button
              type="button"
              onClick={handleNext}
              className={`px-8 py-3 text-sm font-semibold text-[#101828] rounded-lg min-w-[172px] ${isSaveAndContinueDisabled ? 'bg-[#F5F5F5]' : 'bg-primary'}`}
              disabled={isSaveAndContinueDisabled}
            >
              {isSupplier
                ? selectedIndex === tabTitles.length - 1
                  ? t('submit', 'Submit')
                  : t('saveAndContinue', 'Save & Continue')
                : selectedIndex === tabTitles.length - 1
                  ? t('exit', 'Exit')
                  : t('next', 'Next')}
            </button>
          </div>
        </div>

        {/* <TabPanel>
          <PricingComponent isEditable={isSupplier} />
        </TabPanel> */}
      </Tabs>

      {/* Review Modal */}
      <ReviewModal
        isOpen={isReviewModalOpen}
        onClose={() => setIsReviewModalOpen(false)}
        onSubmit={() => handleApproval()}
        isTerminatingStep={isTerminatingStep}
        requestId={requestId}
        userId={userId}
        entityName={entityName}
        entityId={entityId}
      ></ReviewModal>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          title={t('areYouSure', 'Are you sure?')}
          desc={t('initiateReviewProcess', 'This action will initiate the review process.')}
          onConfirm={checkSupplierRole}
          onCancel={() => setIsConfirmationModalOpen(false)}
        />
      )}

      {isDocumentRequestModalOpen && (
        <DocumentRequestModal
          isOpen={isDocumentRequestModalOpen}
          onClose={() => setIsDocumentRequestModalOpen(false)}
          onSubmit={(data) => requestAdditionalDocument(data)}
        />
      )}
    </div>
  );
};

export default SupplierManagement;
