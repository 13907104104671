import { Form, Formik, FormikValues } from 'formik';

import FormikInput from '../../components/CustomTextInput/CustomTextInput';
import FormikDateInput from '../../components/CustomDatePicker/CustomDatePicker';
import FormikTextarea from '../../components/CustomTextArea/CustomTextArea';
import UploadInput from '../../components/CustomUploadInput/CustomUploadInput';

import * as Yup from 'yup';
import { useState } from 'react';

const ExpressionOfInterestPage = () => {
  const [pointsInputEnabled, setPointsInputEnabled] = useState(false);

  const handleFileUpload = (files: File[]) => {
    // console.log("Uploaded Files:", files);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    // industryId: Yup.string().required('Industry is required'),
    // businessType: Yup.string().required('Business type is required'),
  });

  const handleSubmit = (values: FormikValues) => {};

  return (
    <div className="mx-auto max-w-screen-xl p-8">
      <h1 className="mb-1 text-2xl font-semibold text-gray-900">Electronic Procurement</h1>

      <div className="2xl:w-11/12">
        <div className="mb-2.5 mt-8 flex flex-col justify-between gap-x-7 gap-y-4 rounded-xl border border-[#E5E5E5] bg-white p-5 pb-12 xl:flex-row xl:items-center">
          <div className="grid w-full grid-cols-2 gap-3 xl:grid-cols-4">
            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Contract Name</span>
              <span className="text-sm font-medium text-[#525252]">
                Pipeline Inspection and Maintenance
              </span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Type</span>
              <span className="text-sm font-medium text-[#525252]">Joint Venture</span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Date</span>
              <span className="text-sm font-medium text-[#525252]">31/08/2024</span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Status</span>
              <span className="text-sm font-medium text-[#24A148]">Active</span>
            </div>
          </div>

          <button className="ml-auto flex h-12 w-[140px] items-center justify-center rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm">
            View Details
          </button>
        </div>

        <div className="my-9">
          {/* Background Bar */}
          <div className="relative h-[3px] w-full bg-[#E5E5E5]">
            {/* Completed Section 1 */}
            <div className="absolute -top-[20px] z-10 flex h-[38px] w-[38px] items-center justify-center rounded-full bg-[#42BE65] text-lg font-medium text-white">
              1
            </div>

            <div className="absolute left-0 top-0 h-full w-[50%] bg-[#42BE65]"></div>

            <div className="absolute right-0 top-0 h-full w-[50%] bg-[#42BE65]"></div>

            {/* Completed Section 2 */}
            {/* <div className="absolute top-[25%] h-[25%] w-full bg-[#42BE65]"></div> */}
          </div>
        </div>

        <div className="my-4 rounded-lg bg-[#24A148] px-5">
          <p className="text-xl font-semibold text-white">Stage 1 of 3</p>
        </div>

        <div className="rounded-2xl border border-[#E5E5E5] bg-white pb-[54px] pl-[34px] pr-[26px] pt-[33px]">
          <h3 className="mb-[34px] text-xl font-medium text-[#101828]">
            Fill out the information below
          </h3>

          <Formik<FormikValues>
            initialValues={{}}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="flex w-full gap-x-4">
                  <FormikInput
                    label="Address"
                    name="title"
                    type="text"
                    placeholder="Enter Address"
                    isRequired
                  />

                  <FormikInput
                    label="State"
                    name="title"
                    type="text"
                    placeholder="Enter State"
                    isRequired
                  />
                </div>

                <div className="flex w-full gap-x-4">
                  <FormikDateInput label="Estimated Start Date" name="bidStartDate" isRequired />
                  <FormikDateInput label="Estimated End Date" name="bidEndDate" isRequired />
                </div>

                {/* <TagInput
              label="Tags"
              placeholder="Enter tags..."
              tags={tags}
              suggestions={availableTags}
              onChange={setTags}
              isRequired={true}
            /> */}

                <FormikTextarea
                  label="Cover Letter"
                  name="description"
                  placeholder="Pitch yourself"
                  isRequired
                />

                <div className="my-8">
                  <h5 className="mb-8 text-xl font-semibold text-[#101828]">
                    Statutory Declaration
                  </h5>

                  <label className="custom-checkbox flex items-center">
                    <input
                      type="checkbox"
                      checked={pointsInputEnabled} // Bind to Formik value
                      onChange={(e) => {
                        setPointsInputEnabled(e.target.checked); // Update Formik value
                      }}
                    />
                    <span className="checkbox"></span>
                    <span className="label !text-sm !text-[#525252]">
                      I, the applicant, hereby attest that the information provided in this
                      submission is accurate and true to the best of my knowledge.
                    </span>
                  </label>
                </div>

                <UploadInput
                  label="Upload Signature"
                  acceptedFileTypes={['image/png', 'image/jpeg', 'application/pdf']}
                  maxFileSizeMB={10}
                  multiple={true}
                  onUpload={handleFileUpload}
                />

                <div className="ml-auto mt-10 flex w-full items-center justify-end gap-x-[18px]">
                  <button
                    type="submit"
                    className="min-w-[195px] rounded-[10px] bg-[#F5F5F5] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#E0E0E0] disabled:cursor-not-allowed disabled:bg-[#D6D6D6] disabled:text-[#B0B0B0]"
                    // disabled={isLoading}
                    // onClick={() => setSaveAndContinue(false)} // Save and exit
                  >
                    Save & Exit
                  </button>
                  <button
                    type="submit"
                    className="min-w-[195px] rounded-[10px] bg-[#FFC726] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#FFB600] disabled:cursor-not-allowed disabled:bg-[#E0A900] disabled:text-[#B0B0B0]"
                    // disabled={isLoading}
                    // onClick={() => setSaveAndContinue(true)} // Save & Continue
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ExpressionOfInterestPage;
