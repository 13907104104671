import React, { useEffect, useState } from 'react';
import { FaPlus, FaTimes, FaTrash } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ProgressBarNew from '../../../../components/ProgressBarNew/ProgressBarNew';
import { createGoods, getAllCategories } from '../../../../services/catalogService';
import { toast } from 'react-toastify';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import { getTags } from '../../../../services/supplierService';
import Select, { ActionMeta, MultiValue } from 'react-select';
import { OptionType } from '../../../../types/interfaces';
import { uploadImage } from '../../../../services/edmsService';
import { ProductType } from '../../../../types/enums';

interface AddProductsProps {
  show: boolean;
  close: (value: boolean) => void;
  onProductAdded: () => void;
}

const AddServices: React.FC<AddProductsProps> = ({ show, close, onProductAdded }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [categories, setCategories] = useState<{ categorId: string; categoryName: string }[]>([]);
  const [subCategories, setSubCategories] = useState<{ categorId: string; categoryName: string }[]>(
    []
  );
  const [isSubCategoryDisabled, setIsSubCategoryDisabled] = useState(true);
  const [tags, setTags] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<OptionType[]>([]);

  const { supplierId, setSupplierId } = useGlobalContext();

  const formatNumber = (value: any) => (!value ? '' : new Intl.NumberFormat().format(value));
  const parseNumber = (value: any) => value.replace(/,/g, '');

  useEffect(() => {
    const fetchCategories = async () => {
      const params = { isParentCategory: true };
      const response = await getAllCategories(params);
      if (response?.isSuccess) {
        setCategories(response.data);
      }
    };

    const fetchSupplierTags = async () => {
      if (!supplierId) return;
      const response = await getTags(supplierId);
      if (response?.isSuccess) {
        setTags(response.data);
      }
    };

    fetchCategories();
    fetchSupplierTags();
  }, []);

  const handleTagChange = (
    newValue: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    const selectedOptions = newValue as OptionType[];
    setSelectedTags(selectedOptions);
    formik.setFieldValue('tags', selectedOptions);
  };

  const handleCategoryChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCategoryId = event.target.value;
    formik.setFieldValue('category', selectedCategoryId);

    if (selectedCategoryId) {
      setIsSubCategoryDisabled(false);
      const params = {
        categoryId: selectedCategoryId,
        isParentCategory: false,
      };
      const response = await getAllCategories(params);
      if (response?.isSuccess) {
        setSubCategories(response.data);
      }
    } else {
      setIsSubCategoryDisabled(true);
      setSubCategories([]);
    }
  };

  const getValidationSchema = (step: number) => {
    switch (step) {
      case 1:
        return Yup.object({
          title: Yup.string().required('Title is required'),
          category: Yup.string().required('Category is required'),
          subCategory: Yup.string().required('Sub-category is required'),
          location: Yup.string().required('Location is required'),
          description: Yup.string().nullable(),
          images: Yup.array().min(1, 'At least one image is required'),
        });
      case 2:
        return Yup.object({
          certification: Yup.string().required('Certification is required'),
          serviceSource: Yup.string().required('Service source is required'),
          note: Yup.string().nullable(),
          warrantyDuration: Yup.string().required('Warranty Duration is required'),
          warrantyType: Yup.string().required('Warranty Type is required'),
          comment: Yup.string().nullable(),
          tags: Yup.array().min(1, 'At least one tag is required'),
        });
      case 3:
        return Yup.object({
          globalPrice: Yup.number()
            .required('Global Price is required')
            .positive('Price must be a positive number')
            .typeError('Global Price must be a valid number'),
          salePrice: Yup.number()
            .required('Sale Price is required')
            .positive('Sale Price must be a positive number')
            .typeError('Sale Price must be a valid number'),
        });
      default:
        return Yup.object({});
    }
  };

  useEffect(() => {
    formik.validateForm();
  }, [currentStep]);

  const formik = useFormik({
    initialValues: {
      title: '',
      category: '',
      subCategory: '',
      location: '',
      description: '',
      images: [],
      certification: '',
      serviceSource: '',
      warrantyDuration: '',
      warrantyType: '',
      comment: '',
      globalPrice: '',
      salePrice: '',
      tags: [],
      isVatInclusive: false,
      percentageVat: 0,
    },
    validationSchema: getValidationSchema(currentStep),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      if (currentStep < 3) {
        setCurrentStep((prev) => prev + 1);
      } else {
        // console.log('Final Submit', values);
        const payload = {
          productName: values.title,
          description: values.description,
          categoryId: values.subCategory,
          location: values.location,
          globalPrice: values.globalPrice,
          price: values.salePrice,
          certification: values.certification,
          warrantyDuration: parseInt(values.warrantyDuration, 10),
          warrantType: values.warrantyType,
          comment: values.comment,
          serviceSource: values.serviceSource,
          tagIds: values.tags.map((tag: OptionType) => tag.value),
          currency: 'USD',
          status: 'Publish',
          tax: values.percentageVat,
          isTaxInclusive: values.isVatInclusive,
          productType: ProductType.SERVICES,
        };

        handleCreateProduct(payload);
      }
    },
  });

  const saveProductImages = async (productId: string) => {
    const formData = new FormData();
    formik.values.images.forEach((image: any) => {
      formData.append('files', image);
    });
    formData.append('entityId', productId);
    // formData.append('module', '1');
    formData.append('documentTypeName', 'ProductImages');

    const response = await uploadImage(formData);
    if (response?.isSuccess) {
      toast.success(response?.data.message);
    } else {
      toast.warn(
        'Product created successfully, but experienced error while uploading product images'
      );
    }
    formik.resetForm();
    setCurrentStep(1);
    setSelectedImages([]);
    onProductAdded();
  };

  const handleCreateProduct = async (payload: any) => {
    try {
      const response = await createGoods(payload);
      // console.log('Product created successfully', response);
      if (response?.isSuccess) {
        // toast.success(response?.data.message);
        // formik.resetForm();
        // setCurrentStep(1);
        // setSelectedImages([]);
        // close();
        saveProductImages(response.data?.id);
      }
    } catch (error) {
      toast.error('Error creating product');
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      const newImages = fileArray.map((file) => URL.createObjectURL(file));
      setSelectedImages((prevImages) => [...prevImages, ...newImages]);
      formik.setFieldValue('images', [...formik.values.images, ...fileArray]);
      event.target.value = '';
    }
  };

  const handleImageClick = () => {
    const fileInput = document.getElementById('imageUpload') as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleDeleteImage = (index: number) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    formik.setFieldValue(
      'images',
      formik.values.images.filter((_, i) => i !== index)
    );
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white rounded-3xl shadow-lg w-full max-w-5xl py-8 sm:px-16 h-[90vh] overflow-y-auto">
        <button
          onClick={() => close(false)}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
          aria-label="Close modal"
        >
          <FaTimes size={20} />
        </button>
        <h2 className="text-2xl font-bold mt-8 mb-4">Add a New Service</h2>

        {/* Progress Bar */}
        <ProgressBarNew
          steps={['Service Information', 'Service Specification', 'Pricing']}
          currentStep={currentStep}
        />

        <form onSubmit={formik.handleSubmit}>
          {/* Step 1: Product Information */}
          {currentStep === 1 && (
            <>
              <div className="flex items-center space-x-4 mt-3 mb-2">
                {/* Upload button */}
                <div
                  className="border border-gray-400 rounded-lg w-28 h-28 flex  justify-center cursor-pointer"
                  onClick={handleImageClick}
                >
                  <span className="text-center flex flex-col items-center justify-between px-2 py-4 text-sm text-gray-500">
                    <FaPlus size={10} />
                    Add Image or Video
                  </span>
                  <input
                    id="imageUpload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                    multiple
                  />
                </div>
                {/* Carousel for Image Preview */}
                <div className="flex overflow-x-auto space-x-4 w-full">
                  {selectedImages.map((image, index) => (
                    <div
                      key={index}
                      className="relative w-28 h-28 border border-gray-400 rounded-lg overflow-hidden"
                    >
                      <img
                        src={image}
                        alt={`Uploaded ${index}`}
                        className="object-cover w-full h-full"
                      />

                      <button
                        type="button"
                        className="absolute top-1 right-1 text-white bg-red-500 rounded-full p-1"
                        onClick={() => handleDeleteImage(index)}
                      >
                        <FaTrash size={10} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {formik.touched.images &&
              formik.errors.images &&
              formik.values.images.length === 0 ? (
                <div className="text-red-500 text-sm">{formik.errors.images}</div>
              ) : null}

              <p className="text-gray-500 text-sm">
                First image is cover image. No watermarks. Upload jpeg, jpg, png, or mp4. Max size:
                10MB. Dimensions: 400x400 - 1500x1500.
              </p>

              <div className="mt-4 mb-5">
                <label className="block mb-2 font-medium">
                  Title of Service/Consulting
                  <span className="text-red-700">*</span>
                </label>
                <input
                  type="text"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Name of service or consulting. It should be easy to understand"
                  className="block w-full border border-gray-400 rounded-lg p-2 outline-none text-base"
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className="text-red-500 text-sm">{formik.errors.title}</div>
                ) : null}
              </div>

              <div className="grid grid-cols-3 mb-5 gap-4 mt-4">
                <div>
                  <label className="block mb-2 font-medium">
                    Category <span className="text-red-700">*</span>
                  </label>
                  <select
                    name="category"
                    value={formik.values.category}
                    onChange={handleCategoryChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                  >
                    <option value="">Select</option>
                    {categories.map((category) => (
                      <option key={category.categorId} value={category.categorId}>
                        {category.categoryName}
                      </option>
                    ))}
                  </select>
                  {formik.touched.category && formik.errors.category ? (
                    <div className="text-red-500 text-sm">{formik.errors.category}</div>
                  ) : null}
                </div>

                <div>
                  <label className="block mb-2 font-medium">
                    Sub-category <span className="text-red-700">*</span>
                  </label>
                  <select
                    name="subCategory"
                    value={formik.values.subCategory}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                    disabled={isSubCategoryDisabled}
                  >
                    <option value="">Select</option>
                    {subCategories.map((subCategory) => (
                      <option key={subCategory.categorId} value={subCategory.categorId}>
                        {subCategory.categoryName}
                      </option>
                    ))}
                  </select>
                  {formik.touched.subCategory && formik.errors.subCategory ? (
                    <div className="text-red-500 text-sm">{formik.errors.subCategory}</div>
                  ) : null}
                </div>

                <div>
                  <label className="block mb-2 font-medium">
                    Location <span className="text-red-700">*</span>
                  </label>
                  <select
                    name="location"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                  >
                    <option value="">Select</option>
                    <option value="New York">New York</option>
                    <option value="San Francisco">San Francisco</option>
                  </select>
                  {formik.touched.location && formik.errors.location ? (
                    <div className="text-red-500 text-sm">{formik.errors.location}</div>
                  ) : null}
                </div>
              </div>
              <div>
                <label className=" col-span-1 mb-3 font-medium">Description</label>
                <textarea
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full border border-gray-400 rounded-lg p-2 outline-none text-base"
                  rows={3}
                />
                {/* {formik.touched.description && formik.errors.description ? (
                    <div className="text-red-500 text-sm">{formik.errors.description}</div>
                  ) : null} */}
              </div>
            </>
          )}

          {/* Step 2: Product Specification */}
          {currentStep === 2 && (
            <>
              <div>
                <p className="text-xl font-medium mt-4">Description of Service/Consulting</p>
              </div>
              <div className="grid grid-cols-3 gap-4 mt-4 space-y-2">
                <div>
                  <label className="block mb-2 font-medium">
                    Certification<span className="text-red-700">*</span>
                  </label>
                  <select
                    name="certification"
                    value={formik.values.certification}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                  >
                    <option value="">Select Certification</option>
                    <option value="Cert 1">Cert 1</option>
                    <option value="Cert 2">Cert 2</option>
                  </select>
                  {formik.touched.certification && formik.errors.certification ? (
                    <div className="text-red-500 text-sm">{formik.errors.certification}</div>
                  ) : null}
                </div>
                <div>
                  <label className="block mb-2 font-medium">
                    Service source<span className="text-red-700">*</span>
                  </label>
                  <select
                    name="serviceSource"
                    value={formik.values.serviceSource}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                  >
                    <option value="">Select service source</option>
                    <option value="Source 1">Source 1</option>
                    <option value="Source 2">Source 2</option>
                  </select>
                  {formik.touched.serviceSource && formik.errors.serviceSource ? (
                    <div className="text-red-500 text-sm">{formik.errors.serviceSource}</div>
                  ) : null}
                </div>

                <div>
                  <label className="block mb-2 font-medium">
                    WarrantyDuration <span className="text-red-700">*</span>
                  </label>
                  <select
                    name="warrantyDuration"
                    value={formik.values.warrantyDuration}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                  >
                    <option value="">Select Warranty Duration</option>
                    <option value="1">1 year</option>
                    <option value="2">2 years</option>
                  </select>
                  {formik.touched.warrantyDuration && formik.errors.warrantyDuration ? (
                    <div className="text-red-500 text-sm">{formik.errors.warrantyDuration}</div>
                  ) : null}
                </div>

                <div>
                  <label className="block mb-2 font-medium">
                    Warranty Type <span className="text-red-700">*</span>
                  </label>
                  <select
                    name="warrantyType"
                    value={formik.values.warrantyType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full border border-gray-400 rounded-lg px-2 py-4 outline-none"
                  >
                    <option value="">Select Warranty Available</option>
                    <option value="Type 1">Type 1</option>
                    <option value="Type 2">Type 2</option>
                  </select>
                  {formik.touched.warrantyType && formik.errors.warrantyType ? (
                    <div className="text-red-500 text-sm">{formik.errors.warrantyType}</div>
                  ) : null}
                </div>
              </div>

              {/* Add tags multi select field here */}
              <div className="grid mt-4">
                <label className="block mb-2 font-medium">
                  Tags<span className="text-red-700">*</span>
                </label>
                <Select
                  id="tags"
                  isMulti
                  options={tags.map((tag) => ({
                    value: tag.tagId,
                    label: tag.tagName,
                  }))}
                  value={selectedTags}
                  onChange={handleTagChange}
                  className="text-sm"
                />
                {formik.touched.tags && formik.errors.tags ? (
                  <div className="text-red-500 text-sm">{formik.errors.tags}</div>
                ) : null}
              </div>

              <div className="grid gap-4 mt-4">
                <div className=" col-span-1">
                  <label className="block mb-2 font-medium">Note or Comment</label>
                  <textarea
                    name="comment"
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Name/Comment"
                    className="w-full border border-gray-400 rounded-lg p-2 outline-none"
                    rows={3}
                  />
                </div>
              </div>
            </>
          )}

          {/* Step 3: Pricing */}
          {currentStep === 3 && (
            <>
              <div className="mt-8 mb-6 flex  w-[70%] gap-8">
                <div className="">
                  <label className="block mb-2 font-medium">
                    Global Price(USD)<span className="text-red-700">*</span>
                  </label>
                  <input
                    type="text"
                    name="globalPrice"
                    onBlur={formik.handleBlur}
                    placeholder="Global Price"
                    className="w-full border border-gray-400 rounded-lg p-2"
                    onChange={(e: any) => {
                      const rawValue = parseNumber(e.target.value);
                      const numericValue = rawValue ? Number(rawValue) : 0;
                      formik.setFieldValue('globalPrice', numericValue);
                    }}
                    value={formatNumber(formik.values.globalPrice)}
                  />
                  {formik.touched.globalPrice && formik.errors.globalPrice ? (
                    <div className="text-red-500 text-sm">{formik.errors.globalPrice}</div>
                  ) : null}
                </div>
                <div>
                  <label className="block mb-2 font-medium">
                    Sale Price(USD)<span className="text-red-700">*</span>
                  </label>
                  <input
                    type="text"
                    name="salePrice"
                    onBlur={formik.handleBlur}
                    placeholder="Sale Price"
                    className="w-full border border-gray-400 rounded-lg p-2"
                    onChange={(e: any) => {
                      const rawValue = parseNumber(e.target.value);
                      const numericValue = rawValue ? Number(rawValue) : 0;
                      formik.setFieldValue('salePrice', numericValue);
                    }}
                    value={formatNumber(formik.values.salePrice)}
                  />
                  {formik.touched.salePrice && formik.errors.salePrice ? (
                    <div className="text-red-500 text-sm">{formik.errors.salePrice}</div>
                  ) : null}
                </div>
              </div>
              <div className="flex w-[100%] gap-2 items-center mb-6">
                <input
                  type="checkbox"
                  id="isVatInclusive"
                  name="isVatInclusive"
                  checked={formik.values.isVatInclusive}
                  onChange={formik.handleChange}
                  className="h-6 w-6"
                />
                <label htmlFor="isVatInclusive" className="text-base">
                  Is your sale price inclusive of VAT?
                </label>
              </div>
              <div className="w-[30%] mb-8">
                <label className="block mb-2 font-medium">
                  Percentage VAT% (USD)<span className="text-red-700">*</span>
                </label>
                <input
                  type="number"
                  name="percentageVat"
                  value={formik.values.percentageVat}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Percentage VAT"
                  className="w-full border border-gray-400 rounded-lg p-2"
                  disabled={formik.values.isVatInclusive}
                />
                {formik.touched.percentageVat && formik.errors.percentageVat ? (
                  <div className="text-red-500 text-sm">{formik.errors.percentageVat}</div>
                ) : null}
              </div>
            </>
          )}

          <div className="mt-6 flex float-end gap-8">
            <button
              type="button"
              className="px-8 py-2 bg-gray-300 rounded-lg"
              onClick={() => setCurrentStep((prev) => Math.max(prev - 1, 1))}
            >
              {currentStep < 3 ? 'Back' : 'Back'}
            </button>
            <button
              type="submit"
              className={`px-8 py-2 rounded-lg ${formik.isValid ? 'bg-primary' : 'bg-gray-300 cursor-not-allowed'}`}
              disabled={!formik.isValid}
            >
              {currentStep < 3 ? 'Next' : 'Publish'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddServices;
