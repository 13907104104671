import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import FormikInput from '../../components/CustomTextInput/CustomTextInput';
import { XIcon } from 'lucide-react';
import { ProcurementStatus, TenderDocumentType } from '../../types/enums';
import { toast } from 'react-toastify';
import {
  createTenderDocument,
  deleteTenderDocument,
  listTenderDocuments,
  updateTenderStatus,
} from '../../services/procurementService';
import { TenderDocument } from '../../types/procurement-types';
import { useNavigate } from 'react-router-dom';

interface CommercialsValues {
  documentName: string;
  assignPoint: string;
}

interface CommercialsProps {
  serviceInformationId: string | null;
}

const Commercials: React.FC<CommercialsProps> = ({ serviceInformationId }) => {
  const navigate = useNavigate();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveAndContinue, setSaveAndContinue] = useState(false);

  const [tenderDocuments, setTenderDocuments] = useState<TenderDocument[]>([]);

  const [pointsInputEnabled, setPointsInputEnabled] = useState(false);

  const ExpressionOfInterestValuesValidation = Yup.object().shape({
    documentName: Yup.string().required('Document type is required'),
    assignPoint: Yup.number()
      .optional()
      .min(0, 'Points cannot be less than 0')
      .max(100, 'Points cannot exceed 100'),
    // supplierCategory: z.string().optional(),
  });

  useEffect(() => {
    if (serviceInformationId) {
      handleListTenderDocument();
    }
  }, [serviceInformationId]);

  const handleListTenderDocument = async () => {
    try {
      const response = await listTenderDocuments(serviceInformationId);
      console.log('response: ', response.data);

      // Expression of Interests Documents has a type number of 0
      if (response.data) {
        const commercialsDocs = response.data?.filter(
          (item) => item.tenderDocumentType === TenderDocumentType.COMMERCIALS
        );
        setTenderDocuments(commercialsDocs);
      }
    } catch (error) {
      console.error('Something went wrong: ', error);
      toast.error('Something went wrong');
    }
  };

  const handleCloseModal = () => {
    setIsAddModalOpen(false);
    handleListTenderDocument();
  };

  const handleSubmitCommercials = useCallback(
    async (data: CommercialsValues) => {
      const { documentName, assignPoint } = data;

      const payload = {
        serviceInformationId,
        documentName,
        ...(pointsInputEnabled && { assignPoint: Number(assignPoint) }),
        tenderDocumentType: TenderDocumentType.COMMERCIALS,
      };

      console.log('Payload: ', payload);

      try {
        setIsLoading(true);
        const response = await createTenderDocument(payload);

        console.log('response: ', response);

        if (response.isSuccess) {
          toast.success(response.data.message);

          await updateTenderStatus({
            serviceInformationId: serviceInformationId as string,
            status: ProcurementStatus.NEW,
          });

          handleCloseModal();
        }
      } catch (error) {
        console.error('Something went wrong: ', error);
        toast.error('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    },
    [serviceInformationId, pointsInputEnabled]
  );

  const handleDeleteDocument = async (tenderDocumentId: string) => {
    try {
      const response = await deleteTenderDocument(tenderDocumentId);
      if (response.isSuccess) {
        toast.success(response.data.message);
        handleListTenderDocument();
      }
    } catch (error) {
      console.error('Something went wrong: ', error);
      toast.error('Something went wrong');
    }
  };

  const handleGoBack = () => {
    toast.info('Submitted for review');
    navigate(-1); // Goes back to the previous page
  };

  return (
    <div className="mx-4 space-y-4">
      <div className="mb-6 flex items-center justify-between border-b border-b-[#E5E5E5] pb-4">
        <h2 className="text-2xl font-semibold">Commercial/Financial Information Criteria</h2>
        <button
          onClick={() => setIsAddModalOpen(true)}
          className="rounded-lg border border-black bg-white px-3 py-1.5 font-medium text-[#101828]"
        >
          + Add
        </button>
      </div>

      <div className="flex gap-x-3.5 rounded-[4px] border-2 border-[#FA4D56] bg-[#F7DFDF] p-4">
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/outline/Info-red.svg`}
          alt={'info'}
          className=" "
        />

        <p className="text-base text-[#161616]">Total points must equal 100 before submission.</p>
      </div>

      <h3 className="text-xl font-medium text-[#344054]">Requested Documents</h3>

      <div className="!mt-4 rounded-md border border-[#E5E5E5]">
        <div className="grid grid-cols-[2fr_3fr_1fr] items-center border-b border-[#E5E5E5] p-3 pr-6">
          <span className="text-sm text-[#8D8D8D]">Document Name</span>
          <span className="text-sm text-[#8D8D8D]">Points Assigned</span>
        </div>

        {tenderDocuments.map((doc) => (
          <div className="grid grid-cols-[2fr_3fr_1fr] items-center border-b border-[#E5E5E5] p-3 pr-6">
            <span className="text-sm text-[#252C32]">{doc.documentName}</span>
            <span className="text-sm text-[#252C32]">{doc.assignPoint}</span>

            <div className="flex justify-end gap-x-3">
              <button
                className="border-b border-[#FA4D56] text-sm font-semibold text-[#FA4D56]"
                onClick={() => handleDeleteDocument(doc.tenderDocumentId)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="!mt-20 ml-auto flex w-full flex-col items-center justify-end gap-[18px] sm:flex-row">
        <button
          type="submit"
          className="transition-colorer:bg-tion-300 min-w-[195px] rounded-[10px] bg-[#FFC726] px-[22px] py-3.5 text-base font-semibold text-[#101828] hover:bg-[#FFB600] disabled:cursor-not-allowed disabled:bg-[#E0A900] disabled:text-[#B0B0B0]"
          disabled={isLoading}
          onClick={handleGoBack} // Save & Continue
        >
          Submit for Review
        </button>
      </div>

      {/* Modal */}
      {isAddModalOpen && (
        <div className="fixed inset-0 z-50 !mt-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="max-h-[65vh] w-2/3 overflow-auto rounded-[30px] bg-white px-[65px] py-[55px] xl:max-h-none">
            <div className="mb-8 flex items-center justify-between">
              <h3 className="text-base font-medium text-black">Request documents</h3>

              <button className="rounded-full bg-[#F5F5F5] p-1" onClick={handleCloseModal}>
                <XIcon className="h-4 w-4 text-[#1C1B1F]" />
              </button>
            </div>

            <Formik<CommercialsValues>
              initialValues={{
                documentName: '',
                assignPoint: '',
              }}
              validationSchema={ExpressionOfInterestValuesValidation}
              onSubmit={handleSubmitCommercials}
            >
              {({ setFieldValue }) => (
                <Form>
                  <FormikInput
                    label="Type of document"
                    name="documentName"
                    type="text"
                    placeholder="Licenses and Permits"
                    isRequired
                  />

                  <div className="flex justify-between">
                    <label className="custom-checkbox flex items-center">
                      <input
                        type="checkbox"
                        checked={pointsInputEnabled} // Bind to Formik value
                        onChange={(e) => {
                          setPointsInputEnabled(e.target.checked); // Update Formik value
                        }}
                      />
                      <span className="checkbox"></span>
                      <span className="label !text-sm !text-[#525252]">
                        Assign points to this requirement
                      </span>
                    </label>

                    <div className="flex items-center">
                      <FormikInput
                        name="assignPoint"
                        type="number"
                        placeholder="0"
                        inputClassName="mb-0 w-[70px]"
                        disabled={!pointsInputEnabled} // Disable input if checkbox is not checked
                        onChange={(e) => {
                          const value = Math.max(0, Math.min(100, Number(e.target.value))); // Clamp between 0 and 100
                          setFieldValue('assignPoint', value);
                        }}
                        isRequired
                      />
                      <span>/100</span>
                    </div>
                  </div>

                  <div className="mt-11 flex justify-end">
                    <button
                      type="submit"
                      className="w-[195px] rounded-[10px] bg-[#FFC726] px-[22px] py-3.5 text-base font-semibold text-[#101828] transition-colors duration-300 hover:bg-[#FFB600] disabled:cursor-not-allowed disabled:bg-[#E0A900] disabled:text-[#B0B0B0]"
                      disabled={isLoading}
                      onClick={() => setSaveAndContinue(true)} // Save & Continue
                    >
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default Commercials;
