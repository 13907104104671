import React, {
  useRef,
  RefObject,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import {
  createSupplier,
  getIndustries,
  getSupplierById,
  updateSupplier,
} from '../../../services/supplierService';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select, { ActionMeta, MultiValue } from 'react-select';
import PhoneInput from 'react-phone-input-2';
import {
  parsePhoneNumberFromString,
  isPossiblePhoneNumber,
  validatePhoneNumberLength,
  CountryCode,
} from 'libphonenumber-js';
import { getLookupType } from '../../../services/AccountServices';
import { CompanyRegisterFormValues } from '../../../types/interfaces';

interface OptionType {
  value: string;
  label: string;
}
interface FormComponentProps {
  isEditable: boolean;
  affiliates: any[];
  onFormSubmitSuccess: () => void;
  onValuesChange: (values: CompanyRegisterFormValues) => void;
}

const CompanyRegister = forwardRef<{ submitForm: () => void }, FormComponentProps>(
  ({ isEditable, onFormSubmitSuccess, onValuesChange }, ref) => {
    const { supplierId, setSupplierId } = useGlobalContext();
    const { t } = useTranslation();

    const commercialInputRef = useRef<HTMLInputElement>(null);
    const tinInputRef = useRef<HTMLInputElement>(null);
    const photoInputRef = useRef<HTMLInputElement>(null);
    const [selectedIndustries, setSelectedIndustries] = useState<OptionType[]>([]);
    const [industryOptions, setIndustryOptions] = useState<OptionType[]>([]);
    const [selectedTags, setSelectedTags] = useState<OptionType[]>([]);
    const [tagOptions, setTagOptions] = useState<OptionType[]>([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState('US');

    const location = useLocation();
    const isSupplier = location.state?.isSupplier;
    const urlParams = new URLSearchParams(window.location.search);
    const companyId = urlParams.get('supplierId');

    // Function to trigger the file input click
    const handleFileUploadClick = (inputRef: RefObject<HTMLInputElement>) => {
      if (inputRef.current) {
        inputRef.current.dispatchEvent(
          new MouseEvent('click', { bubbles: true, cancelable: true })
        );
      }
    };

    const formik = useFormik<CompanyRegisterFormValues>({
      initialValues: {
        // registrationMethod: '',
        country: '',
        registrationNumber: '',
        dateOfEstablishment: '',
        businessName: '',
        businessType: '',
        officeAddress1: '',
        officeAddress2: '',
        municipality: '',
        city: '',
        suburb: '',
        phoneNumber: '',
        website: '',
        email: '',
        supplierCategory: '',
        commercialRegCertFile: null,
        taxIdentificationFile: null,
        identificationFile: null,
        affiliates: '',
        facebookLink: '',
        twitterLink: '',
        linkedinLink: '',
        instagramLink: '',
        industryId: '',
        tags: [],
      },
      validationSchema: Yup.object({
        // registrationMethod: Yup.string().required(t('required', 'Required')),
        country: Yup.string().required(t('required', 'Required')),
        registrationNumber: Yup.string().required(t('required', 'Required')),
        businessName: Yup.string()
          .required(t('required', 'Required'))
          .min(5, t('minLength', 'Must be at least 5 characters')),
        dateOfEstablishment: Yup.string().required(t('required', 'Required')),
        officeAddress1: Yup.string().required(t('required', 'Required')),
        suburb: Yup.string().required(t('required', 'Required')),
        municipality: Yup.string().required(t('required', 'Required')),
        city: Yup.string().required(t('required', 'Required')),
        phoneNumber: Yup.string()
          .required(t('required', 'Required'))
          .test('isValidPhoneNumber', t('invalidPhoneNumber', 'Invalid phone number'), (value) => {
            if (!value) return false;
            const phoneNumber = parsePhoneNumberFromString(
              value,
              selectedCountryCode as CountryCode
            ); // Replace 'US' with your default country code
            if (!phoneNumber) {
              return false;
            }

            const isLengthValid = validatePhoneNumberLength(phoneNumber.number);

            return isLengthValid === undefined && isPossiblePhoneNumber(phoneNumber.number);
          }),
        // industries: Yup.array().required(t('required', 'Required')),
        website: Yup.string()
          .required(t('required', 'Required'))
          .matches(
            /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/\S*)?$/,
            t('invalidWebsite', 'Invalid website')
          ),
        email: Yup.string()
          .email(t('invalidEmail', 'Invalid email'))
          .required(t('required', 'Required')),
        tags: Yup.array().min(1, t('required', 'Required')),
      }),
      onSubmit: (values: CompanyRegisterFormValues) => {
        try {
          const payload: any = {
            // companyId: '',
            businessName: values.businessName,
            businessType: values.businessType,
            dateOfEstablishment: values.dateOfEstablishment
              ? new Date(values.dateOfEstablishment).toISOString()
              : new Date().toISOString(),
            streetName: values.officeAddress1,
            region: values.municipality,
            city: values.city,
            registrationNumber: values.registrationNumber,
            activate: true,
            town: values.suburb,
            phoneNumber: values.phoneNumber,
            country: values.country,
            website: values.website,
            email: values.email,
            supplierCategory: 'supplier',
            // supplierCategory: values.supplierCategory,
            // shareholders: [],
            // personalInformations: [],
            // awards: [],
            // companyDocuments: [],
            industryId: values.industryId,
            tags: values.tags?.length
              ? values.tags.map((tag) => ({
                  tagName: tag.label,
                  companyId: supplierId,
                }))
              : selectedTags.map((tag) => ({
                  tagName: tag.label,
                  companyId: supplierId,
                })),
          };

          if (supplierId) {
            payload.companyId = supplierId;
          } else {
            payload.tags = values.tags?.map((tag) => ({
              tagName: tag.label,
            }));
          }

          // delete payload.tags;
          const formData = new FormData();
          Object.keys(payload).forEach((key) => {
            if (payload[key as keyof typeof payload] !== undefined) {
              formData.append(key as string, payload[key as keyof typeof payload] as string | Blob);
            }
          });
          if (values.commercialRegCertFile) {
            formData.append('commercialRegCertFile', values.commercialRegCertFile);
          }
          if (values.taxIdentificationFile) {
            formData.append('taxIdentificationFile', values.taxIdentificationFile);
          }
          if (values.identificationFile) {
            formData.append('identificationFile', values.identificationFile);
          }

          if (values.tags && supplierId) {
            values.tags.forEach((item, index) => {
              // formData.append(`tags[${index}][tagId]`, item.value);
              formData.append(`tags[${index}][tagName]`, item.label);
              formData.append(`tags[${index}][companyId]`, supplierId);
            });
          }

          if (supplierId) {
            handleUpdate(payload);
          } else {
            handleSubmit(payload);
          }
        } catch {
          // toast.error(t('errorCreatingSupplier', 'Error creating supplier'));
        } finally {
          // setIsLoading(false);
        }
      },
    });

    const handleSubmit = async (formData: any) => {
      const response = await createSupplier(formData);
      if (response?.isSuccess) {
        setSupplierId(response?.data?.id);
        toast.success(response?.data?.message);
        onFormSubmitSuccess(); // Notify parent component on successful form submission
        getSupplierInformation();
      } else {
        toast.error(t('errorCreatingSupplier', 'Error creating supplier'));
      }
    };

    const handleUpdate = async (formData: any) => {
      const response = await updateSupplier(formData);
      if (response?.isSuccess) {
        setSupplierId(response?.data?.id);
        toast.success(response?.data?.message);
        onFormSubmitSuccess(); // Notify parent component on successful form submission
        getSupplierInformation();
      } else {
        //toast.error(t('errorUpdatingSupplier', 'Error updating supplier'));
      }
    };

    const handleIndustriesChange = (
      newValue: MultiValue<OptionType>,
      actionMeta: ActionMeta<OptionType>
    ) => {
      const selectedOptions = newValue as OptionType[];
      setSelectedIndustries(selectedOptions);
      formik.setFieldValue('industries', selectedOptions);
    };

    const handleTagChange = (
      newValue: MultiValue<OptionType>,
      actionMeta: ActionMeta<OptionType>
    ) => {
      const selectedOptions = newValue as OptionType[];
      setSelectedTags(selectedOptions);
      formik.setFieldValue('tags', selectedOptions);
    };

    // Use useImperativeHandle to expose the submitForm function to the parent component
    useImperativeHandle(ref, () => ({
      submitForm: () => {
        formik.submitForm();
      },
    }));

    const getSupplierInformation = async () => {
      try {
        const selectedSupplierId = isSupplier ? supplierId : companyId;
        if (!selectedSupplierId) return;
        const response = await getSupplierById(selectedSupplierId);
        if (response) {
          if (isSupplier) setSupplierId(response?.companyId);
          const supplierData = response;
          formik.setValues({
            businessName: supplierData.businessName,
            dateOfEstablishment: supplierData.dateOfEstablishment
              ? new Date(supplierData.dateOfEstablishment).toISOString().split('T')[0]
              : '',
            // registrationMethod: '',
            country: supplierData.country ? supplierData.country : '',
            businessType: supplierData.businessType ? supplierData.businessType : '',
            streetName: supplierData.streetName,
            officeAddress1: supplierData.streetName,
            officeAddress2: supplierData.officeAddress2,
            region: supplierData.region,
            municipality: supplierData.region,
            city: supplierData.city,

            suburb: supplierData.town,
            phoneNumber: supplierData.phoneNumber,
            website: supplierData.website,
            email: supplierData.email,
            registrationNumber: supplierData.registrationNumber,
            industryId: supplierData.industryId,
            // tags: supplierData.tags?.map((tag: any) => {
            //   const matchingOption = tagOptions.find(
            //     (option) => option.label === tag.tagName
            //   );

            //   return matchingOption ? matchingOption : { value: '', label: '' };
            // }),
          });

          const parsedNumber = parsePhoneNumberFromString(`+${supplierData.phoneNumber}`);
          setSelectedCountryCode(parsedNumber?.country || 'US');
          if (supplierData.tags) {
            setSelectedTags(
              supplierData.tags.map((tag: any) => ({
                value: tag.tagName,
                label: tag.tagName,
              }))
            );
          }

          onValuesChange(supplierData);
        }
      } catch (error) {
        toast.error(t('errorGettingSupplierInformation', 'Error getting supplier information'));
      }
    };

    const getSupplierIndustries = async () => {
      const response = await getIndustries();
      if (response?.isSuccess) {
        setIndustryOptions(
          response.data.map((industry: any) => ({
            value: industry.industryId,
            label: industry.industryName,
          }))
        );
      }
    };
    const getSupplierTags = async () => {
      const response = await getLookupType('tags');
      if (response?.isSuccess) {
        setTagOptions(
          response.data.map((lookup: any) => ({
            value: lookup.lookupId,
            label: lookup.lookupValue,
          }))
        );
      }
    };

    useEffect(() => {
      getSupplierIndustries();
      getSupplierTags();
      getSupplierInformation();
    }, []);

    return (
      <>
        <h1 className="text-xl flex justify-start font-medium text-center ml-0 m-6">
          {t('fillOutCompanyInformation', 'Fill out your company’s information')}
        </h1>
        <div className="divider my-10 mb-4"></div>

        <form onSubmit={(e) => e.preventDefault()} className="flex flex-col gap-7">
          <div className="flex gap-4 "></div>

          <div className=" flex gap-4">
            <div className="form-group w-[50%]">
              <label
                className=" text-base  font-medium text-gray-500 font-500"
                htmlFor="businessName"
              >
                {t('businessName', 'Business Name')}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className="h-[3.5rem]"
                id="businessName"
                name="businessName"
                disabled={!isEditable}
                onChange={formik.handleChange}
                value={formik.values.businessName}
              />
              {formik.touched.businessName && formik.errors.businessName && (
                <span className=" text-red-500 error">{formik.errors.businessName}</span>
              )}
            </div>
            <div className="form-group w-[50%]">
              <label
                className=" text-base  font-medium text-gray-500 font-500"
                htmlFor="registrationNumber"
              >
                {t('commercialRegNo', 'Commercial Reg. No')}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className="h-[3.5rem]"
                id="registrationNumber"
                name="registrationNumber"
                onChange={formik.handleChange}
                disabled={!isEditable}
                value={formik.values.registrationNumber}
              />
              {formik.touched.registrationNumber && formik.errors.registrationNumber && (
                <span className=" text-red-500 error">{formik.errors.registrationNumber}</span>
              )}
            </div>{' '}
          </div>

          <div className="flex gap-4">
            <div className="form-group w-[33%]">
              <label
                className=" text-base  font-medium text-gray-500 font-500"
                htmlFor="businessType"
              >
                {t('businessType', 'Business Type')}
                {/* <span className="text-red-500">*</span> */}
              </label>
              <select
                id="businessType"
                name="businessType"
                disabled={!isEditable}
                onChange={formik.handleChange}
                value={formik.values.businessType}
                className="w-full text-base border border-[#cccccc] h-[3.5rem] rounded-md focus:outline-none focus:border-gray-700"
              >
                <option value="">{t('select', 'Select')}</option>
                <option value="sole-proprietorship">
                  {t('soleProprietorship', 'Sole Proprietorship')}
                </option>
                <option value="joint-venture">{t('jointVenture', 'Joint Venture')}</option>
                <option value="limited-liability">
                  {t('limitedLiability', 'Limited Liability')}
                </option>
              </select>
              {formik.touched.businessType && formik.errors.businessType && (
                <span className=" text-red-500 error">{formik.errors.businessType}</span>
              )}
            </div>
            <div className="form-group w-[33%]">
              <label
                className=" text-base  font-medium text-gray-500 font-500"
                htmlFor="dateOfEstablishment"
              >
                {t('dateOfEstablishment', 'Date of Establishment')}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                className="h-[3.5rem]"
                id="dateOfEstablishment"
                name="dateOfEstablishment"
                disabled={!isEditable}
                onChange={formik.handleChange}
                value={formik.values.dateOfEstablishment}
                max={new Date().toISOString().split('T')[0]} // Disable future dates
              />
              {formik.touched.dateOfEstablishment && formik.errors.dateOfEstablishment && (
                <span className=" text-red-500 error">{formik.errors.dateOfEstablishment}</span>
              )}
            </div>
            <div className="form-group w-[33%]">
              <label className=" text-base  font-medium text-gray-500 font-500" htmlFor="country">
                {t('country', 'Country')}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className="h-[3.5rem]"
                id="country"
                name="country"
                disabled={!isEditable}
                onChange={formik.handleChange}
                value={formik.values.country}
              />
              {formik.touched.country && formik.errors.country && (
                <span className=" text-red-500 error">{formik.errors.country}</span>
              )}
            </div>
          </div>

          <div className="flex gap-4">
            <div className="form-group w-[50%]">
              <label className=" text-base  font-medium text-gray-500 font-500" htmlFor="industry">
                {t('industry', 'Industry')}
                <span className="text-red-500">*</span>
              </label>
              <select
                id="industryId"
                name="industryId"
                disabled={!isEditable}
                onChange={formik.handleChange}
                value={formik.values.industryId}
                className="w-full text-base border border-[#cccccc] h-[3.5rem] rounded-md focus:outline-none focus:border-gray-700"
              >
                <option value="">{t('select', 'Select')}</option>
                {industryOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group w-[50%]">
              <label className=" text-base  font-medium text-gray-500 font-500" htmlFor="tags">
                {t('tags', 'Tags')}
                <span className="text-red-500">*</span>
              </label>
              <Select
                id="tags"
                isMulti
                options={tagOptions}
                value={selectedTags
                  .map((tag) => {
                    return tagOptions.find((option) => option.label === tag.label);
                  })
                  .filter((option): option is OptionType => option !== undefined)}
                onChange={handleTagChange}
              />
            </div>
          </div>

          <div className="divider my-10 mb-4"></div>
          <div>
            {/* Office Address */}
            <div className=" flex gap-4">
              <div className="form-group w-[50%]">
                <label
                  className=" text-base  font-medium text-gray-500 font-500"
                  htmlFor="officeAddress1"
                >
                  {t('officeAddressLine1', 'Office Address Line 1')}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className="h-[3.5rem]"
                  id="officeAddress1"
                  name="officeAddress1"
                  disabled={!isEditable}
                  onChange={formik.handleChange}
                  value={formik.values.officeAddress1}
                />
                {formik.touched.officeAddress1 && formik.errors.officeAddress1 && (
                  <span className=" text-red-500 error">{formik.errors.officeAddress1}</span>
                )}
              </div>

              <div className="form-group w-[50%]">
                <label
                  className=" text-base  font-medium text-gray-500 font-500"
                  htmlFor="officeAddress2"
                >
                  {t('officeAddressLine2', 'Office Address Line 2')}
                </label>
                <input
                  type="text"
                  className="h-[3.5rem]"
                  id="officeAddress2"
                  disabled={!isEditable}
                  name="officeAddress2"
                  onChange={formik.handleChange}
                  value={formik.values.officeAddress2}
                />
              </div>
            </div>
          </div>

          {/* Municipality and City */}
          <div className=" flex gap-4">
            <div className="form-group w-[33%]">
              <label
                className=" text-base  font-medium text-gray-500 font-500"
                htmlFor="municipality"
              >
                {t('municipalityRegion', 'Municipality/Region')}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className="h-[3.5rem]"
                id="municipality"
                disabled={!isEditable}
                name="municipality"
                onChange={formik.handleChange}
                value={formik.values.municipality}
              />
              {formik.touched.municipality && formik.errors.municipality && (
                <span className=" text-red-500 error">{formik.errors.municipality}</span>
              )}
            </div>

            <div className="form-group w-[33%]">
              <label className=" text-base  font-medium text-gray-500 font-500" htmlFor="city">
                {t('postAdministrativeCity', 'Post Administrative/City')}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className="h-[3.5rem]"
                id="city"
                name="city"
                disabled={!isEditable}
                onChange={formik.handleChange}
                value={formik.values.city}
              />
              {formik.touched.city && formik.errors.city && (
                <span className=" text-red-500 error">{formik.errors.city}</span>
              )}
            </div>
            <div className="form-group w-[33%]">
              <label className=" text-base  font-medium text-gray-500 font-500" htmlFor="city">
                {t('suburbOrTown', 'Suburb or Town')}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className="h-[3.5rem]"
                id="suburb"
                name="suburb"
                onChange={formik.handleChange}
                disabled={!isEditable}
                value={formik.values.suburb}
              />
              {formik.touched.suburb && formik.errors.suburb && (
                <span className=" text-red-500 error">{formik.errors.suburb}</span>
              )}
            </div>
          </div>
          <div className=" flex gap-4">
            <div className="form-group w-[33%]">
              <label
                className=" text-base  font-medium text-gray-500 font-500"
                htmlFor="phoneNumber"
              >
                {t('phone_Number', 'Phone Number')}
                <span className="text-red-500">*</span>
              </label>
              <PhoneInput
                containerStyle={{ width: '100%' }}
                inputStyle={{ width: '100%', height: '3.5rem' }}
                country={'us'}
                value={formik.values.phoneNumber}
                onChange={(value, country: any) => {
                  formik.setFieldValue('phoneNumber', value);
                  setSelectedCountryCode(country.countryCode.toUpperCase());
                }}
                disabled={!isEditable}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <span className=" text-red-500 error">{formik.errors.phoneNumber}</span>
              )}
            </div>

            <div className="form-group w-[33%]">
              <label className=" text-base  font-medium text-gray-500 font-500" htmlFor="email">
                {t('email', 'Email')}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className="h-[3.5rem]"
                id="email"
                name="email"
                disabled={!isEditable}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <span className=" text-red-500 error">{formik.errors.email}</span>
              )}
            </div>
            <div className="form-group w-[33%]">
              <label className=" text-base  font-medium text-gray-500 font-500" htmlFor="website">
                {t('website', 'Website')}
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                className="h-[3.5rem]"
                id="website"
                name="website"
                placeholder="https://www.example.com"
                disabled={!isEditable}
                onChange={formik.handleChange}
                value={formik.values.website}
              />
              {formik.touched.website && formik.errors.website && (
                <span className=" text-red-500 error">{formik.errors.website}</span>
              )}
            </div>
          </div>
          <div className="divider my-10 mb-4"></div>
          <div className="flex flex-col gap-10">
            <div className="mt-4">
              <h1 className=" text-[18px] font-medium ">
                {t('socialMediaOptional', 'Social Media (Optional)')}
              </h1>
            </div>

            <div className=" flex gap-4">
              <div className="form-group w-[50%]">
                <input
                  placeholder="Enter your facebook link"
                  type="text"
                  className="h-[3.5rem]"
                  id="facebookLink"
                  name="facebookLink"
                  disabled={!isEditable}
                  onChange={formik.handleChange}
                  value={formik.values.facebookLink}
                />
                {formik.touched.facebookLink && formik.errors.facebookLink && (
                  <span className=" text-red-500 error">{formik.errors.facebookLink}</span>
                )}
              </div>

              <div className="form-group w-[50%]">
                <input
                  placeholder="Enter your twitter link"
                  type="text"
                  className="h-[3.5rem]"
                  id="twitterLink"
                  name="twitterLink"
                  disabled={!isEditable}
                  onChange={formik.handleChange}
                  value={formik.values.twitterLink}
                />
              </div>
            </div>

            <div className=" flex gap-4">
              <div className="form-group w-[50%]">
                <input
                  placeholder="Enter your Linkedin link"
                  type="text"
                  className="h-[3.5rem]"
                  id="linkedinLink"
                  name="linkedinLink"
                  disabled={!isEditable}
                  onChange={formik.handleChange}
                  value={formik.values.linkedinLink}
                />
                {formik.touched.linkedinLink && formik.errors.linkedinLink && (
                  <span className=" text-red-500 error">{formik.errors.linkedinLink}</span>
                )}
              </div>

              <div className="form-group w-[50%]">
                <input
                  placeholder="Enter your Instagram link"
                  type="text"
                  className="h-[3.5rem]"
                  id="instagramLink"
                  name="instagramLink"
                  disabled={!isEditable}
                  onChange={formik.handleChange}
                  value={formik.values.instagramLink}
                />
              </div>
            </div>
          </div>

          {/* Submit Button */}
          {/* <div className="button-container">
          {isEditable ? (
            <AppButton
              title={t('submit', 'Submit')}
              className="text-lg font-semibold bg-[#FFC726] rounded-lg w-[92px] h-[36px]"
              onClick={() => formik.submitForm()} // Submits the form when editable
            />
          ) : null}
        </div> */}
        </form>
      </>
    );
  }
);

export default CompanyRegister;
