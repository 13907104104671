import { Endpoint } from './endpoint/endpoint';
import axiosInstance from '../axiosInstance';
import {
  ProcurementProject,
  ProcurementProposal,
  ProcurementProposalWithTag,
  SupplierTender,
  TenderDocument,
  TenderGoodsAndServices,
} from '../types/procurement-types';

type ApiResponse<T = []> = {
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  data: T;
};

export const getAProcurement = async (projectId: any) => {
  const response = await axiosInstance.get(`${Endpoint.procurement.getProcurement}/${projectId}`);
  return response.data;
};

export const createAProcurement = async (updatedFormData: any) => {
  const response = await axiosInstance.post(Endpoint.procurement.create, updatedFormData);
  return response.data;
};

export const editAProcurement = async (updatedFormData: any) => {
  const response = await axiosInstance.post(Endpoint.procurement.edit, updatedFormData);
  return response.data;
};

export const updateTenderStatus = async (serviceInfoData: {
  serviceInformationId: string;
  status: number;
}) => {
  const response = await axiosInstance.patch(
    Endpoint.procurement.updateTenderStatus,
    serviceInfoData
  );
  return response.data;
};

export const createTenderGoodsAndServices = async (payload: any) => {
  const response = await axiosInstance.post(Endpoint.procurement.createGoodsAndServices, payload);
  return response.data;
};

export const editTenderGoodsAndServices = async (payload: any) => {
  const response = await axiosInstance.post(Endpoint.procurement.editGoodsAndServices, payload);
  return response.data;
};

export const deleteTenderGoodsAndServices = async (tenderGoodsServicesId: string) => {
  const response = await axiosInstance.delete(
    `${Endpoint.procurement.deleteGoodsAndServices}/${tenderGoodsServicesId}`
  );
  return response.data;
};

export const listTenderGoodsAndServices = async (
  serviceInformationId: any
): Promise<ApiResponse<TenderGoodsAndServices[]>> => {
  const response = await axiosInstance.get<ApiResponse<TenderGoodsAndServices[]>>(
    `${Endpoint.procurement.listGoodsAndServices}/${serviceInformationId}`,
    {
      params: {
        pageNumber: 1,
        pageSize: 1000,
      },
    }
  );

  return response.data;
};

export const createTenderDocument = async (payload: any) => {
  const response = await axiosInstance.post(Endpoint.procurement.createTenderDocument, payload);
  return response.data;
};

export const deleteTenderDocument = async (tenderDocumentId: string) => {
  const response = await axiosInstance.delete(
    `${Endpoint.procurement.deleteTenderDocument}/${tenderDocumentId}`
  );
  return response.data;
};

export const listTenderDocuments = async (
  serviceInformationId: any
): Promise<ApiResponse<TenderDocument[]>> => {
  const response = await axiosInstance.get<ApiResponse<TenderDocument[]>>(
    `${Endpoint.procurement.listTenderDocuments}/${serviceInformationId}`,
    {
      params: {
        pageNumber: 1,
        pageSize: 1000,
      },
    }
  );

  return response.data;
};

export const getAllProcurements = async (
  statusMap: any,
  activeStatus: any
): Promise<ApiResponse<ProcurementProject[]>> => {
  const response = await axiosInstance.get<ApiResponse<ProcurementProject[]>>(
    `${Endpoint.procurement.getAllProcurements}`,
    {
      params: {
        status: statusMap[activeStatus as keyof typeof statusMap],
        pageNumber: 1,
        pageSize: 1000,
      },
    }
  );

  return response.data;
};

export const deleteProcurement = async (serviceInformationId: string) => {
  const response = await axiosInstance.delete(
    `${Endpoint.procurement.deleteProcurement}/${serviceInformationId}`
  );

  return response.data;
};

export const getSupplierTenders = async (
  supplierId: string
): Promise<ApiResponse<SupplierTender[]>> => {
  const response = await axiosInstance.get<ApiResponse<SupplierTender[]>>(
    `${Endpoint.procurement.getSupplierTenders}`,
    {
      params: {
        supplierId,
        sort: 0, // TODO: make dynamic
        pageNumber: 1,
        pageSize: 1000,
      },
    }
  );

  return response.data;
};

export const getProcurementProposals = async (): Promise<ApiResponse<ProcurementProposal[]>> => {
  const response = await axiosInstance.get<ApiResponse<ProcurementProposal[]>>(
    `${Endpoint.procurement.getProcurementProposals}`,
    {
      params: {
        pageNumber: 1,
        pageSize: 1000,
      },
    }
  );

  return response.data;
};

export const getSingleProcurementProposal = async (queryParam: {
  serviceInformationId: string;
  isView: boolean;
  isProposal: boolean;
}): Promise<ApiResponse<ProcurementProposalWithTag>> => {
  const response = await axiosInstance.get<ApiResponse<ProcurementProposalWithTag>>(
    `${Endpoint.procurement.getProcurementProposalById}`,
    {
      params: {
        ...queryParam,
        pageNumber: 1,
        pageSize: 1000,
      },
    }
  );

  return response.data;
};

export const requestTenderAccess = async (payload: {
  serviceInformationId: string;
  supplierId: string;
  isAccessRequest: boolean;
}) => {
  const response = await axiosInstance.post(Endpoint.procurement.requestAccess, payload);
  return response.data;
};

export const UnpublishAProcurement = async (projectId: string) => {
  const response = await axiosInstance.put(`${Endpoint.procurement.unpublishProcurement}`, {
    procurementProjectId: projectId,
  });
  return response.data;
};

export const getIndustries = async () => {
  const response = await axiosInstance.get(`${Endpoint.procurement.getIndustries}`);
  return response.data;
};

export const getSupplierTypes = async () => {
  const response = await axiosInstance.get(`${Endpoint.procurement.getSupplierTypes}`);
  return response.data;
};

export const getBusinessTypes = async () => {
  const response = await axiosInstance.get(`${Endpoint.procurement.getBusinessTypes}`);
  return response.data;
};

export const getTags = async () => {
  const response = await axiosInstance.get(`${Endpoint.procurement.getSuppliersTags}`);
  return response.data;
};

export const initiateWorkFlow = async (workFlowData: any) => {
  const response = await axiosInstance.post(Endpoint.procurement.initiateWorkFlow, workFlowData);
  return response.data;
};

export const initiateWorkFlowApproval = async (workFlowData: any) => {
  const response = await axiosInstance.post(
    Endpoint.procurement.initiateWorkFlowApproval,
    workFlowData
  );
  return response.data;
};
