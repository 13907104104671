import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import AppButton from './AppButton/AppButton';
import { useGlobalContext } from '../contexts/GlobalContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const MicrosoftLoginButton: React.FC = () => {
  const { instance, accounts } = useMsal();
  const { login } = useGlobalContext();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await instance.loginPopup(loginRequest);
      const account = response.account;
      // console.log('microsoft', response);

      if (account) {
        localStorage.setItem('userId', account.localAccountId);
        localStorage.setItem('userName', account.username);
        localStorage.setItem('accessToken', response.accessToken);
        // localStorage.setItem('refreshToken', response.refreshToken);
        localStorage.setItem('email', account.username);
        localStorage.setItem('fullName', account.name || '');
        login(null, 'Regulator');
        toast.success('Login successful');
        navigate('/application-module');
      }
    } catch (error) {
      // console.error(error);
      toast.error('Login failed. Please try again later.');
    }
  };

  return (
    <AppButton
      leftIcon={
        <img src={`${process.env.PUBLIC_URL}/assets/images/microsoft_365.svg`} alt="microsoft" />
      }
      className="flex gap-1.5 items-center justify-center w-full min-w-[142px] text-xs border border-[#dadce0] py-2 px-1 rounded-md"
      title="Microsoft 365"
      onClick={handleLogin}
    />
  );
};

export default MicrosoftLoginButton;
