import React from 'react';
import { useTranslation } from 'react-i18next';
import './AppButton.css';

type AppBtn = {
  title: string | React.ReactNode;
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
  outline?: boolean;
  danger?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  type?: 'button' | 'submit';
};

const AppButton: React.FC<AppBtn> = ({
  title,
  onClick,
  isLoading,
  disabled,
  style,
  className,
  outline,
  danger,
  leftIcon,
  rightIcon,
  type,
}) => {
  const { t } = useTranslation();

  const getBtnClass = () => {
    let btnClass = 'app-btn';
    if (outline) btnClass += ' outline';
    if (danger) btnClass += ' danger';
    return btnClass;
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={style}
      aria-disabled={isLoading}
      className={getBtnClass() + ' ' + className}
      type={type}
    >
      {isLoading ? (
        <span className="loading">{t('loading', 'Loading...')}</span>
      ) : (
        <>
          {leftIcon && <span className="icon-left">{leftIcon}</span>}
          <span>{typeof title === 'string' ? t(title) : title}</span>
          {rightIcon && <span className="icon-right">{rightIcon}</span>}
        </>
      )}
    </button>
  );
};

export default AppButton;
