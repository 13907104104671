import React, { useRef, RefObject, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGlobalContext } from '../../../../contexts/GlobalContext';
import {
  createSupplier,
  getIndustries,
  updateSupplier,
} from '../../../../services/supplierService';
import { toast } from 'react-toastify';
import { useSSR, useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import Select, { ActionMeta, MultiValue } from 'react-select';
import {
  parsePhoneNumberFromString,
  isPossiblePhoneNumber,
  validatePhoneNumberLength,
  CountryCode,
} from 'libphonenumber-js';
import { OptionType } from '../../../Configurations/RoleManagement/RolePermissionModal';
import { CompanyRegisterFormValues } from '../../../../types/interfaces';

interface ProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: CompanyRegisterFormValues | null;
  affiliateIndex?: number | null;
  isEditable: boolean;
}

const AffiliateModal: React.FC<ProjectModalProps> = ({
  isOpen,
  onClose,
  affiliateIndex,
  initialValues,
  isEditable,
}) => {
  const { t } = useTranslation();
  const { setIsOpen } = useGlobalContext();
  const { supplierId } = useGlobalContext();
  const [selectedIndustries, setSelectedIndustries] = useState<OptionType[]>([]);
  const [industryOptions, setIndustryOptions] = useState<OptionType[]>([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState('US');

  // const { isLoading, setIsLoading } = useGlobalContext();
  // const fileInputRef = useRef<HTMLInputElement>(null);
  const commercialRegCertRef = useRef<HTMLInputElement>(null);
  const tinCertRef = useRef<HTMLInputElement>(null);
  const anotherTinCertRef = useRef<HTMLInputElement>(null);

  const validationSchema = Yup.object().shape({
    businessName: Yup.string().required('Business Name is required'),
    dateOfEstablishment: Yup.string().required('Date of Establishment is required'),
    // typeOfBusiness: Yup.string().required('Type of Business is required'),
    country: Yup.string().required('Country is required'),
    registrationNumber: Yup.string().required('Commercial Registration No is required'),
    streetName: Yup.string().required('Office Address Line 1 is required'),
    // industries: Yup.array().required('Industries is required'),
    region: Yup.string().required('Region is required'),
    city: Yup.string().required('City is required'),
    suburb: Yup.string().required('Suburb is required'),
    phoneNumber: Yup.string()
      .required(t('required', 'Required'))
      .test('isValidPhoneNumber', t('invalidPhoneNumber', 'Invalid phone number'), (value) => {
        if (!value) return false;
        const phoneNumber = parsePhoneNumberFromString(value, selectedCountryCode as CountryCode); // Replace 'US' with your default country code
        if (!phoneNumber) {
          return false;
        }

        const isLengthValid = validatePhoneNumberLength(phoneNumber.number);

        return isLengthValid === undefined && isPossiblePhoneNumber(phoneNumber.number);
      }),
    website: Yup.string()
      .required(t('required', 'Required'))
      .matches(
        /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/\S*)?$/,
        t('invalidWebsite', 'Invalid website')
      ),
    email: Yup.string().email('Invalid Email').required('Email is required'),
  });

  // Function to trigger the file input click
  const handleFileUploadClick = (inputRef: RefObject<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true }));
    }
  };

  const formik = useFormik<CompanyRegisterFormValues>({
    initialValues: initialValues
      ? {
          ...initialValues,
          dateOfEstablishment: initialValues.dateOfEstablishment
            ? new Date(initialValues.dateOfEstablishment).toISOString().split('T')[0]
            : '',
          suburb: initialValues.town,
        }
      : {
          businessName: '',
          dateOfEstablishment: '',
          typeOfBusiness: '',
          country: '',
          registrationNumber: '',
          streetName: '',
          officeAddress2: '',
          region: '',
          city: '',
          suburb: '',
          phoneNumber: '',
          website: '',
          email: '',
          industryId: '',
        },
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const payload = {
        companyId: values.companyId,
        businessName: values.businessName,
        dateOfEstablishment: values.dateOfEstablishment
          ? new Date(values.dateOfEstablishment).toISOString()
          : new Date().toISOString(),
        streetName: values.streetName,
        region: values.region,
        city: values.city,
        registrationNumber: values.registrationNumber,
        activate: true,
        town: values.suburb,
        phoneNumber: values.phoneNumber,
        website: values.website,
        typeOfBusiness: values.typeOfBusiness,
        country: values.country,
        email: values.email,
        supplierCategory: 'affiliate',
        parentCompanyId: supplierId,
        industryId: values.industryId,
      };

      if (!initialValues) {
        delete payload.companyId;
      }

      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        if (payload[key as keyof typeof payload] !== undefined) {
          formData.append(key as string, payload[key as keyof typeof payload] as string | Blob);
        }
      });
      if (values.commercialRegCertFile) {
        formData.append('commercialRegCertFile', values.commercialRegCertFile);
      }
      if (values.taxIdentificationFile) {
        formData.append('taxIdentificationFile', values.taxIdentificationFile);
      }
      if (values.identificationFile) {
        formData.append('identificationFile', values.identificationFile);
      }

      if (initialValues) {
        updateAffiliate(payload);
      } else {
        createAffiliate(payload);
      }
    },
  });

  const createAffiliate = async (payload: any) => {
    try {
      const response = await createSupplier(payload);
      if (response?.isSuccess) {
        toast.success(response?.data?.message);
        onClose();
        setIsOpen(false);
      }
    } catch {
      toast.error(t('failedToCreateSupplierAffiliate', 'Failed to create supplier affiliate'));
    }
  };

  const updateAffiliate = async (payload: any) => {
    try {
      const response = await updateSupplier(payload);
      if (response?.isSuccess) {
        toast.success(response?.data?.message);
        onClose();
        setIsOpen(false);
      }
    } catch {
      toast.error(t('failedToCreateSupplierAffiliate', 'Failed to create supplier affiliate'));
    }
  };

  const handleIndustriesChange = (
    newValue: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    const selectedOptions = newValue as OptionType[];
    setSelectedIndustries(selectedOptions);
    formik.setFieldValue('industries', selectedOptions);
  };

  const getSupplierIndustries = async () => {
    const response = await getIndustries();
    if (response?.isSuccess) {
      setIndustryOptions(
        response.data.map((industry: any) => ({
          value: industry.industryId,
          label: industry.industryName,
        }))
      );
    }
  };

  useEffect(() => {
    if (initialValues) {
      const parsedNumber = parsePhoneNumberFromString(`+${initialValues.phoneNumber}`);
      setSelectedCountryCode(parsedNumber?.country || 'US');
    }
  }, []);

  useEffect(() => {
    getSupplierIndustries();
  }, []);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-6xl scrollable-modal">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">
                {t('addAffiliatesAndSubsidiaries', 'Add Affiliates and Subsidiaries')}
              </h2>
              <button className="text-red-500 text-xl" onClick={onClose}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/close.svg`}
                  alt={t('close', 'close')}
                />
              </button>
            </div>

            {/* Divider */}
            <div className="col-span-6 divider my-6 !bg-[#E5E5E5]"></div>

            <div className="">
              <div className="space-y-8">
                {/* Row 1: Business Name, Commercial Reg No */}
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block mb-1">
                      {t('businessName', 'Business Name')}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      value={formik.values.businessName}
                      onChange={formik.handleChange('businessName')}
                      disabled={!isEditable}
                      name="businessName"
                      className="w-full border rounded-lg text-sm p-2 h-11"
                      placeholder={t('enterBusinessName', 'Enter business name')}
                    />
                    {formik.touched.businessName && formik.errors.businessName && (
                      <span className="text-red-500 text-sm">{formik.errors.businessName}</span>
                    )}
                  </div>

                  <div>
                    <label className="block mb-1">
                      {t('commercialRegNo', 'Commercial Reg. No')}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      value={formik.values.registrationNumber}
                      onChange={formik.handleChange('registrationNumber')}
                      disabled={!isEditable}
                      name="registrationNumber"
                      className="w-full border rounded-lg text-sm p-2 h-11"
                      placeholder={t('commercialRegNo', 'Commercial Reg. No')}
                    />
                    {formik.touched.registrationNumber && formik.errors.registrationNumber && (
                      <span className="text-red-600">{formik.errors.registrationNumber}</span>
                    )}
                  </div>
                </div>

                {/* Row 2: Date of Establishment, Type of Business, Country */}
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <label className="block mb-1">
                      {t('dateOfEstablishment', 'Date of Establishment')}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      value={formik.values.dateOfEstablishment}
                      onChange={formik.handleChange('dateOfEstablishment')}
                      type="date"
                      disabled={!isEditable}
                      name="dateOfEstablishment"
                      className="w-full border rounded-lg p-2 h-11"
                      max={new Date().toISOString().split('T')[0]} // Disable future dates
                    />
                    {formik.touched.dateOfEstablishment && formik.errors.dateOfEstablishment && (
                      <span className="text-red-600">{formik.errors.dateOfEstablishment}</span>
                    )}
                  </div>
                  <div>
                    <label className="block mb-1">
                      {t('typeOfBusiness', 'Type of Business')}
                      {/* <span className="text-red-500">*</span> */}
                    </label>
                    <select
                      id="typeOfBusiness"
                      name="typeOfBusiness"
                      disabled={!isEditable}
                      onChange={formik.handleChange}
                      value={formik.values.typeOfBusiness}
                      className="w-full text-base border border-[#cccccc] h-11 rounded-md focus:outline-none focus:border-gray-700"
                    >
                      <option value="">{t('select', 'Select')}</option>
                      <option value="sole-proprietorship">
                        {t('soleProprietorship', 'Sole Proprietorship')}
                      </option>
                      <option value="joint-venture">{t('jointVenture', 'Joint Venture')}</option>
                      <option value="limited-liability">
                        {t('limitedLiability', 'Limited Liability')}
                      </option>
                    </select>
                    {formik.touched.typeOfBusiness && formik.errors.typeOfBusiness && (
                      <span className="text-red-600">{formik.errors.typeOfBusiness}</span>
                    )}
                  </div>
                  <div>
                    <label className="block mb-1">
                      {t('country', 'country')}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      value={formik.values.country}
                      onChange={formik.handleChange('country')}
                      disabled={!isEditable}
                      name="country"
                      className="w-full border rounded-lg text-sm p-2 h-11"
                      placeholder={t('country', 'country')}
                    />
                    {formik.touched.country && formik.errors.country && (
                      <span className="text-red-600">{formik.errors.country}</span>
                    )}
                  </div>
                </div>
                {/* Row 3: Industries, Commercial Reg No, Office Address Line 1 */}
                <div className="grid grid-cols-1 gap-4">
                  <div>
                    <label className="block mb-1" htmlFor="industry">
                      {t('industry', 'Industry')}
                      <span className="text-red-500">*</span>
                    </label>
                    {/* <Select
                      id="industries"
                      isMulti
                      options={industryOptions}
                      value={selectedIndustries}
                      onChange={handleIndustriesChange}
                      className="!min-h-[27.5px]"
                      isDisabled={!isEditable}
                    /> */}
                    <select
                      id="industryId"
                      name="industryId"
                      disabled={!isEditable}
                      onChange={formik.handleChange}
                      value={formik.values.industryId}
                      className="w-full text-base border border-[#cccccc] h-[3.5rem] rounded-md focus:outline-none focus:border-gray-700"
                    >
                      <option value="">{t('select', 'Select')}</option>
                      {industryOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Row 4: Region, City, Suburb */}
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <label className="block mb-1">
                      {t('region', 'Region')}
                      <span className="text-red-500">*</span>
                    </label>
                    <select
                      value={formik.values.region}
                      onChange={formik.handleChange('region')}
                      disabled={!isEditable}
                      name="region"
                      className="w-full border rounded-lg p-2 h-11"
                    >
                      <option value="">{t('selectRegion', 'Select Region')}</option>
                      <option value="north">{t('north', 'North')}</option>
                      <option value="south">{t('south', 'South')}</option>
                      <option value="east">{t('east', 'East')}</option>
                      <option value="west">{t('west', 'West')}</option>
                    </select>
                    {formik.touched.region && formik.errors.region && (
                      <span className="text-red-600">{formik.errors.region}</span>
                    )}
                  </div>

                  <div>
                    <label className="block mb-1">
                      {t('city', 'City')}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      value={formik.values.city}
                      onChange={formik.handleChange('city')}
                      disabled={!isEditable}
                      name="city"
                      className="w-full border rounded-lg text-sm p-2 h-11"
                      placeholder={t('enterCity', 'Enter city')}
                    />
                    {formik.touched.city && formik.errors.city && (
                      <span className="text-red-600">{formik.errors.city}</span>
                    )}
                  </div>

                  <div>
                    <label className="block mb-1">
                      {t('suburb', 'Suburb')}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      value={formik.values.suburb}
                      onChange={formik.handleChange('suburb')}
                      disabled={!isEditable}
                      name="suburb"
                      className="w-full border rounded-lg text-sm p-2 h-11"
                      placeholder={t('enterSuburb', 'Enter Suburb')}
                    />
                    {formik.touched.suburb && formik.errors.suburb && (
                      <span className="text-red-600">{formik.errors.suburb}</span>
                    )}
                  </div>
                </div>

                {/* Row 5: Office Address Line 1, Office Address Line 2 */}
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block mb-1">
                      {t('officeAddressLine1', 'Office Address Line 1')}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      value={formik.values.streetName}
                      onChange={formik.handleChange('streetName')}
                      disabled={!isEditable}
                      name="streetName"
                      className="w-full border rounded-lg text-sm p-2 h-11"
                      placeholder={t('enterAddressLine1', 'Enter address line 1')}
                    />
                    {formik.touched.streetName && formik.errors.streetName && (
                      <span className="text-red-600">{formik.errors.streetName}</span>
                    )}
                  </div>
                  <div>
                    <label className="block mb-1">
                      {t('officeAddressLine2', 'Office Address Line 2')}
                    </label>
                    <input
                      value={formik.values.officeAddress2}
                      onChange={formik.handleChange('officeAddress2')}
                      disabled={!isEditable}
                      name="officeAddress2"
                      className="w-full border rounded-lg text-sm p-2 h-11"
                      placeholder={t('enterAddressLine2', 'Enter address line 2')}
                    />
                    {formik.touched.officeAddress2 && formik.errors.officeAddress2 && (
                      <span className="text-red-600">{formik.errors.officeAddress2}</span>
                    )}
                  </div>
                </div>

                {/* Row 6: Phone Number, Website, Email */}
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <label className="block mb-1">
                      {t('phone_Number', 'Phone Number')}
                      <span className="text-red-500">*</span>
                    </label>
                    <PhoneInput
                      country={'us'}
                      disabled={!isEditable}
                      value={formik.values.phoneNumber}
                      onChange={(value, country: any) => {
                        formik.setFieldValue('phoneNumber', value);
                        setSelectedCountryCode(country.countryCode.toUpperCase());
                      }}
                      containerClass="!h-11 !w-full"
                      inputClass="!w-full !h-11 border-none outline-none"
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                      <span className="text-red-600">{formik.errors.phoneNumber}</span>
                    )}
                  </div>

                  <div>
                    <label className="block mb-1">
                      {t('website', 'Website')}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      value={formik.values.website}
                      onChange={formik.handleChange('website')}
                      disabled={!isEditable}
                      name="website"
                      className="w-full border rounded-lg text-sm p-2 h-11"
                      placeholder={t('enterWebsiteUrl', 'Enter website URL')}
                    />
                    {formik.touched.website && formik.errors.website && (
                      <span className="text-red-600">{formik.errors.website}</span>
                    )}
                  </div>

                  <div>
                    <label className="block mb-1">
                      {t('email', 'Email')}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      value={formik.values.email}
                      onChange={formik.handleChange('email')}
                      type="email"
                      disabled={!isEditable}
                      name="email"
                      className="w-full border rounded-lg text-sm p-2 h-11"
                      placeholder={t('enterEmail', 'Enter email')}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <span className="text-red-600">{formik.errors.email}</span>
                    )}
                  </div>
                </div>
              </div>

              {/* Divider */}
              {isEditable && <div className="col-span-6 divider my-6 !bg-[#E5E5E5]"></div>}

              {/* Submit Button */}
              <div className="col-span-6 flex justify-end mt-4">
                {isEditable && (
                  <button
                    className="bg-primary text-black px-12 h-12 py-2 rounded-lg font-semibold"
                    onClick={() => formik.handleSubmit()}
                  >
                    {t('submit', 'Submit')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default AffiliateModal;
