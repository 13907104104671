import { Link } from 'react-router-dom';

const ProcurementTenderPage = () => {
  return (
    <div className="mx-auto max-w-screen-xl p-8">
      <h1 className="mb-1 text-2xl font-semibold text-gray-900">Electronic Procurement</h1>
      {/* <p className="text-base text-[#525252]">
                  Create tender opportunities and get qualified suppliers (update copy)
                </p> */}

      <div className="2xl:w-11/12">
        <div className="mb-2.5 mt-8 flex flex-col justify-between gap-x-7 gap-y-4 rounded-xl border border-[#E5E5E5] bg-white p-5 pb-12 xl:flex-row xl:items-center">
          <div className="grid w-full grid-cols-2 gap-3 xl:grid-cols-4">
            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Contract Name</span>
              <span className="text-sm font-medium text-[#525252]">
                Pipeline Inspection and Maintenance
              </span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Type</span>
              <span className="text-sm font-medium text-[#525252]">Joint Venture</span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Date</span>
              <span className="text-sm font-medium text-[#525252]">31/08/2024</span>
            </div>

            <div className="flex flex-col gap-y-2">
              <span className="text-sm text-[#8D8D8D]">Status</span>
              <span className="text-sm font-medium text-[#24A148]">Active</span>
            </div>
          </div>

          <button className="ml-auto flex h-12 w-[140px] items-center justify-center rounded-lg border border-[#D0D5DD] px-3.5 py-2 text-sm font-semibold text-[#344054] shadow-sm">
            View Details
          </button>
        </div>

        <div className="rounded-2xl border border-[#E5E5E5] bg-white pb-[54px] pl-[34px] pr-[26px] pt-[33px]">
          <div className="grid items-center gap-y-6 xl:grid-cols-[1fr_1.5fr]">
            <div className="flex items-center gap-x-6">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/ShellPix.svg`}
                alt="vendor icon"
                className="h-[58px] w-[58px] rounded-full"
              />

              <div className="flex flex-col items-start gap-y-2">
                <span className="text-sm text-[#8D8D8D]">Contractor Name:</span>
                <span className="font-semibold text-[#101828] underline">
                  Green Earth Consultants Ltd
                </span>

                <span className="rounded-[30px] bg-[#42BE65] px-2.5 py-1 text-[10px] font-medium text-white">
                  Verified Operator
                </span>
              </div>
            </div>

            <div className="grid grid-cols-[1fr_1.5fr]">
              <div className="flex flex-col gap-y-2">
                <span className="text-sm text-[#525252]">Contract Value:</span>
                <span className="text-base font-medium text-[#161616]">$62,000</span>
              </div>

              <div className="flex flex-col gap-y-2">
                <span className="text-sm text-[#525252]">Contract ID:</span>
                <span className="text-base font-medium text-[#161616]">PI-2024</span>
              </div>
            </div>
          </div>

          <div className="mt-[33px] flex gap-x-6">
            <div>
              {/* Background Bar */}
              <div className="relative left-[50%] top-0 h-full w-[3px] -translate-x-1/2 bg-[#E5E5E5]">
                {/* Completed Section 1 */}
                <div className="absolute top-0 h-[25%] w-full bg-[#42BE65]"></div>

                {/* Completed Section 2 */}
                <div className="absolute top-[25%] h-[25%] w-full bg-[#42BE65]"></div>
              </div>
            </div>

            <div className="flex w-full flex-col gap-y-4">
              <Link
                to="/procurement/123/expressionOfInterest"
                className="relative rounded-2xl border border-[#E9EFF2] px-4 py-6"
              >
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="mb-1 text-base font-medium text-[#292D32]">
                      Expression of interest
                    </h4>

                    <div className="flex flex-col gap-x-4 gap-y-1 xl:flex-row">
                      <p className="text-xs text-[#292D32]">
                        <span className="text-[#DA1E28]">Deadline:</span> 29/11/2024
                      </p>

                      <p className="text-xs text-[#292D32]">
                        <span className="text-[#24A148]">Bid Submitted:</span> 02/10/2024
                      </p>
                    </div>
                  </div>

                  <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#42BE65] px-5 py-3 text-sm font-semibold text-[#42BE65]">
                    <span>Completed</span>
                  </div>
                </div>

                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/tender-completed.svg`}
                  alt="Completed"
                  className="absolute -left-[26px] top-[50%] h-6 w-6 -translate-x-1/2 -translate-y-1/2"
                />
              </Link>

              <Link to="" className="relative rounded-2xl border border-[#E9EFF2] px-4 py-6">
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="mb-1 text-base font-medium text-[#292D32]">
                      Technical Evaluation
                    </h4>

                    <div className="flex flex-col gap-x-4 gap-y-1 xl:flex-row">
                      <p className="text-xs text-[#292D32]">
                        <span className="text-[#DA1E28]">Deadline:</span> 29/11/2024
                      </p>

                      <p className="text-xs text-[#292D32]">
                        <span className="text-[#24A148]">Bid Submitted:</span> Not submitted
                      </p>
                    </div>
                  </div>

                  <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#128FFF] px-5 py-3 text-sm font-semibold text-[#128FFF]">
                    <span>In progress</span>
                  </div>
                </div>

                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/tender-completed.svg`}
                  alt="Completed"
                  className="absolute -left-[26px] top-[50%] h-6 w-6 -translate-x-1/2 -translate-y-1/2"
                />
              </Link>

              <Link to="" className="relative rounded-2xl border border-[#E9EFF2] px-4 py-6">
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="mb-1 text-base font-medium text-[#292D32]">
                      Commercial Information/Financial Projection
                    </h4>

                    <div className="flex flex-col gap-x-4 gap-y-1 xl:flex-row">
                      <p className="text-xs text-[#292D32]">
                        <span className="text-[#DA1E28]">Deadline:</span> N/A
                      </p>

                      <p className="text-xs text-[#292D32]">
                        <span className="text-[#24A148]">Bid Submitted:</span> N/A
                      </p>
                    </div>
                  </div>

                  <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#D0D5DD] px-5 py-3 text-sm font-semibold text-[#D0D5DD]">
                    <span>Upcoming</span>
                  </div>
                </div>

                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/tender-pending.svg`}
                  alt="Completed"
                  className="absolute -left-[26px] top-[50%] h-6 w-6 -translate-x-1/2 -translate-y-1/2"
                />
              </Link>

              <div className="relative rounded-2xl border border-[#E9EFF2] px-4 py-6">
                <div className="flex items-center justify-between">
                  <h4 className="mb-1 text-base font-medium text-[#292D32]">Review</h4>

                  <div className="flex h-[33px] items-center justify-center rounded-2xl border border-[#D0D5DD] px-5 py-3 text-sm font-semibold text-[#D0D5DD]">
                    <span>Upcoming</span>
                  </div>
                </div>

                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/tender-pending.svg`}
                  alt="Completed"
                  className="absolute -left-[26px] top-[50%] h-6 w-6 -translate-x-1/2 -translate-y-1/2"
                />
              </div>
            </div>
          </div>

          <div className="mt-7 flex justify-end gap-x-4">
            <button className="min-w-[172px] rounded-lg bg-[#F5F5F5] text-sm font-semibold text-[#101828] hover:bg-[#E5E5E5]">
              Withdraw
            </button>

            <button className="min-w-[172px] rounded-lg bg-[#FFC726] py-3 text-sm font-semibold text-[#101828] hover:bg-[#e6b800]">
              Continue Application
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcurementTenderPage;
