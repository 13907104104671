import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './SuccessCard.css';
import { FadeLoader } from 'react-spinners';

import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Navbar from '../../layouts/NavBar/Navbar';

interface SuccessCardProps {
  isLoading?: boolean;
  title?: string;
}

const SuccessCard: React.FC<SuccessCardProps> = ({ title }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const route = location.state?.route;
  const message = location.state?.message;

  // Show the second image after a delay
  useEffect(() => {
    const timer = setTimeout(() => {
      if (route) {
        navigate(route);
      }
    }, 2000); // 2 seconds delay

    return () => clearTimeout(timer);
  }, [[navigate, route]]);

  return (
    <>
      <Navbar />
      <div className="flex justify-center items-start bg-[#f9fafb] min-h-screen pt-[70px]">
        <div className="success-card border-none gap-4 ">
          <div className="flex flex-col justify-center ">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/Group1272628232.svg`}
              alt={t('illustration', 'Illustration')}
            />
          </div>
          <div>
            <h2 className="font-semibold text-[32px] text-[#24A148] mt-[30px]">{title}</h2>
          </div>
          <div className=" text-sm">
            <p>{message}</p>
          </div>

          <FadeLoader color="#FFC726" />
        </div>
      </div>
    </>
  );
};

export default SuccessCard;
